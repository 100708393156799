.iconTittle {
  display: flex;
  align-items: center;
}


.modalIcon{
  display: flex;
  justify-content:center;
  align-items: center;
  height: 40px;
  width: 40px!important;
  color: #FFFF;
  border-radius: 50%;
  background: transparent linear-gradient(180deg, #F59B40 0%, #BE772F 100%) 0% 0% no-repeat padding-box;
}

.modalTittle{
  text-align: left;
  font-size: 16px;
  font-family: Ubuntu;
  font-weight: 700;
  letter-spacing: 1.2px;
  color: #F39332;

  text-transform: uppercase;
}

.modalDescription {
  text-align: left;
  font-size: 14px;
  font-family: Ubuntu;
  font-weight: 700;
  letter-spacing: 1.2px;
  color: #615b56;
}

.modalDescriptionClient {
  color: #F39332;
}
textarea.bodyCard::-webkit-input-placeholder{
  color: #615b56;
}
textarea.bodyCard::-moz-placeholder{
  color: #615b56;
}
.body {
  min-height: 100px;
  /* max-height: 300px!important; */
  overflow: hidden!important; 
}

.buttonDetails {
  font-size: 14px;
  font-family: Ubuntu;
  font-weight: 700;
  cursor: pointer;
  color: #615b56;
}

.buttonDetails:hover {
  color:#8e8e93
}

.error{
  display: block;
  color: red;
}

.selItemLabel {
  padding-right: 90px;
}

.linkIcon {
  fill: #4A5067;
  position: absolute;
  top: 5px;
  right: 7px;
  align-self: flex-start;
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  margin: 0 0 0 12px;
  width: 90px;
}

.linkIcon svg {
  width: auto;
  height: 26px;
  margin-right: -5px;
}