.mainDiv{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 5px 15px 15px #0000001A;
  border-radius: 5px;
  position: relative;
}

.tableDemands th{
  font-size: 21px;
  text-align: center;
  color: #1A213F;
  padding: 20px!important;
  vertical-align: middle!important;
  border-bottom: 1px solid #E0E3DA!important;
  letter-spacing: 0px;
  color: #1A213F;
  font-weight: 400;
}

.tableDemands th span{
  color: #718CFF!important;
}

.tableDemands td{
  text-align: center;
  padding: 30px;
  vertical-align: middle!important;
}

.tableDemands tbody tr{
  text-align: center;
  font-size: 19px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #1A213F;
  
  border-bottom: 1px solid #E0E3DA!important;
}

.tableDemands tbody tr span {
  color: #718CFF;
  
}

.tableDemands tbody tr:hover {
  background-color: #FCEFE2!important
}

a.removeDecoration {
  text-decoration:none!important;
}


.closeButton {
  /* position: relative;
  left: 46%; */
  display: flex;
  justify-content: center;
  align-items: center;
  font: 900 17px Poppins;
  letter-spacing: 1.22px;
  text-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  background: none;
  border: 0;
  cursor: pointer;

  transition: 0.2s;
  text-decoration: none;
}

.closeButton:hover {
  transform: translateY(-5px);
}

.closeButton .icon {
  display: block;
  margin-left: 5px;
}




