.details-sku-modal {
  font-family: 'Poppins';
  font-style: normal;

  .modal-header-sku {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 20px 0 20px;

    .title-modal-details-skus {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #4b5fa6;
      margin-bottom: 10px;
    }

    .subtitle-modal-details-skus {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #4b5fa6;
    }

    .button-close {
      border: none;
      background: transparent;
      color: #494e59;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  .div-change-record {
    padding-right: 20px;
    margin-top: -10px;
    display: flex;
    justify-content: flex-end;

    .linkChangeRecord {
      border: none;
      background: transparent;
      font-size: 16px;

      svg {
        margin-right: 5px;
        font-size: 25px;
      }
    }

    .changeRecordOrange {
      color: #e67f17;

      svg {
        color: #e67f17;
      }
    }

    .changeRecordGray,
    .changeRecordGray:hover {
      color: #878b94;

      svg,
      svg:hover {
        color: #878b94;
      }
    }
  }

  .form-edit-sku {
    border: 1px solid #d3d5dd;
    padding: 20px 0;

    .line-sku {
      display: grid;
      grid-template-columns: repeat(5, 1fr) 0.7fr;
      grid-column-gap: 8px;
      margin-bottom: 8px;

      .sku {
        width: 100%;
        height: 38px;
        border: 1px solid #878b94;
        border-radius: 8px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:disabled,
        &:hover {
          color: #303440;
        }
      }

      .btn-delete-sku {
        border: none;
        background: transparent;
      }

      .sku-cancelled {
        color: #e64545;
        margin-top: 13px;
      }
    }
  }

  .btn-save-sku {
    margin: 12px 0;
    display: flex;
    justify-content: flex-end;
    margin-top: 35px;

    button {
      border: none;
      background: #4b5fa6;
      color: #fff;
      padding: 5px 12px;
      border-radius: 4px;
      font-weight: 700;
      font-size: 14px;

      &:disabled {
        background: #e3e3e3;
      }
    }
  }
}
