@import '../../../../../Assets/colors.scss';
@import '../../../../../Assets/mixins.scss';
@import '../../../../../Assets/modals.scss';

.custom-modal {
  .icon-head-no-applicable {
    fill: $orange;
  }

  .text-no-applicable {
    color: $orange;
    margin: 7px 0px 20px 0px;
    font: {
      weight: 500;
      size: 16px;
    }
    @extend .placeholder-fonts;
  }

  .sub-text-no-applicable {
    color: $blue;
    margin: 0;
    position: relative;
    // top: -10px;
    line-height: 15px;
    font: {
      weight: 500;
      size: 13px;
    }
    @extend .placeholder-fonts;

    &:after {
      content: '';
      width: 40px;
      height: 6px;
      position: relative;
      display: block;
      top: 5px;
      @include border-radius(3px);
      background-color: $orange;
    }
  }

  p {
    @extend .placeholder-fonts;

    span {
      font-weight: bold;
    }
  }

  .modal-body {
    padding: 0px 25px;
  }
  .modal-header {
    border: 0;
    display: block;

    svg {
      margin-top: 3px;
    }
  }
}
