.buttonRed {
  background: #FD4646;
  width: 99px;
  height: 32px;
  border-radius: 4px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #FFFFFF
}

.buttonOrange {
  white-space: nowrap;
  background-color: #F08723;
  color: #FFFFFF;
  width: 269px;
  height: 32px;
  top: 483px;
  left: 799px;
  border-radius: 4px;
  padding: 0px, 16px, 0px, 16px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
}