// Cinza
// $gray1: #373737;
$gray2: #646464;
$gray3: #979797;
$gray4: #cacaca;
$gray5: #f2f2f2;
$gray6: #f6f6f6;
// $gray7: #e0e3da;
// $gray8: #4e4e4e;
// Cores Primarias
$primary1: #f39332;
$primary2: #1a213f;
$primary3: #1f4d74;
$primary4: #1b213f;

// Cores Secundarias
$blue: #425ba6;
$darkBlue: #28418c;
$lightBlue: #6078bf;
// $blue2: #0030ff;
// $blue3: #338CE1;
// $blue4: #A8D2FA;
$blue5: #2699fb;
$blue6: #699bc5;
// $blue7: #354E63;
$red: #fd4646;
$darkRed: #cc2929;
$lightRed: #ff6666;
$red2: #fee8ea;
$red3: #fad0d5;
// $red4: #f9a7b0;
$red5: #dc436d;
$red6: #f95c87;
$red7: #b9254e;
$green: #47cc7c;
$darkGreen: #4baf5a;
$lightGreen: #78f0a8;
$green2: #9cd7b5;
$green3: #1d8d4a;
$green4: #8dc6a5;
// $beige: #fcefe2;
$purple: #6200ee;
$purple2: #d1d9ff;
$purple3: #cccefc;
$purple4: #738cf8;
$yellow: #f2f84d;
// $yellow2: #fbfdc9;
$orange: #f08723;
$lightOrange: #f5aa62;

// Text colors
.text-color-green {
  color: $green;
}

// .text-color-green2 {
//   color: $green2;
// }

.text-color-green3 {
  color: $green3;
}

.text-color-red {
  color: $red;
}

.text-color-red5 {
  color: $red5;
}

// .text-color-primary1 {
//   color: $primary1;
// }

.text-color-primary3 {
  color: $primary3;
}

// .text-color-purple {
//   color: $purple;
// }

// .text-color-yellow {
//   color: $yellow;
// }

.text-color-blue {
  color: $blue;
}

.text-color-orange {
  color: $orange;
}

// .text-color-blue2 {
//   color: $blue2;
// }

// .text-color-blue3 {
//   color: $blue3;
// }

// Cor Transparente
$transparent: transparent;

// Gradiantes
// @mixin primary-gradient1 {
//   background: rgb(227, 178, 126);
//   background: linear-gradient(
//     180deg,
//     rgba(227, 178, 126, 1) 0%,
//     rgba(243, 147, 50, 1) 100%
//   );
// }
// @mixin primary-gradient2 {
//   background: linear-gradient($primary2, rgb(101, 105, 125));
// }
@mixin primary-gradient3 {
  background: rgb(94, 139, 177);
  background: linear-gradient(
    180deg,
    rgba(94, 139, 177, 1) 0%,
    rgba(57, 83, 106, 1) 100%
  );
}

// @mixin gray-gradient {
//   background: linear-gradient(white, rgb(227, 227, 227));
// }
// @mixin purple-gradient {
//   background: linear-gradient(white, rgb(171, 185, 246));
// }

// @mixin red-gradient {
//   background: linear-gradient($red, rgb(254, 148, 148));
// }

// @mixin green-gradient {
//   background: linear-gradient(rgb(49, 241, 121), rgb(2, 153, 58));
// }
@mixin green-gradient-2 {
  background: rgb(92, 209, 139);
  background: linear-gradient(
    180deg,
    rgba(92, 209, 139, 1) 0%,
    rgba(22, 133, 66, 1) 100%
  );
}

@mixin green-gradient-3 {
  background: rgb(58, 223, 124);
  background: linear-gradient(
    180deg,
    rgba(58, 223, 124, 1) 0%,
    rgba(120, 240, 168, 1) 100%
  );
}

@mixin blue-gradient {
  background: rgb(108, 187, 254);
  background: linear-gradient(
    180deg,
    rgba(108, 187, 254, 1) 0%,
    rgba(0, 101, 186, 1) 100%
  );
}

// @mixin blue-gradient-2 {
//   background: rgb(0, 107, 198);
//   background: linear-gradient(
//     180deg,
//     rgba(0, 107, 198, 1) 0%,
//     rgba(31, 77, 116, 1) 100%
//   );
// }

@mixin blue-gradient-5 {
  background: rgb(191, 193, 251);
  background: linear-gradient(
    180deg,
    rgba(191, 193, 251, 1) 0%,
    rgba(38, 153, 251, 1) 100%
  );
}

@mixin blue-gradient-6 {
  background: rgb(0, 107, 198);
  background: linear-gradient(
    180deg,
    rgba(0, 107, 198, 1) 0%,
    rgba(31, 77, 116, 1) 100%
  );
}

@mixin blue-grey-gradient {
  background: rgb(243, 147, 50);
  background: linear-gradient(
    180deg,
    rgba(243, 147, 50, 1) 0%,
    rgba(105, 155, 197, 1) 100%
  );
}

// @mixin red-gradient {
//   background: rgb(254, 153, 166);
//   background: linear-gradient(
//     180deg,
//     rgba(254, 153, 166, 1) 0%,
//     rgba(249, 92, 135, 1) 100%
//   );
// }

@mixin orange-gradient {
  background: transparent linear-gradient(180deg, $orange 0%, $primary1 100%) 0%
    0% no-repeat padding-box;
}

@mixin pink-gradient {
  background: transparent linear-gradient(180deg, $red6 0%, $red7 100%) 0% 0%
    no-repeat padding-box;
}

@mixin blue-gradient {
  background: transparent linear-gradient(180deg, #699bc5 0%, #354e63 100%) 0%
    0% no-repeat padding-box;
}

// @mixin black-gradient {
//   background: transparent linear-gradient(180deg, $primary4 0%, $gray8 100%) 0%
//     0% no-repeat padding-box;
// }

@mixin green-gradient {
  background: transparent linear-gradient(180deg, $green 0%, $green4 100%) 0% 0%
    no-repeat padding-box;
}
