.buttonRed {
  background: #FD4646;
  width: 99px;
  height: 32px;
  border-radius: 4px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #FFFFFF
}

.buttonBlue {
  padding: 0 40px;
  white-space: nowrap;
  width: 100%;
  padding: 6px 16px;
  background: #425BA6;
  border-radius: 4px;
  border: #425BA6;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF
}