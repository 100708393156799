
.specialPaddingModal {
  padding: 20px 80px 30px 80px;
}


.modal-dialog {
  &.modal-cd-destiny-sku {
    
    .modal-header {
      padding: 0px 20px;
      border: 0;
      margin-top: 20px;

      div {
        color: #FF725E;
        font-size: 24px;
        text-align: center;
        font-weight: 600;
        display: inline;
      }

      svg {
        margin-left: 8px;
        height: 30px;
        width: 100px;
      }
    }

    .close {
      display: none;
    }

    .modal-body {

      p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      span {
        color: #425BA6;
        font-weight: bold;
      }
    }

    .modal-footer {

      button {
        width: auto;
        display: inline-flex;
        margin: 0 10px;
      }

      .divButtonFooter {
        max-width: 500px;
        margin: auto;
      }
    }
  }
}