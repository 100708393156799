.login{
    background-image: url('../../Assets/loginBack.jpg');
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: space-between;
}

.logoDigital{
    position: absolute;
    top: 25px;
}

.logoDigital span{
    letter-spacing: 0px;
    color: #000;
    text-shadow: 2px 1px 2px #0000002E;
    font-size: 27px;
    position: relative;
    top: 6px;
    left: -10px;
}

.slogan{

}

.slogan h1{
    color: #293D7C;
    font-size: 68px;
    line-height: 72px;
    font-weight: 200;
    font-family: 'Poppins', sans-serif;
}

.slogan h2{
    color: #293D7C;
    font-size: 19px;
    line-height: 25px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.buttonLogin{
    background-color: #f3a75b;
    width: 140px;
    height: 40px;
    font-size: 20px;
    color: #ffffff;
    border-radius: 5px;
    margin-top: 10px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.buttonLogin:hover{
    text-decoration: none;
}

.buttonLogin:focus{
    outline: none;
}

.modalLogin{
    width: 450px;
    position: absolute;
    right: 15%;
    top: 15%;
    border: none!important;
}

.loginLogo{
    text-align: center;
}

.loginLogo .text{
    font-size: 35px;
    position: relative;
    top: 8px;
    left: -15px;
    text-shadow: 2px 1px 2px #0000002E;
}

.loginLogo img{
    width: 70px;
}

.loginInfo{
    text-align: center;
    padding: 50px 0;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
}

.formLogin{
    padding: 0px 15px;
}

.inputLogin{
    background: #FFFFFF 0% 0% no-repeat padding-box!important;
    box-shadow: 0px 12px 40px #00000014!important;
    letter-spacing: 0.6px!important;
    color: #707070!important;
    font-size: 12px!important;
    display: block!important;
    width: 100%!important;
    padding: 25px!important;
    margin-bottom: 5px!important;
    border: none!important;
    border-left: 5px solid #f3a75b!important;
    font-family: 'Poppins', sans-serif!important;
}

.inputLogin:focus{
    border-left: 5px solid #ffc386;
    outline: none;
}

.forgot{
    padding: 30px 0;
    font-family: 'Poppins', sans-serif;
}

.send{
    text-align: right;
}

.buttonSent{
    background-color: #f3a75b;
    width: 140px;
    height: 45px;
     font-size: 20px; 
    color: #ffffff;
    border-radius: 5px;
    /* margin-top: 10px; */
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.buttonSent:hover{
    text-decoration: none;
}

.buttonSent:focus{
    outline: none;
}

.sentButton{
    letter-spacing: 1.22px;
    color: #707070;
    font-size: 22px;
    font-weight: bold;
    background: transparent;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
}

.infoEnter{
    text-align: center;
    font-size: 14px;
    padding: 80px 0 0 0;
    font-family: 'Poppins', sans-serif;
}

/* Responsive */

@media (max-width: 1023px){
    .logoDigital{
        margin: auto;
        text-align: center;
        padding-top: 20px;
        position: initial;
    }

    .buttonLogin{
        margin: 20px auto;
    }
    
    .imgLogin{
        display: none;
    }

    .slogan h1{
        font-size: 4em;
        line-height: 4rem;
    }
}