@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');
@import '../../../../Assets/colors.scss';

@media only screen and (min-device-width: 1366px) and (max-device-width: 1650px) {
  .margin {
    margin-right: 40px !important;
  }
}

.detailProject {
  background-color: #F1F2F4;
}

.headerProject {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  justify-content: space-between;

  .projectNature {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-left: 20px;
  }

  .containerStatusButtons {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
  }

  .containerButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    min-width: 177px;
    margin-bottom: 10px;

    .subContainerButton {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: transparent;
      border: 1px solid #989797;
      border-radius: 2px;
      padding: 5px !important;
    }
  }
}


.mainContainer {
  display: flex;
  align-items: center;
  padding: 25px 10px 10px 20px;
  background-color: #ffffff;
  /* border-radius: 10px; */
  margin-top: 10px;
  /* box-shadow: 0px 0px 6px 1px #2c28281c;
  margin: 0 30px 30px 30px !important; */
  position: relative;
  font-family: 'Poppins';
  font-style: normal;

  .margin {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
  }
  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      margin-right: 15px;
    }

    .details-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-right: 20px;

      .client-project-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        /* flex-direction: column; */

        .iconProject {
          font-family: 'Montserrat', 'Ubuntu';
          font-size: 19px;
          font-weight: bold;
          letter-spacing: 1.28px;
          color: #1f4d74;
          padding-right: 22.5px;
          text-align: center;
          margin-top: 10px;
        }

        .infosProject {
          display: flex;
          flex-direction: column;

          .projectName {
            font-weight: 500;
            font-size: 19px;
            line-height: 30px;
            color: #303440;
            padding-right: 12.5px;
            white-space: nowrap;
          }
  
          .clientName {
            font-style: normal;
            font-weight: 500;
            font-size: 19px;
            line-height: 30px;
            color: #F39332;
            display: block;
          }
        }
      }

      .releaseDate {
        font-size: 11px;
        font-weight: 300;
        letter-spacing: 1.98px;
        color: #1b213f;
        text-align: center;

        /**/

        span {
          font-family: 'Poppins';
          font-style: normal;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 1.98px;
          color: #f39332;
          text-align: center;
          line-height: 21px;
        }

        img {
          margin-right: 5px;
        }
      }
    }
  }

  .detailsProjectContainer {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 18px;

    span {
      margin-bottom: 5px;
      strong {
        font-weight: 700;
      }
    }
  }

  main {
    margin: 15px 20px 0 0;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 8px 8px 50px #00000021;

    .divider {
      display: flex;
      align-items: center;
      margin: 0;
      height: 35px;

      // background: transparent linear-gradient(180deg, #f39332 0%, #e3b27e 100%)
      //   0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #0000000f;
      background-color: #e1e4e8;

      span {
        font-size: 14px;
        margin-left: 20px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
        color: #425ba6;
      }
    }

    .dataProject {
      display: flex;
      justify-content: space-between;
      font-family: 'Poppins';
      font-style: normal;
      font-size: 12px;
      line-height: 18px;
      color: #1b213f;
      white-space: nowrap;
      margin: 8px 0px 5px 25px;

      span {
        font-weight: 600;
      }

      .lado-grafico {
        margin-right: 40px;
      }
    }

    .timeLine {
      margin: 16px 0;
      padding: 0 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
}

.closeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', 'Ubuntu';
  font-size: 19px;
  font-weight: 300 !important;
  letter-spacing: 1.22px;
  color: #265278;
  text-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  background: none;
  border: 0;
  cursor: pointer;

  transition: 0.2s;
  text-decoration: none;
}

.closeButton:hover {
  transform: translateY(-5px);
  color: #62819d;
}

.closeButton .icon {
  display: block;
  margin-left: 5px;
  font-weight: 300 !important;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .closeButton .icon {
    display: none;
  }
}

.status {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #1B213F;
  margin-bottom: 5px;
}

.stage-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-around;
}

.divModal {
  display: flex;
  align-items: center;
  margin-top: 30px;

  .detailsModal {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    font-size: 10px;
    line-height: 16px;
    span {
      font-weight: normal !important;
    }
  }

  button svg {
    margin-right: 7px;
  }
}