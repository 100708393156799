.userDiv {
  display: flex;
  margin-top: 70px;

  .avatarUser {
    margin-left: 10px; 
    width: 32px; 
    height: 32px; 
    border-radius: 50%;
    background: #E3E3E3;

    p {
      font-family: Poppins;  
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: #3C3C3B;
      margin-top: 5px;
    }
  }

  .divName {
    margin-left: 10px;

    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #3C3C3B;
    }

    .emailUser {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      color: #676B73;
      margin-top: -5px;
    }
  }
}

.ulMenuSidebar {
  margin: 40px 20px;

   li .linkMenu {
    font-family: Poppins;
    font-style: normal;
    color: #3C3C3B;
    font-size: 16px;
    line-height: 24px;
    display: block;
    font-weight: 400;
    padding: 15px 15px;
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
      color: #3C3C3B;
      margin-right: 5px;
    }

    &:hover {
      background-color: #EAECF3;
      border-radius: 3px;
      color: #3C3C3B;
      font-weight: 400;
      border-radius: 4px;
    } 

    &.active {
     color: #2B408B !important;
     font-weight: bold;
     background-color: transparent !important;
     border-radius: 4px;
    }
  }

  .linkAccordion {
    padding: 0px 0px 10px 30px;
    font-size: 13px;
    font-weight: 500;
    position: relative;

    .optionMenu {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #3C3C3B;
      padding: 10px 5px;

      &:hover {
      background-color: #EAECF3;
      border-radius: 3px;
      color: #3C3C3B;
      font-weight: 400;
      border-radius: 4px;
     }

     &.active {
     color: #2B408B !important;
     font-weight: 700;
     background-color: #EAECF3 !important;
     border-radius: 4px;
     }
    }

    .linkInsideMenu {
      font-family: Poppins;
      font-style: normal;
      display: block;
      font-size: 12px;
      color: #3C3C3B;
      margin: 7px 0;
      font-weight: 300;
      line-height: 18px;

      &:hover {
      background-color: #EAECF3;
      border-radius: 3px;
      color: #3C3C3B;
      font-weight: 400;
      border-radius: 4px;
     }
    }

    .buttonInsideMenu {
      font-family: Poppins;
      font-style: normal;
      display: block;
      font-size: 12px;
      color: #3C3C3B;
      margin: 7px -5px;
      font-weight: 300;
      line-height: 18px;

      &:hover {
      background-color: #EAECF3;
      border-radius: 3px;
      color: #3C3C3B;
      font-weight: 400;
      border-radius: 4px;
     }

     &.active {
     color: #2B408B !important;
     font-weight: bold;
     background-color: transparent !important;
     border-radius: 4px;
     }
    }
  }
} 

/* .buttonCloseDiv {
  margin-top: 20px;

  .buttonCloseText {
    font-family: Poppins;
    font-style: normal;
    color: #3C3C3B;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    font-weight: 400;
    padding: 15px 30px;
    
    button {
     background-color: transparent;
     border: none;
    }

    img {
      width: 24px;
      height: 24px;
      color: #3C3C3B;
      margin-right: 5px;
    }

    &:hover {
      background-color: #EAECF3;
      border-radius: 3px;
      color: #3C3C3B;
      font-weight: 400;
      border-radius: 4px;
    }
  }
} */

