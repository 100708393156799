@import '../../../../../Assets/colors.scss';
@import '../../../../../Assets/mixins.scss';
@import '../../../../../Assets/modals.scss';

.custom-modal {
  .icon-head-observation {
    fill: $blue;
  }

  .text-title-observation {
    color: $blue;
    margin: 0;
    position: relative;
    top: 4px;

    font: {
      weight: 600;
      size: 16px;
    }
    @extend .placeholder-fonts;
  }

  .sub-title-observation {
    color: $blue;
    margin: 0;
    position: relative;
    // top: -12px;
    font: {
      weight: 500;
      size: 13px;
    }
    @extend .placeholder-fonts;

    &:after {
      content: '';
      width: 40px;
      height: 6px;
      position: relative;
      display: block;
      top: 40px;
      @include border-radius(3px);
      background-color: $blue;
    }
  }

  .sub-title-modal-green3 {
    &:before {
      @include green-gradient-3;
    }
  }

  p {
    @extend .placeholder-fonts;

    span {
      font-weight: bold;
    }
  }

  .modal-body {
    padding: 0px 25px;
  }
  .modal-header {
    border: 0;
    display: block;

    svg {
      margin-top: 3px;
    }
  }

  .select-etapa {
    max-width: -webkit-fill-available;
  }
}
