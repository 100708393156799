.containerCollapse{ 
  margin: 0 20px;
}

.rowCollapse {
  margin: 5px 0;
}
.titleCollapse {
  color: #F39332;
  font-size: 18px;
  font-weight: bold;
}

.labelCollapse {
  color: #F39332;
  letter-spacing: 0.32px;
  font-size: 16px;
  font-weight: bold;
}
.dividerCollapse {
  background: transparent linear-gradient(180deg, #9B632A 0%, #F39332 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 182px;
  height: 9px!important;
  margin-bottom: 12px!important;
  margin-top: 5px!important;
}

.iconCollapse {
  color: #be752d;
}

.inputGroupCollapse {
  height: 39px!important;
  
}

.inputGroupCollapse:hover{
  border: 0.5px solid #F39332!important;
}

.inputCollapse{
  color: #303752!important;
  background: white!important;
  font-weight: bold!important;
} 

.linkIconCombo {
  fill: #777;
  height: 14px;
}

.linkIcon {
  fill: #777;
  height: 16px;
  align-self: flex-end;
  margin-bottom: 12px;
}
