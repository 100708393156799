.animeLeft {
    opacity: 0;
    transform: translateX(-20px);
    animation: animeLeft 0.7s forwards;
  }
  
  @keyframes animeLeft {
    to {
      opacity: 1;
      transform: initial;
    }
  }

  .animeRight {
    opacity: 0;
    transform: translateX(+20px);
    animation: animeRight 0.7s forwards;
  }
  
  @keyframes animeRight {
    to {
      opacity: 1;
      transform: initial;
    }
  }