.modalWeight {
  width: 400px;

  .weightInputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 0 30px 0;

    .weightInputColumn {
      width: 48%;

      .legendField {
        font-size: 10px;
        color: #070707;
        font-weight: bold;
        margin: 0;
      }
    }
  }

  .weightButtons {
    max-width: 100%;
    margin: auto;
    display: flex;
    gap: 20px;
    justify-content: flex-end;

    .button-cancelar {
      background: #f97575 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #2c28281c;
      border: 1px solid #f97575;
      border-radius: 5px;
      color: #fff;
      padding: 5px;
      width: 120px;
      font-size: 14px;
    }

    .button-confirmar {
      background: #ffffff 0% 0% no-repeat padding-box;
      background: linear-gradient(0deg, rgba(237, 237, 238, 1) 0%, rgba(212, 209, 209, 1) 100%);
      box-shadow: 0px 3px 6px #2c28281c !important;
      border-radius: 5px;
      padding: 5px;
      width: 120px;
      font-size: 14px;
      font-weight: 600;
      color: #202644 !important;
    }
  }
}