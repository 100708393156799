.dndfi-file-area {
  padding: 16px 20px;
  background: #f1f2f4;
  border: 1px dashed #494e59;
  border-radius: 8px;
  position: relative;
  margin: 0 0 16px 0;
  &.error {
    background: #ff6666;
    border: 1px dashed #494e59;
    .inline-text {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #fff;
    }
  }

  .dndfi-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#425ba6, 0.2);
  }

  .inline-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #30343e;

    button {
      display: inline-block;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #425ba6;
      background: transparent;
      border: 0;
      margin: 0;
      padding: 0;
    }
  }
}
