.mainDiv2 {
  margin: 0 30px !important;
  padding: 10px 0 !important;
  position: relative !important;

 .countProject {
  font-size: 15px;
  font-weight: bold;
  position: relative;
  line-height: 20px;
  color: #fff;
 }

 .projectsNumber {
  font-size: 18px;
  font-weight: 300;
  margin-left: 2px;
 }

 .buttonBlue {
  margin-left: -60px;
  padding: 0 16px;
  white-space: nowrap;
  padding: 6px 16px;
  background: #425BA6;
  border-radius: 4px;
  border: #425BA6;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  color: #FFFFFF;
 }

 .textInput,
 .textInput::placeholder {
  color: #000 !important;
  padding: 8px 11px !important;
  opacity: 1;
 }
}

.linkBriefing {
  img {
  display: inline-block;
  }

  p {
   font-size: 10px;
   color: #6ca0f9;
   margin-top: 5px;
  }
}