.listManagementPage {
  background-color: #f5f5f5;

  .mainDivListViewProjects {
    margin: 100px 0px 0 0px !important;
    padding: 10px 30px !important;
    position: fixed;
    z-index: 2;
    font-family: 'Poppins';
    background-color: #f1f2f4;

    .totProjects {
      margin-top: 8px;
    }

    .textInput,
    .textInput::placeholder {
      color: #000 !important;
      padding: 8px 11px !important;
      opacity: 1;
    }

    .inputAtrasados {
      width: 100%;
      height: 38px;
      display: flex;
      align-items: center;

      label {
        display: flex;
        align-content: center;
        font-size: 14px;
        margin: 0;
      }

      input {
        width: 18px;
        height: 18px;
        margin: 0 5px 0 0;
      }
    }
  }

  .areaTableList {
    // margin-top: 200px;
    position: fixed;
    width: 100%;
    // height: 100%;
    top: 158px;
    bottom: 105px;
    overflow: auto;
    scroll-behavior: smooth;
  }

  .areaFooter {
    background-color: #f1f2f4;
    width: 100%;
    height: 105px;
    margin: 0;
    padding: 0 30px;
    z-index: 2;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;

    .colTotProjects {
      display: flex;
      align-items: center;
      max-width: 30%;
      height: 100%;
    }
  }
}
