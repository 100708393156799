.modal-briefing-delete {

  .button-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;

    button {
      height: 32px;
      white-space: nowrap;
      border-radius: 4px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
    }
  
    .action-button-red {
      background: #FD4646;
    }
    
    .action-button-blue {
      background: #425BA6;
    }
  }
}
