@import '../../Assets/colors.scss';
@import '../../Assets/mixins.scss';

.buttons {
  border: none;
  box-shadow: 0px 1px 3px #f6f8fb;
  font-family: 'Montserrat';
  min-height: 34px;
  padding: 5px 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  line-height: 1.5;
  color: #ffffff;
  // @include border-radius(5px);

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out transform 0.15s ease-in-out;

  &.btn-radius {
    border-radius: 5px;
  }

  &.btn-opacity {
    opacity: 0.5;
  }

  &.btn-bold {
    font-weight: 700;
  }

  &.btn-xsmall {
    min-width: 65px;
    font-size: 11px;
  }

  &.btn-small {
    min-height: 17px;
    min-width: 86px;
    font-size: 13px;
  }
  &.btn-default {
    min-width: 100px;
    font-size: 14px;
  }

  &.btn-medium {
    min-width: 138px;
    font-size: 16px;
  }

  &.btn-large {
    min-width: 148px;
    font-size: 14px;
    padding: 9px;
  }

  &:active:not(:disabled):not(.disabled) {
    border-color: #4e555b;
    // box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
  }

  &.icon-left {
    justify-content: space-between;

    svg {
      margin: 0 15px 0 -15px;
    }
  }

  &.icon-right {
    justify-content: space-between;

    svg {
      margin: 0 -15px 0 3px;
    }
  }

  &.icon-only {
    justify-content: center;
    min-width: auto;
    padding: 13px;
  }

  &.btn-round {
    border-radius: 50%;
  }
  &.btn-hidden {
    display: none;
  }

  &.btn-blue {
    background-color: $blue;

    &:hover,
    &.btn-active {
      // filter: brightness(110%) saturate(110%) !important;
      background-color: $darkBlue;
    }
  }

  &.btn-lightBlue {
    background-color: $lightBlue;
    opacity: 0.8;

    &:hover,
    &.btn-active {
      opacity: 1;
    }
  }

  // &.btn-blue3 {
  //   background-color: $blue3;
  //   color: #ffffff;

  //   &:hover,
  //   &.btn-active {
  //     filter: brightness(110%) saturate(110%);
  //   }
  // }

  &.btn-blue5 {
    @include blue-gradient-5;
    color: #ffffff;

    &:hover,
    &.btn-active {
      filter: brightness(110%) saturate(110%);
    }
  }

  // &.btn-blue6 {
  //   @include blue-gradient-6;
  //   color: #ffffff;

  //   &:hover,
  //   &.btn-active {
  //     filter: brightness(110%) saturate(110%);
  //   }
  // }

  &.btn-primary3 {
    background-color: $primary3;
    color: #ffffff;

    &:hover,
    &.btn-active {
      filter: brightness(110%) saturate(110%);
    }
  }

  &.color-icon-blue {
    svg {
      color: $blue;
    }
    svg:hover {
      filter: brightness(110%) saturate(110%);
    }
    &.btn-active {
      svg {
        filter: brightness(110%) saturate(110%);
      }
    }
  }

  &.color-icon-darkBlue {
    svg {
      color: $primary2;
    }
  }

  // &.btn-gradBlue {
  //   background: linear-gradient(rgb(43, 68, 167), rgb(30, 44, 111));

  //   &:hover,
  //   &.btn-active {
  //     background: linear-gradient(rgb(0, 160, 252), rgb(5, 110, 171));
  //   }
  // }

  &.btn-gradBlue2 {
    background: transparent linear-gradient(180deg, #1f4d74 0%, #1b213f 100%) 0%
      0% no-repeat padding-box;

    &:hover,
    &.btn-active {
      filter: brightness(110%) saturate(110%);
    }
  }

  // &.btn-gradBlue3 {
  //   background: transparent linear-gradient(180deg, #2699fb 0%, #006bc6 100%) 0%
  //     0% no-repeat padding-box;

  //   &:hover,
  //   &.btn-active {
  //     filter: brightness(110%) saturate(110%);
  //   }
  // }

  &.btn-red {
    background-color: $lightRed;

    &:hover,
    &.btn-active {
      // filter: brightness(110%) saturate(110%);
      background-color: $red;
    }
  }

  &.color-icon-red {
    svg {
      color: $red;
    }

    svg:hover {
      filter: brightness(110%) saturate(110%);
    }

    &.btn-active {
      svg {
        filter: brightness(110%) saturate(110%);
      }
    }
  }

  // &.btn-red4 {
  //   background-color: $red4;

  //   &:hover,
  //   &.btn-active {
  //     filter: brightness(110%) saturate(110%);
  //   }
  // }

  &.btn-red5 {
    background-color: $red5;

    &:hover {
      filter: brightness(110%) saturate(110%);
    }
  }

  // &.color-icon-red4 {
  //   svg {
  //     color: $red4;
  //   }

  //   svg:hover {
  //     filter: brightness(110%) saturate(110%);
  //   }

  //   &.btn-active {
  //     svg {
  //       filter: brightness(110%) saturate(110%);
  //     }
  //   }
  // }

  // &.btn-gradRed {
  //   background: linear-gradient(rgb(249, 68, 68), rgb(185, 68, 68));

  //   &:hover,
  //   &.btn-active {
  //     background: linear-gradient(rgb(248, 89, 89), rgb(185, 69, 69));
  //   }
  // }

  // &.btn-gradRed2 {
  //   background: transparent linear-gradient(180deg, #f95c87 0%, #b9254e 100%) 0%
  //     0% no-repeat padding-box;

  //   &:hover,
  //   &.btn-active {
  //     filter: brightness(110%) saturate(110%);
  //   }

  //   &:disabled {
  //     background: #e4f0fb 0% 0% no-repeat padding-box !important;

  //     i {
  //       color: #d4d9df !important;
  //     }

  //     &:hover {
  //       filter: brightness(110%) saturate(110%);
  //     }
  //   }
  // }

  &.btn-green {
    background-color: $lightGreen;

    &:hover,
    &.btn-active {
      // filter: brightness(110%) saturate(110%);
      background-color: $green;
    }
  }

  &.btn-green2 {
    background-color: $green2;

    &:hover,
    &.btn-active {
      background: rgb(120, 240, 168);
      background: linear-gradient(
        180deg,
        rgba(120, 240, 168, 1) 0%,
        rgba(14, 125, 58, 1) 100%
      );
    }
  }

  // &.btn-blue2 {
  //   background: linear-gradient(
  //       180deg,
  //       rgb(99, 148, 189, 1) 0%,
  //       rgb(39, 85, 124, 1) 100%
  //     )
  //     0% 0% no-repeat padding-box;

  //   &:hover,
  //   &.btn-active {
  //     background: rgb(99, 148, 189);
  //     background: linear-gradient(180deg, #1f4d74 0%, #1b213f 100%);
  //   }
  // }

  &.btn-orange {
    background-color: $orange;

    &:hover,
    &.btn-active {
      filter: brightness(110%) saturate(110%);
    }
  }

  &.color-icon-green {
    svg {
      color: $green;
    }
    svg:hover {
      filter: brightness(110%) saturate(110%);
    }
    &.btn-active {
      filter: brightness(110%) saturate(110%);
    }
  }

  // &.btn-gradGreen {
  //   background: linear-gradient(rgb(48, 239, 119), rgb(3, 155, 60));

  //   &:hover,
  //   &.btn-active {
  //     background: linear-gradient(rgb(111, 234, 160), rgb(66, 209, 124));
  //   }
  // }

  // &.btn-gradGreen2 {
  //   background: transparent linear-gradient(180deg, #78f0a8 0%, #0e7d3a 100%) 0%
  //     0% no-repeat padding-box;

  //   &:hover,
  //   &.btn-active {
  //     filter: brightness(110%) saturate(110%);
  //   }
  // }

  &.btn-orange {
    background-color: $primary1;

    &:hover,
    &.btn-active {
      filter: brightness(110%) saturate(110%);
    }
  }

  &.color-icon-orange {
    svg {
      color: $primary1;
    }
    svg:hover {
      filter: brightness(110%) saturate(110%);
    }
    &.btn-active {
      filter: brightness(110%) saturate(110%);
    }
  }

  // &.btn-gradOrange {
  //   background: linear-gradient(rgb(249, 199, 149), rgb(232, 145, 56));

  //   &:hover,
  //   &.btn-active {
  //     background: linear-gradient(rgb(227, 172, 116), rgb(239, 149, 58));
  //   }
  // }

  // &.btn-white {
  //   background: linear-gradient(rgb(252, 252, 252), rgb(223, 223, 223));
  //   color: $gray2;

  //   &:hover,
  //   &.btn-active {
  //     background: linear-gradient(rgb(250, 250, 250), rgb(250, 250, 250));
  //   }
  // }

  // &.btn-purple {
  //   background: linear-gradient(rgb(95, 0, 232), rgb(52, 0, 128));

  //   &:hover,
  //   &.btn-active {
  //     background: linear-gradient(rgb(159, 104, 238), rgb(52, 0, 128));
  //   }
  // }

  &.btn-grey {
    background-color: $gray5;
    color: $primary2 !important;
    &:hover,
    &.btn-active {
      filter: brightness(102%) saturate(102%) !important;
    }
  }

  // &.btn-gradGrey {
  //   background: transparent linear-gradient(180deg, #ffffff 0%, #e5e9ff 100%) 0%
  //     0% no-repeat padding-box;
  //   color: $primary2 !important;
  //   // &:hover,
  //   // &.btn-active {
  //   //   filter: brightness(110%) saturate(110%)!important;
  //   // }
  // }

  // &.btn-gradGrey2 {
  //   background: #bfc1fb 0% 0% no-repeat padding-box;
  //   color: #ffffff !important;
  //   opacity: 0.5;
  //   &:hover,
  //   &.btn-active {
  //     filter: brightness(110%) saturate(110%) !important;
  //   }
  // }

  // &.btn-lightPurple {
  //   background: linear-gradient(rgb(246, 247, 250), rgb(208, 208, 255));

  //   &:hover,
  //   &.btn-active {
  //     filter: brightness(110%) saturate(110%);
  //   }
  // }

  &.btn-transparent {
    background-color: transparent;
    color: $gray2;
    box-shadow: none;
  }

  &:disabled {
    opacity: 1;
    // background: transparent linear-gradient(180deg, #c4c4c4 0%, #ececec 100%) 0%
    //   0% no-repeat padding-box;
    background-color: $gray5;
    color: $gray4;
    border: 1px solid $gray4;
    i {
      color: $gray4;
    }
    &:hover {
      // background: transparent linear-gradient(180deg, #c4c4c4 0%, #ececec 100%)
      //   0% 0% no-repeat padding-box !important;
      background-color: $gray5;
      border: 1px solid $gray4;
      color: $gray4;
      filter: none;
    }
  }
}
