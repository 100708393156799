.mainDiv{
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #2C28281C;
  margin: 0 30px;
  position: relative;
  padding-bottom: 10px;
}

.tableBriefing{
  margin: 0;
}
a.removeDecoration {
  text-decoration:none!important;
}

.tableBriefing {
  background-image: linear-gradient(#F29435, #E3B280);
  font-size: 14px;
  text-align: center;
  color: #fff;
  padding: 20px 5px!important;
  vertical-align: middle!important;
  border-radius: 5px 5px 0 0 ;
}
.tableProspectionDetails {
  background: #fcefe2;
  font-size: 14px;
  text-align: center;
  color: #1A213F;
  padding: 20px 5px!important;
  vertical-align: middle!important;
  border-radius: 0;
  border-bottom: 4px solid #7a4a19;
}

.columnBegin {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #1A213F 0%, #666A7E 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px 0 0 5px;
  height: 60px;
  color: #FFFFFF;
  cursor: pointer;
  padding: 0px 30px !important;
}

.columns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.columns .icon {
  height: 24px;
  font-size: 24px;
  color: #666666;
}

.columns .icon.success {
  color: #47CC7C;
}

.columns.drow {
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
}

.fullCenter {
  width: 100%;
  text-align: center;
}

.fakeButton { 
  padding: 9.5px 5px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  color: #1A213F;
  border-radius: 5px;
  
}

.fakeButton2 { 
  padding: 7.5px 5px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  color: #1A213F;
  border-radius: 5px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}
.fakeStatus{ 

  padding: 9.5px 5px!important;
  background: #78efa7 0% 0% no-repeat padding-box!important;
  color: #FFFFFF!important;
  border-radius: 5px!important;
}

.fakeStatusStandby {
  padding: 9.5px 5px!important;
  background: #F29435 0% 0% no-repeat padding-box!important;
  color: #FFFFFF!important;
  border-radius: 5px!important;
}

.fakeStatusFinish{ 

  padding: 9.5px 5px!important;
  background: transparent linear-gradient(180deg, #298A50 0%, #78F0A8 100%) 0% 0% no-repeat padding-box!important;
  color: #FFFFFF!important;
  border-radius: 5px!important;
}

.fakeStatusUnfeasible{ 

  padding: 9.5px 5px!important;
  background: transparent linear-gradient(180deg, #1A213F 0%, #666A7E 100%) 0% 0% no-repeat padding-box!important;
  color: #FFFFFF!important;
  border-radius: 5px!important;
}

.fakeStatusCancel {
  padding: 9.5px 5px!important;
  background: transparent linear-gradient(180deg, #D13636 0%, #631616 100%) 0% 0% no-repeat padding-box!important;
  color: #FFFFFF!important;
  border-radius: 5px!important;
}

.fakeButton3 { 
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28.5px;

  word-break: break-all;
  color: #F29435!important;
  text-shadow: 0px 3px 6px #00000029;
  background: transparent linear-gradient(90deg, #FFFFFF 0%, #E3E3E3 100%) 0% 0% no-repeat padding-box!important;
  box-shadow: 5px 5px 15px #00000030;
  border-radius: 5px;
  opacity: 1;
}

.fakeButton3Success{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28.5px;

  
  color: #FFFFFF!important;
  text-shadow: 0px 3px 6px #00000029;
  background:  transparent linear-gradient(180deg, #298A50 0%, #78F0A8 100%) 0% 0% no-repeat padding-box!important;
  box-shadow: 5px 5px 15px #00000030;
  border-radius: 5px;
  border: none;
  opacity: 1;
}

.fakeButton3Success:disabled {
  border: none;
}

.fakeButton3Success:disabled:hover {
  border: none;
}

.fakeButton3Success:disabled strong {
  word-break: break-all!important;
  white-space: normal;
}

.fakeButton3:hover{
  background: #E5E4E2;
  color: #E3B280!important;
}

.saveButton {
  margin-top: 28.5px;
  padding: 9.5px 15px!important;
}

label.bold {
  font-weight: 700;
}

.closeButton {
  /* position: relative;
  left: 46%; */
  display: flex;
  justify-content: center;
  align-items: center;
  font: 900 17px Poppins;
  letter-spacing: 1.22px;
  color: #FFFFFF;
  text-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  background: none;
  border: 0;
  cursor: pointer;

  transition: 0.2s;
  text-decoration: none;
}

.closeButton:hover {
  transform: translateY(-5px);
  color: #E5E4E2;
}

.closeButton .icon {
  display: block;
  margin-left: 5px;
}

.calendarActive {
  color: #F29435 !important;
}

.buttonCalendar {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none!important;
  padding: 5px !important;
}
.buttonCalendarResult {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255, 0, 0, 0)!important;
  border: none!important;
  padding: 5px !important;
}

.buttonCalendar:disabled {
  border: none !important;
  color: #666666!important;
}

.buttonThumbs {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f1f1!important;
}

.calendarConcluded {
  color: #00c800 !important;
}

.buttonRemove {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #DF5753!important;
  border: none!important;
}

.thumbsUp {
  color: #00c800;
}

.thumbsDown{
  color: #FF0000;
}

.supplierNegativeBegin {
  background: transparent linear-gradient(180deg, #D13636 0%, #631616 100%) 0% 0% no-repeat padding-box;

}

.supplierNegative {
  background:  #FFD1D1 0% 0% no-repeat padding-box;
}
.supplierPositiveBegin {
  background:  transparent linear-gradient(180deg, #298A50 0%, #78F0A8 100%) 0% 0% no-repeat padding-box;
}
.supplierPositive {
  background: #A9F6C8 0% 0% no-repeat padding-box;
}

.supplierContact {
  background:  transparent linear-gradient(180deg, #F39332 0%, #895929 100%) 0% 0% no-repeat padding-box;
}

.textPositive {
  text-align: left;
  font-size: 20px;
  font-weight: 700;
  font-family: Yu Gothic UI;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-shadow: 0px 2px 6px #0000003B;
  opacity: 1;
  margin-right: 5px;
}

.returnButton {
  background: rgb(255, 0, 0, 0)!important;
  border: none!important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #494E59;
  opacity: 1!important;
  margin-right: 5px!important;
}

.colNegativePositive {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonThumbs:disabled {
  background: #f1f1f1!important;
  border: none;
  color: #666666!important;
}

.rowBtn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rowRetailer{
  margin: 10px 0;
  background: #f1f1f1;
  display: flex;
  align-items: center;
  border-radius: 5px;
  height: 60px;
}

.error {
  color: red;
}

.linkIcon {
  fill: white;
  position: absolute;
  right: 7px;
  height: 15px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .closeButton .icon {
    display: none;
  }
} 

.ButtonInsertSupplier {
  color: #FFFFFF!important;
  font-weight: 500!important;

  background: transparent linear-gradient(90deg, #E3AE77 0%, #F09436 100%) 0% 0% no-repeat padding-box!important;
  box-shadow: 5px 5px 15px #00000030!important;
  border-radius: 4px!important;
  opacity: 1!important;

}

.ButtonInsertSupplier:hover {
  background: transparent linear-gradient(90deg, #E3AE77 0%, #F09436 30%) 0% 0% no-repeat padding-box!important;
}

.helpBtn {
  display: inline-block;
  padding: 0;
  background: none;
  color: #E9A662;
  position: relative;
  top: -5px;
}

.done {
  color: #47CC7C !important;
}