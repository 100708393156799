.holdTabs {
  text-align: right;
  margin: 0px 30px;
  position: relative;
  margin-top: -5px;
  bottom: -6px;
}

.navItemDisabled {
  cursor: pointer;
  background-color: #A7A7A7;
  border-radius: 8px 8px 0px 0px;
}

.aba {
  cursor: pointer;
  background: #F5AA62;
  border-radius: 8px 8px 0px 0px;
  margin-left: 0 !important;
}


.navItem3 {
  background-color: #F08723;
  cursor: pointer;
  border-radius: 8px 8px 0px 0px;
}

.inputRadio {
 margin: auto;
}

/*font-weight: bold;
  padding-top: 13px;
  text-align: center;
  background: #343436;
  color: #fff;
  border-radius: 30px;
  height: 100px;*/