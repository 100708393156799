.headerContainer {
  display: flex;
  justify-content: space-between;

  .logoDiv {
    margin: 0 0 0 40px;
  }

  .logoDiv a:hover {
    text-decoration: none;
  }

  .logo {
    text-align: center;
    padding: 30px 0 0 0;
  }

  .logoSpan {
    letter-spacing: 0px;
    color: #ffffff;
    text-shadow: 2px 1px 2px #0000002e;
    font-size: 27px;
    position: relative;
    top: 6px;
    left: -10px;
  }

  .headerItems {
    padding: 30px 30px 0 30px;
    display: flex;
    justify-content: flex-end;
  }

  .headerNotifications {
    cursor: pointer;
    margin-top: 2px;
    display: none;
  }

  .whiteLogo {
    margin-right: 10px;
    margin-top: 3px;
    margin-left: 5px;
  }

  .whiteLogo img {
    width: 45px;
    border-radius: 100%;
  }

  .userLog {
    margin-right: 30px;
    line-height: 13px;
    margin-top: 5px;
  }

  .userLog p {
    font-weight: 300;
    font-size: 14px;
    color: #999;
    text-shadow: 1px 1px 5px #9a5e2187;
  }

  .logOut {
    margin-top: 5px;
  }

  .logOut button {
    background-color: #f3a75b;
    width: 80px;
    font-size: 16px;
    color: #ffffff;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    padding: 7px;
  }

  .notifications {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 10px 10px 20px #2c28281c;
    border-radius: 10px;
    padding: 30px 0 0px 0;
  }

  .titleNotifications {
    color: #172b4d;
    font-size: 18px;
    padding: 0 30px 30px 30px;
  }

  .notificationsBox {
    padding: 20px;
    margin: auto;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .notificationsBox:hover {
    background-color: #efefef;
  }

  .notificationsIcon {
    float: left;
    display: none;
  }

  .alertNotification {
    font-size: 11px;
    color: #8898aa;
    margin: 5px;
    float: left;
    margin-left: 20px;
    margin-top: 10px;
  }

  .dateNotification {
    font-size: 11px;
    color: #8898aa;
    margin: 5px;
    float: right;
    margin-left: 20px;
    margin-top: 10px;
  }

  .nameProject {
    font-size: 13px;
    color: #172b4d;
    width: 200px;
    margin-left: 60px;
  }

  .iconModal {
    flex-shrink: 0;
  }

  .textTitleModal {
    font-size: 16px;
    color: #8898aa;
    padding: 15px 0;
  }

  .textModal {
    margin: 20px 0;
  }

  .textModal p {
    font-size: 18px;
    line-height: 30px;
    color: #172b4d;
    font-weight: 700;
  }

  .textModal span {
    margin-left: 2px;
  }
}
