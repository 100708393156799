.download-modal {
  font-family: Poppins; 
  font-style: normal;
 
  .div-header {
    display: flex;
    justify-content: space-evenly;

    .div-icon-download {
     width: 50px;
     height: 50px;
     background-color: #3B68FF;
     border-radius: 12px;
     margin-top: 20px;
     margin-left: 180px;
    }

    .icon-download {
      width: 22px;
      height: 22px; 
      color: #FFFFFF; 
      margin: 15px;
    }

    .title-header {
      margin-left: 12px; 
      margin-right: 300px; 
      font-weight: 600; 
      font-size: 22px; 
      line-height: 150%;
      letter-spacing: -0.03em;
      color: #425BA6; 
      white-space: nowrap;
      margin-top: 20px;

    }

    .icon-close {
      color: #353535; 
      width: 20px; 
      height: 20px;
      margin-top: 10px;
      margin-left: -200px;
    }
  }

  .body-modal {
    font-family: Poppins;
    font-style: normal;
    font-size: 14px; 
    line-height: 150%;
    letter-spacing: -0.03em;
    color: #596780;
    margin-left: 26px;
  }

  .div-icon-doc {
    display: flex;

    .icon-doc {
      color: #425BA6; 
      width: 16px; 
      height: 20px;
      margin-left: 26px;
    }

    .link-doc {
      margin-left: 10px;
      font-family: Poppins; 
      font-style: normal;
      font-weight: 500; 
      font-size: 14px; 
      line-height: 24px; 
      color: #425BA6;
      white-space: nowrap;
    }
  }

  .footer-modal {
    margin-left: 100px;

   .button-footer {
    padding: 10px; 
    border-radius: 5px; 
    width: 300px; 
    height: 43px; 
    background: #FFFFFF; 
    font-family: Poppins; 
    font-style: normal; 
    font-weight: 700; 
    font-size: 14px; 
    line-height: 24px; 
    color: #425BA6; 
    white-space: nowrap; 
    border: 1px solid #425BA6; 
    text-align: center;
    margin-bottom: 20px;
   }
  }
}