.pagination-area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 10px;

  .pagination {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // font: normal normal 500 10px/14px Ubuntu;
    font-family: 'Poppins';
    height: 40px;
    margin: 2rem 5px 2rem 20px;
    margin-top: 2rem;

    li {
      text-align: center;
    }
    li + li {
      margin-left: 5px;
    }
    li button {
      text-align: center;
      border: 1px solid #1f2543;
      border-radius: 50%;
      padding: 10px 13px;
      transition: all 0.3s linear;
      margin: 1px;
      height: 39px;
      min-width: 39px;
    }
    li button:hover {
      background: #1f2543;
      box-shadow: 0px 3px 6px #2c28281c;
      color: #ffffff;
    }
    .active {
      background: #1f2543;
      box-shadow: 0px 3px 6px #2c28281c;
      color: #ffffff !important;
    }
    li button:disabled {
      border: 1px solid #1f2543;
      color: #a6a6a6;
    }
    li button:disabled.active {
      background: #1f2543;
      box-shadow: 0px 3px 6px #2c28281c;
      color: #ffffff !important;
    }
    li button.arrows {
      background: none;
      border-radius: 32px !important;
      text-shadow: 0px 2px 3px #0000002b;
    }
    li button.arrows:hover {
      background: #1f2543;
    }
    li button.arrows:disabled {
      text-shadow: none;
    }
  }

  &.new-type {
    li button {
      border: 1px solid #dfe3e8;
      border-radius: 5px;
      background-color: #fff;
    }
    li button.arrows {
      background-color: #fff;
      border-radius: 5px !important;
      color: #000;
    }
    li button:disabled,
    li button.arrows:disabled,
    li button:disabled.active {
      border: 1px solid #dfe3e8;
      color: #d0d4d8;
      background-color: #c3cad0;
      text-shadow: none;
    }
    li button:hover,
    li button.arrows:hover,
    .active,
    .active:hover {
      background: #fff;
      box-shadow: 0px 3px 6px #2c28281c;
      color: #f39332 !important;
      border-color: #f39332;
      text-decoration: none;
    }

    li:first-child button svg,
    li:last-child button svg {
      transform: translateY(-2px);
    }
  }
}
