@import '../../../../../Assets/colors.scss';
@import '../../../../../Assets/mixins.scss';
@import '../../../../../Assets/modals.scss';

.custom-modal {
  .icon-head-day-edition {
    fill: $blue;
  }
  .text-day-edition {
    color: $blue;
    margin: 0;
    position: relative;
    top: 4px;
    font: {
      weight: 600;
      size: 16px;
    }
    @extend .placeholder-fonts;
  }

  .sub-day-edition {
    color: $blue;
    margin: 0;
    position: relative;
    // top: -12px;
    font: {
      weight: 500;
      size: 13px;
    }
    @extend .placeholder-fonts;

    &:after {
      content: '';
      width: 40px;
      height: 6px;
      position: relative;
      display: block;
      top: 5px;
      @include border-radius(3px);
      background-color: $blue;
    }
  }

  .media-days {
    color: $lightBlue;

    span {
      font-size: 26px;
      color: $blue;
      font-weight: normal;
      margin-left: 8px;
    }

    small {
      font-size: 20px;
      padding-left: 10px;
      color: $blue;
    }

    .txt-day-size {
      font-size: 17px;
      color: $blue;
      font-weight: 400;
    }
  }

  // .sub-title-modal-green3 {
  //   &:before {
  //     @include green-gradient-3;
  //   }
  // }

  p {
    @extend .placeholder-fonts;

    span {
      font-weight: bold;
    }
  }

  .modal-body {
    padding: 0px 25px;
  }
  .modal-header {
    border: 0;
    display: block;

    svg {
      margin-top: 3px;
    }
  }
}
