.header {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  overflow: hidden;
  z-index: 2;
  font-family: 'Poppins';

  &.fixed {
    position: fixed;
  }

  h2 {
    font-size: 28px;
    line-height: 30px;
    font-weight: 600;
    margin-left: 110px;
  }

  h3 {
    font-size: 15px;
    line-height: 15px;
    font-weight: normal;
    margin-bottom: -15px;
  }

  &.center {
    align-items: center;
    h2 {
      margin-left: 0;
    }
  }
}
