.mainDiv {
  border-radius: 10px;
  box-shadow: 0px 3px 6px #2c28281c;
  margin: 0 30px;
  position: relative;
}

.mainDiv2 {
  margin: 0 30px !important;
  padding: 10px 0 !important;
  position: relative !important;
}

.countProject {
  font-size: 15px;
  font-weight: bold;
  position: relative;
  line-height: 35px;
  color: #fff;
}

.projectsNumber {
  font-size: 18px;
  font-weight: 300;
  margin-left: 2px;
}

.tableBriefing {
  margin: 0;
}

.tableBriefing th {
  background-image: linear-gradient(#f29435, #e3b280);
  font-size: 14px;
  text-align: center;
  color: #fff;
  padding: 20px !important;
  vertical-align: middle !important;
}

.tableBriefing td {
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  padding: 30px;
  vertical-align: middle !important;
}

.tableBriefing tbody tr:nth-of-type(odd) {
  background-color: #fcefe2 !important;
}

.tableBriefing tbody tr:hover {
  background-color: #fcefe2 !important;
}

.btnProject {
  text-align: center;
  margin: auto;
  background: transparent linear-gradient(90deg, #f39332 0%, #985a1b 100%) 0% 0%
    no-repeat padding-box !important;
  box-shadow: 4px 4px 5px #00000036 !important;
  border-radius: 7px !important;
  text-shadow: 0px 3px 6px #00000029 !important;
  color: #fff !important;
  font-size: 16px !important;
  padding: 8px 20px !important;
  display: block;
  width: 250px;
  font-weight: 500 !important;
  border: unset !important;
  line-height: unset !important;
}

.btnDefault {
  margin: auto;
  text-align: center;
  display: block;
  width: 190px;
  padding: 8px 0px;
  box-shadow: 4px 4px 5px #00000036;
  border-radius: 7px;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.btnDisabledSend {
  composes: btnDefault;
  background: transparent linear-gradient(90deg, #e5e5e5 0%, #faf7fe 100%) 0% 0%
    no-repeat padding-box;
  color: #a6a6a6;
  border: unset !important;
  cursor: default !important;
  pointer-events: none !important;
  opacity: 0.7;
}

.btnDisabledSend:hover {
  color: #a6a6a6;
}

.borderLeftRadius {
  border-radius: 9px 1px 0px 0px;
}

.borderRightRadius {
  border-radius: 0px 9px 0px 0px;
}

.one-third {
  width: unset !important;
}

.paginationSourcing {
  padding: 30px 30px 60px 0;
  display: flex;
  justify-content: flex-end;
}

.paginationSourcing ul li {
  margin: 0 5px;
  border-radius: 50%;
  border: 1px solid #f39332;
}

.paginationSourcing ul li a {
  position: relative;
  display: block;
  padding: 0.6rem 0.85rem;
  line-height: 1.25;
  color: #f39332;
  border: none;
  font-size: 12px;
  background-color: transparent;
  width: 35px;
  height: 35px;
}

.paginationSourcing ul li a:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #f39332;
  border-color: #f39332;
  border-radius: 50%;
}

.paginationSourcing ul li a:focus {
  box-shadow: none !important;
}

.btnGreen {
  composes: btnDefault;
  background: rgb(50, 243, 122);
  background: linear-gradient(
    105deg,
    rgba(50, 243, 122, 1) 0%,
    rgba(3, 156, 60, 1) 100%
  ) !important;
  color: #d1cfcf;
  cursor: pointer;
}

.btnGreen:hover {
  color: #fff !important;
}

.btnBlue {
  composes: btnDefault;
  cursor: default;
  pointer-events: none;
  background: rgb(113, 140, 255);
  background: linear-gradient(
    105deg,
    rgba(113, 140, 255, 1) 0%,
    rgba(0, 48, 255, 1) 100%
  ) !important;
  color: #fff !important;
}

.btnBlue:hover {
  cursor: text !important;
  border: 0 !important;
  opacity: 1;
  background: rgb(113, 140, 255);
  background: linear-gradient(
    105deg,
    rgba(113, 140, 255, 1) 0%,
    rgba(0, 48, 255, 1) 100%
  );
}

.btnBlue:focus {
  cursor: text !important;
  border: 0 !important;
  background: rgb(113, 140, 255);
  background: linear-gradient(
    105deg,
    rgba(113, 140, 255, 1) 0%,
    rgba(0, 48, 255, 1) 100%
  );
}

.searchButton {
  margin-left: -20px;
}

.clearSearchButton {
  margin-left: 825px !important;
}
