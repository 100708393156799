@import '../../../../Assets/colors.scss';
@import '../../../../Assets/mixins.scss';
@import '../../../../Assets/modals.scss';


.sectionSupplierSelected {
  border: 1px solid #9A9B9B;
  padding: 10px;
  border-radius: 6px;
  height: 150px;
  overflow: auto;

  p {
    border-bottom: 1px solid #9A9B9B;
    margin-bottom: 10px;
  }

  ul {
    margin-left: 20px;
  }
}

.modal-dialog {
  &.modal-select-supplier {

    .finalSelected {
      text-align: center;
      font-weight: 400px;
      color: #4BAF5A;
      padding-top: 10px;
    }
    
    .modal-header {
      padding: 0px 20px;
      border: 0;
      margin-top: 20px;

      span {
        color: #F08723;
        font-weight: bold;
      }

      p {
        font-weight: normal;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .modal-footer {

      button {
        width: auto;
        display: inline-flex;
        margin: 0 10px;
      }

      .divButtonFooter {
        max-width: 500px;
        margin: auto;
      }
    }

    .modal-body {

      p {
        margin-bottom: 10px;
      }

      p, li {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400px;
        font-size: 14px;
        line-height: 18px;
      }

      span {
        color: #FF6666;
        font-weight: bold;
        
      }
    }

    .close {
      display: none;
    }
    
  }
}