$_DIRECTIONS: (top right bottom left);

@for $i from 1 through 100 {
  .padding-#{$i} {
    padding: #{$i}px;
  }

  @each $direction in $_DIRECTIONS {
    .padding-#{$direction}-#{$i} {
      padding-#{$direction}: #{$i}px;
    }
  }
}

@mixin border-radius($size) {
  border-radius: $size;
}

.placeholder-box-shadow {
  -webkit-box-shadow: 0px 3px 10px -1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 3px 10px -1px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 10px -1px rgba(0, 0, 0, 0.15);
}
