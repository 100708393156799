.nav-tabs {
  display: inline-flex;
  border: 0;
}

.nav-tabs .nav-link.active {
  background-color: #f08723;
  color: #fff;
  border: 0;
  font-weight: 600;
}

.nav-tabs .nav-link {
 /*  background-color: #f5aa62; */
  font-weight: 600;
  color: #fff;
  border: 0;
  text-transform: uppercase;
  padding: 12px 16px 16px 16px;
  margin-left: 4px;
  border-radius: 8px 8px 0px 0px;
}

.tab-content .active {
  text-decoration: none;
}

.tab-content table tr th {
  text-align: left;
  padding-left: 60px !important;
}

.tab-content table tr td {
  text-align: left;
  padding-left: 60px;
}

.tab-content table tr th {
  text-align: left;
  padding-left: 60px !important;
}

.tab-content table tr td {
  text-align: left;
  padding-left: 60px;
}

.tab-content table tr th:nth-child(3) {
  text-align: center;
  padding-left: 60px !important;
}

.tab-content table tr td:nth-child(3) {
  text-align: center;
  padding-left: 60px;
}

input[type='checkbox'] {
  background-color: #fff;
  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
}

input[type='radio'] {
  background-color: #fff;
  font: inherit;
  color: currentColor;
  width: 22px;
  height: 22px;
}

