:root {
  --cor-azul: #2c4599;
  --cor-laranja: #f39332;
}

.home {
  background: url('../../Assets/images/background-home.webp') top center
    no-repeat;
  background-size: cover;
  margin: auto;
  width: 100%;
  display: flex;
  font-family: 'Poppins';
  padding: 100px;
}

.logoDigital {
  position: absolute;
  top: 25px;
}

.logoDigital span {
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 2px 1px 2px #0000002e;
  font-size: 27px;
  position: relative;
  top: 6px;
  left: -10px;
}

.bemVindo {
  margin-top: 80px;
}

.bemVindo p {
  color: var(--cor-azul);
  background-color: #fff;
  width: 185px;
  height: 26px;
  text-align: center;
  padding-top: 3px;
  border-radius: 16px;
  font-weight: 500;
}

.slogan {
  margin-top: 0px;
  width: 50%;
}

.slogan h1 {
  color: #fff;
  text-shadow: 2px 1px 14px #0000002e;
  font-size: 87px;
  font-weight: 300;
  line-height: 130px;
  margin-left: -5px;
  letter-spacing: 18px;
}

.slogan h1 span {
  font-weight: 600;
}

.slogan h2 {
  color: #fff;
  text-shadow: 2px 1px 14px #0000002e;
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
  font-family: 'Poppins', sans-serif;
  margin: 20px 0;
}

.loginImg {
  /* width: 49%; */
}

.divBtns {
  position: relative;
  /* margin-top: 40px; */
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fdfdfd;
  box-shadow: 2px 5px 20px #00000061;
  border-radius: 12px;
  /* display: block; */
  padding: 5px;
  /* max-width: 160px; */
  text-align: center;
  margin: 40px 10px 0 10px;
  width: 144px;
  height: auto;
}

.divBtns::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: -20px;
  left: -18px;
  bottom: -20px;
  right: -18px;
  background: inherit;
  border-radius: 12px;
  z-index: -1;
  opacity: 0.4;
  -webkit-transform: scale3d(0.8, 0.5, 1);
  transform: scale3d(0.8, 0.5, 1);
}

.divBtns:hover {
  -webkit-animation: anim-moema-1 0.3s forwards;
  animation: anim-moema-1 0.3s forwards;
}

.divBtns:hover::before {
  -webkit-animation: anim-moema-2 0.3s 0.3s forwards;
  animation: anim-moema-2 0.3s 0.3s forwards;
}

@-webkit-keyframes anim-moema-1 {
  60% {
    -webkit-transform: scale3d(0.8, 0.8, 1);
    transform: scale3d(0.8, 0.8, 1);
  }
  85% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes anim-moema-1 {
  60% {
    -webkit-transform: scale3d(0.8, 0.8, 1);
    transform: scale3d(0.8, 0.8, 1);
  }
  85% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes anim-moema-2 {
  to {
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes anim-moema-2 {
  to {
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.divBtns p {
  letter-spacing: 1.62px;
  color: var(--cor-azul);
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
}

.divBtns .btnPlm p {
  margin-top: 5px;
}

.divBtns .imgArea {
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divBtns .imgArea .img {
  display: block;
  height: 75px;
  width: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

/* pre-load all images */
.btnGestaoComercial::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url('../../Assets/images/gestao-comercial-cinza.svg')
    url('../../Assets/images/gestao-comercial-color.svg')
    url('../../Assets/images/indicadores-cinza.svg')
    url('../../Assets/images/indicadores-color.svg')
    url('../../Assets/images/private-label-manager-cinza.svg')
    url('../../Assets/images/private-label-manager-color.svg')
    url('../../Assets/images/base-fornecedores-cinza.svg')
    url('../../Assets/images/base-fornecedores-color.svg')
    url('../../Assets/images/inova-amicci-cinza.svg')
    url('../../Assets/images/inova-amicci-color.svg');
}

.divBtns .btnGestaoComercial .img {
  background: url('../../Assets/images/gestao-comercial-cinza.svg') center
    center no-repeat;
}

.divBtns:hover .btnGestaoComercial .img {
  background: url('../../Assets/images/gestao-comercial-color.svg') center
    center no-repeat;
}

.divBtns .btnIndicators .img {
  background: url('../../Assets/images/indicadores-cinza.svg') center center
    no-repeat;
}

.divBtns:hover .btnIndicators .img {
  background: url('../../Assets/images/indicadores-color.svg') center center
    no-repeat;
}

.divBtns .btnPlm .img {
  background: url('../../Assets/images/private-label-manager-cinza.svg') center
    center no-repeat;
}

.divBtns:hover .btnPlm .img {
  background: url('../../Assets/images/private-label-manager-color.svg') center
    center no-repeat;
}

.divBtns .btnBaseFor .img {
  background: url('../../Assets/images/base-fornecedores-cinza.svg') center
    center no-repeat;
}

.divBtns:hover .btnBaseFor .img {
  background: url('../../Assets/images/base-fornecedores-color.svg') center
    center no-repeat;
}

.divBtns .btnInov .img {
  background: url('../../Assets/images/inova-amicci-cinza.svg') center center
    no-repeat;
}

.divBtns:hover .btnInov .img {
  background: url('../../Assets/images/inova-amicci-color.svg') center center
    no-repeat;
}

.buttonSaibaMais,
.buttonSaibaMais:visited {
  text-align: center;
  display: inline-block;
  padding: 10px 20px;
  line-height: 1;
  text-decoration: none;
  transition: all 0.3s ease;
  border: 3px solid #fff;
  border-radius: 10px;
  box-shadow: 0 3px 6px 7px rgba(0, 0, 0, 0.1),
    inset 0 3px 6px 7px rgba(0, 0, 0, 0.1);
  color: #fff;
  background-color: transparent;
  font-size: 12px;
  font-weight: 600;
}

.buttonSaibaMais:hover {
  background-color: #fff;
  box-shadow: none;
  color: var(--cor-azul);
  transition: all 0.6s ease;
  transform: scale(0.98);
}

.botoesArea {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: -105px;
}

.modalSaibaMais {
  box-shadow: 10px 10px 25px #0000001f;
  max-width: 910px !important;
  width: 910px;
  height: 680px;
  font-family: 'Poppins';
  border-radius: 18px !important;
}

.modalSaibaMais > div {
  border-radius: 18px !important;
}

.closeModal {
  text-align: right;
  padding: 10px;
  font-size: 17px;
  margin-right: -40px;
  font-weight: 700;
  cursor: pointer;
}

.paddingModal {
  padding: 0px 46px 0px 46px;
}

.paddingModal h3 {
  color: var(--cor-azul);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 4px;
  margin-top: -25px;
}

.paddingModal h4 {
  color: var(--cor-azul);
  margin-top: 16px;
  margin-bottom: 40px;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 3px;
  line-height: 26px;
}

.infoArea {
  height: 520px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.infoArea::-webkit-scrollbar {
  width: 12px;
}
.infoArea::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
}
.infoArea::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.06);
}

.textForModal {
  width: 90%;
}

.imgModal img {
  width: 115px;
}

.textForModal h5 {
  color: var(--cor-laranja);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 3px;
  margin-bottom: 10px;
}

.textForModal p {
  color: var(--cor-azul);
  font-size: 14px;
  font-weight: 400;
}

@media (min-width: 1920px) {
  .home {
    height: 100vh;
  }
}

@media (max-width: 1366px) {
  .slogan {
    width: 80%;
  }
}

@media (max-width: 1023px) {
  .divBtns {
    top: 0;
  }

  .buttonSaibaMais {
    margin: 20px auto;
  }

  .btnManagement {
    margin: auto;
  }
}
