@import '../../../../../Assets/colors.scss';
@import '../../../../../Assets/mixins.scss';
@import '../../../../../Assets/modals.scss';

.custom-modal {
  .icon-head-stage-completion {
    fill: $green;
  }

  .text-stage-completion {
    color: $green;
    margin: 0;
    position: relative;
    top: 8px;
    font: {
      weight: 600;
      size: 16px;
    }
    @extend .placeholder-fonts;
  }

  .sub-stage-completion {
    color: $blue;
    margin: 0;
    position: relative;
    // top: -10px;
    line-height: 15px;
    font: {
      weight: 500;
      size: 13px;
    }
    @extend .placeholder-fonts;

    &:after {
      content: '';
      width: 40px;
      height: 6px;
      position: relative;
      display: block;
      top: 5px;
      @include border-radius(3px);
      background-color: $green;
    }
  }
  .date-in {
    border: 3px solid #1f4d74;
    color: #1f4d74;
  }

  p {
    @extend .placeholder-fonts;

    span {
      font-weight: bold;
    }
  }

  .list-next-tasks {
    list-style: none;
    border: 1px solid $gray3;
    border-radius: 5px;
    margin: 20px 0;
    height: 130px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px;

    span {
      font-weight: 600;
      color: $gray3;
    }

    .inside-list {
      padding: 5px 8px;
    }
  }

  .modal-body {
    padding: 0px 25px;
  }

  .modal-header {
    border: 0;
    display: block;

    svg {
      margin-top: 3px;
    }
  }
}
