.loginInfo{
	text-align: center;
	margin-top: 10px;
	margin-bottom: 20px;
	font-size: 20px;
	font-family: 'Poppins', sans-serif;
}

.formLogin{
	padding: 0px 15px;
}

.inputLogin{
	background: #FFFFFF 0% 0% no-repeat padding-box!important;
	box-shadow: 0px 12px 40px #00000014!important;
	letter-spacing: 0.6px!important;
	color: #707070!important;
	font-size: 12px!important;
	display: block!important;
	width: 100%!important;
	padding: 25px!important;
	padding-bottom: 20px!important;
	border: none!important;
	border-left: 5px solid #f3a75b!important;
	font-family: 'Poppins', sans-serif!important;
}

.inputLogin:focus{
	border-left: 5px solid #ffc386;
	outline: none;
}

.forgot{
	margin-top: 5px;
	margin-bottom: 20px;
	font-family: 'Poppins', sans-serif;
	
}
.forgot span:hover{
	cursor: pointer;
}
.forgot span:hover{
	color: #f3a75b;
}
.send{
	display: flex;
	justify-content: center;
}

.buttonSent{
	background-color: #f3a75b!important;
	height: 45px;
	font-size: 17px; 
	color: #ffffff!important;
}
.buttonSentLoading{
	background-color: #ffc386!important;
	height: 45px;
	font-size: 17px;
}



.sentButton{
	letter-spacing: 1.22px;
	color: #707070;
	font-size: 22px;
	font-weight: bold;
	background: transparent;
	display: inline-block;
	font-family: 'Poppins', sans-serif;
}

.infoEnter{
	text-align: center;
	font-size: 14px;
	padding: 80px 0 0 0;
	font-family: 'Poppins', sans-serif;
}

.error{
	color: #cc0000;
	
}

.space{
	margin: 5px 0!important;
}