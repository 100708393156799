.customTxtReason {
  background-color: #f3a75b;
  margin-left: 20px;
  padding: 10px 20px;
  color: #fff;
  float: left;
  border-radius: 5px;
  margin-bottom: 25px;
}

.formBriefing {
  .inputText {
    border-color: transparent;
  }

  .borderOrange {
    border: 2px solid #F39332;

    &:disabled {
      border-color: transparent;
    }
  }

  .rs-picker-toggle-wrapper {
    border: 1px solid #C4D1FF;
  }

  p.status-project {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  .div-status-project {
    float: right;
    margin-right: 40px;
    margin-top: -80px;
    color: #fff;
    font-family: 'Poppins';
    font-size: 12px;
  }

  .buttonStatusEye {
    margin-left: 5px;
    font-size: 14px;
    border-radius: 5px;
    background-color: transparent;
    border: transparent;
    color: #FFF;

    &:disabled {
      background-color: transparent;
    }
  }

  .buttonStatusEyeApp,
  .buttonStatusEyeNA {
    background: #4CB05B;
    border: 2px solid #4CB05B;

    &:disabled { 
      color: #4CB05B;
    }
  }

  .buttonStatusEyeRev {
    border: 2px solid #E64545;
    background-color: #E64545;

    &:disabled {
      color: #E64545;
    }
  }

  .buttonStatusEyeCli {
    border: 2px solid #F39332;
    background-color: #F39332;

    &:disabled {
      color: #F39332;
    }
  }

  .buttonStatusEyeWP {
    border: 2px solid #878B94;
    color: #878B94;
  }

  .containerEyeStatus {
    display: flex;
    margin-top: 10px;
  }

  .mainDiv {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0px 3px 6px #2c28281c;
    margin: 0 30px;
    padding: 0;
    position: relative;

    .briefingLine1,
    .briefingLine2 {
      width: 100%;
      height: 90px;
      display: flex;
      gap: 10px;
      padding: 10px;
    }

    .briefingLine1 {
      background-color: #eaecf3;

      .inputDivisionLineFields {
        flex: 1;
      }
    }

    .briefingLine2 {
      background-color: #fefefe;
      margin-top: 15px;

      .inputDivisionLineFields {
        width: 14%;
        min-width: 150px;
        max-width: 300px;

        &.smallField {
          width: 5%;
          min-width: 70px;
          max-width: 200px;
          margin-top: -16px;
        }

        select {
          max-width: 100% !important;
        }
      }
    }

    .inputOnlyRetailer {
      background-color: #fff;
      padding: 9px 25px;
      border-radius: 6px;
      height: 40px;
      margin: 7px 0;
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .buttonObservation {
      text-align: center !important;
      transform: translateY(-8px);

      .legendButtonObservation {
        font-size: 10px;
        color: #070707;
        font-weight: bold;
        margin: 0;
      }

      .inputWhiteItem {
        box-shadow: 1px 1px 3px #00000030 !important;
        border-radius: 5px !important;
        background: #E5E5E5;
        border: none;
        width: 100% !important;
        height: 40px !important;
        cursor: pointer;

        svg {
          margin-top: 6px;
        }
      }
    }

    .inputDivisionLineFields {
      text-align: center !important;
      display: flex;
      flex-direction: column;

      .legendLineField {
        font-size: 10px;
        color: #070707;
        font-weight: bold;
        margin: 0;
        flex: 1;
      }

      .btnInputCategory {
        margin: 7px 0;
        width: 100%;
        border: 1px solid #c4d1ff;
        border-radius: 6px;
        background-color: #fff;
        font-size: 12px;
        font-weight: 600;
        height: 40px;
        box-shadow: 2px 2px 6px #00000029;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      input,
      select {
        margin: 8px 0;
      }
      .selectPicker {
        margin: 7px 0;
      }
    }

    .headerBriefing {
      background: #f7f7f7;
      padding: 30px;
      width: 700px;
      position: relative;
      // top: -90px;
      border-top-left-radius: 25px;
      border-top-right-radius: 35px;
      border-bottom-right-radius: 15px;
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      margin-left: -10px;

      .headerBriefingCol {
        width: 49%;
      }
    }

    .headerBriefing2 {
      background: #f7f7f7;
      padding: 30px;
      width: 900px;
      position: relative;
      // top: -90px;
      border-top-left-radius: 25px;
      border-top-right-radius: 35px;
      border-bottom-right-radius: 15px;
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      margin-left: -10px;

      .headerBriefingCol {
        width: 49%;

        .inputOnlyRetailer {
          background-color: #fff;
          border: solid 1px orange;
          padding: 9px 25px;
          border-radius: 6px;
          height: 40px;
          margin: 8px 0;
        }
      }
    }

    .closeButton {
      position: relative;
      display: flex;
      /* justify-content: center; */
      /* align-items: center; */
      align-self: flex-end;
      /* font: 900 17px Poppins; */
      /* letter-spacing: 1.22px; */
      /* color: #f39332; */
      /* text-shadow: 0px 3px 6px #00000029; */
      /* opacity: 1; */
      background: none;
      border: 0;
      /* transition: 0.2s; */
      /* width: 130px; */
      margin: -125px 10px 50px 0px;

      &:hover::before {
        content: 'Fechar';
        color: #f39332;
        font-weight: 600;
        display: block;
        //margin: -12px -15px 0 0;
        text-shadow: 0 0 3px #fff, 0 0 5px #8a8a8a;
      }
    }

    .closeButtonIcon {
      display: block;
      /* margin-left: 5px; */
      width: 40px;
    }

    .widthBriefing {
      margin: 0 15px;
      display: flex;
      flex-direction: column;

      .lineLabelsTop {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 10px;
        width: 100%;

        .groupDescrItem {
          display: flex;
          flex-direction: row;
          gap: 10px;

          .groupDescrItemFieldset {
            width: 100%;
            border: 1px solid #2b408b;
            border-bottom: none;
          }

          .groupDescrItemLegend {
            color: #fff;
            display: block;
            text-align: center;
            background: #2b408b;
            font-size: 10px;
            padding: 6px;
            border-radius: 5px;
            width: 140px;
          }
        }

        .groupTarget {
          display: flex;
          flex-direction: row;
          gap: 10px;

          .groupTargetFieldset {
            width: 100%;
            border: 1px solid #3c3c3b;
            border-bottom: none;
          }

          .groupTargetLegend {
            color: #fff;
            display: block;
            text-align: center;
            background: #3c3c3b;
            font-size: 10px;
            padding: 6px;
            border-radius: 5px;
            width: 90px;
          }
        }

        .groupBusinessPrem {
          display: flex;
          flex-direction: row;
          gap: 10px;

          .groupBusinessPremFieldset {
            width: 100%;
            border: 1px solid #efc342;
            border-bottom: none;
          }

          .groupBusinessPremLegend {
            color: #fff;
            display: block;
            text-align: center;
            background: #efc342;
            font-size: 10px;
            padding: 6px;
            border-radius: 5px;
            width: 160px;
          }
        }

        .groupOptions {
          display: flex;
          flex-direction: row;
          gap: 10px;
        }
      }

      .lineLabelMiddle {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin-top: 25px;

        .groupDescrItemMiddle {
          display: flex;
          flex-direction: row;
          gap: 10px;

          .blueBigInput {
            text-align: center;
            flex: 1 1;

            .legendFieldMiddle {
              font-size: 10px;
              color: #070707;
              font-weight: bold;
              margin: 0;
            }

            .question-icon {
              background: #666a7e;
              font-size: 11px;
              border-radius: 100%;
              display: inline-block;
              width: 15px;
              height: 15px;
              color: #fff;
              position: relative;
              left: 5px;
              cursor: pointer;
            }
          }

          .normalSizeInputMiddle {
            text-align: center;
            flex: 1 1;

            .legendFieldMiddle {
              font-size: 10px;
              color: #070707;
              font-weight: bold;
              margin: 0;
            }

            .question-icon {
              background: #666a7e;
              font-size: 11px;
              border-radius: 100%;
              display: inline-block;
              width: 15px;
              height: 15px;
              color: #fff;
              position: relative;
              left: 5px;
              cursor: pointer;
            }
          }
        }

        .groupTargetMiddle {
          display: flex;
          flex-direction: row;
          gap: 10px;

          .blueBigInput {
            text-align: center;
            flex: 2 2;

            .legendFieldMiddleTarget {
              font-size: 10px;
              color: #070707;
              font-weight: bold;
              margin: 0;
              position: relative;
              margin-top: -20px !important;
            }

            .question-icon {
              background: #666a7e;
              font-size: 11px;
              border-radius: 100%;
              display: inline-block;
              width: 15px;
              height: 15px;
              color: #fff;
              position: relative;
              left: 5px;
              cursor: pointer;
            }
          }

          .normalSizeInputMiddle {
            text-align: center;
            flex: 1 1;

            .legendFieldMiddleTarget {
              font-size: 10px;
              color: #070707;
              font-weight: bold;
              margin: 0;
              position: relative;
              margin-top: -20px !important;
            }

            .question-icon {
              background: #666a7e;
              font-size: 11px;
              border-radius: 100%;
              display: inline-block;
              width: 15px;
              height: 15px;
              color: #fff;
              position: relative;
              left: 5px;
              cursor: pointer;
            }
          }
        }

        .groupBusinessPremMiddle {
          display: flex;
          flex-direction: row;
          gap: 10px;

          .blueBigInput {
            text-align: center;
            flex: 2 2;

            .legendFieldMiddleTarget {
              font-size: 10px;
              color: #070707;
              font-weight: bold;
              margin: 0;
              position: relative;
              margin-top: -20px !important;
            }

            .question-icon {
              background: #666a7e;
              font-size: 11px;
              border-radius: 100%;
              display: inline-block;
              width: 15px;
              height: 15px;
              color: #fff;
              position: relative;
              left: 5px;
              cursor: pointer;
            }
          }

          .normalSizeInputMiddle {
            text-align: center;
            flex: 1 1;

            .legendFieldMiddleTarget {
              font-size: 10px;
              color: #070707;
              font-weight: bold;
              margin: 0;
              position: relative;
              margin-top: -20px !important;
            }

            .legendFieldMiddleStock {
              font-size: 10px;
              color: #070707;
              font-weight: bold;
              margin: 0;
              position: relative;
              margin-top: -35px !important;
            }

            .question-icon {
              background: #666a7e;
              font-size: 11px;
              border-radius: 100%;
              display: inline-block;
              width: 15px;
              height: 15px;
              color: #fff;
              position: relative;
              left: 5px;
              cursor: pointer;
            }
          }
        }

        .groupOptionsMiddle {
          display: flex;
          flex-direction: row;
          gap: 10px;

          .whiteSmallInput {
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            flex: 1 1;

            .legendFieldMiddleOption {
              font-size: 10px;
              color: #070707;
              font-weight: bold;
              margin: 0;
            }

            .btnOption {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 36px;
              border: none;
              border-radius: 5px;
              background: #E5E5E5;

              &:disabled {
                background-color: #f5f5f5;

                svg {
                  color: #575757;
                }
              }

              svg {
                color: #2B408B;
              }
            }
          }

          .whiteMedInput {
            align-items: center;
            flex: 2 2;

            .legendFieldMiddleOption {
              font-size: 10px;
              color: #070707;
              font-weight: bold;
              margin: 0;
            }

            .btnOption {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 36px;
              border: none;
              border-radius: 5px;
              background: #E5E5E5;

              &:disabled {
                background-color: #f5f5f5;

                svg {
                  color: #575757;
                }
              }

              svg {
                color: #2B408B;
              }
            }

            .inputDestinyIcon {
              transform: translateY(-2px);
            }
          }
        }
      }

      .lineInputsArea {
        display: flex;
        flex-direction: column;

        .lineInputsLine {
          display: flex;
          flex-direction: row;
          gap: 10px;
          margin-bottom: 5px;

          .groupDescrItemLineInput {
            display: flex;
            flex-direction: row;
            gap: 10px;

            .blueBigInput {
              text-align: center;
              flex: 1 1;
              width: 125px;
            }

            .normalSizeInput {
              text-align: center;
              flex: 1 1;

              .btnInput {
                height: 100%;
                width: 100%;
                border: 1px solid #c4d1ff;
                border-radius: 6px;
                background-color: #fff;
                font-size: 12px;
                font-weight: 600;
              }

              .inputGrayItem {
                box-shadow: 1px 1px 3px #00000030 !important;
                border-radius: 5px !important;
                background-color: #d1d1d1 !important;
                border: none;
                font-size: 12px !important;
                color: #080808 !important;
                font-weight: 700 !important;
                height: 36px !important;
                text-align: center;

                &:focus {
                  box-shadow: 1px 1px 3px #00000030;
                  border-radius: 5px;
                  background-color: #d1d1d1;
                  border: none;
                }
              }
            }

            .inputBlueItem {
              box-shadow: 1px 1px 3px #00000030 !important;
              border-radius: 5px !important;
              background-color: #c4d1ff !important;
              border: none;
              text-align: center;
              font-size: 12px !important;
              color: #080808 !important;
              font-weight: 700 !important;
              height: 36px !important;
              text-align: center;

              &:focus {
                box-shadow: 1px 1px 3px #00000030;
                border-radius: 5px;
                background-color: #c4d1ff;
                border: none;
              }
            }
          }
        }
      }

      .groupBusinessPrem {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .normalSizeInput {
          text-align: center;
          flex: 1 1;

          .inputYellowItem {
            box-shadow: 1px 1px 3px #00000030 !important;
            border-radius: 5px !important;
            background-color: #ffe7a1 !important;
            border: none !important;
            font-size: 12px !important;
            color: #080808 !important;
            font-weight: 700 !important;
            height: 36px !important;
            text-align: center;

            &:focus {
              box-shadow: 1px 1px 3px #00000030;
              border-radius: 5px;
              background-color: #ffe7a1;
              border: none;
            }
          }
        }
      }

      .groupOptionsFinal {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .whiteSmallInput {
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          flex: 1 1;

          .legendFieldMiddleOption {
            font-size: 10px;
            color: #070707;
            font-weight: bold;
            margin: 0;
          }

          .btnOption {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 36px;
            border: none;
            border-radius: 5px;
            background: #E5E5E5;

              &:disabled {
                background-color: #f5f5f5;

                svg {
                  color: #575757;
                }
              }

              svg {
                color: #2B408B;
              }
          }
        }

        .whiteMedInput {
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 2 2;

          .legendFieldMiddleOption {
            font-size: 10px;
            color: #070707;
            font-weight: bold;
            margin: 0;
          }

          .btnOption {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 36px;
            border: none;
            border-radius: 5px;
            background: #E5E5E5;
            margin: 0 2.5px;

              &:disabled {
                background-color: #f5f5f5;

                svg {
                  color: #575757;
                }
              }

              svg {
                color: #2B408B;
              }
          }

          .inputDestinyIcon {
            transform: translateY(-2px);
          }

          .inputDuplicateIcon {
            transform: scale(0.7) translateY(-2px);
          }
        }
      }

      .moreButton {
        margin: 20px;
        width: 150px;
        background-color: transparent;
        border: 1px solid #f39332;
        border-radius: 5px;
        color: #f39332;
        font-size: 14px;

        .moreIconSky {
          margin-right: 8px;
        }
      }

      .getSuppliers {
        max-width: 45%;

        .inputGetSuppliers {
          background: #2b408b;
          padding: 10px;
          margin: 20px;
          color: #fff;
          font-size: 13px;
          border-radius: 5px;
          cursor: pointer;
          max-width: 242px;

          &:disabled {
            background: lightgray;
            color: gray;
            pointer-events: none;
          }

          .inputGetSuppliers span {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .totalButtons {
    margin: 20px 40px;
    display: flex;
    justify-content: space-between;

    .mainButtons {
      display: flex;
      gap: 12px;

      button:last-child {
        margin-left: 10px;
      }
    }

    button {
      width: 100% !important;
      height: 32px;
      padding: 6px 12px;
      white-space: nowrap;
      border-radius: 4px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #ffffff;

      &:disabled {
        background: #E3E3E3 !important;
      }
    }

    .actionBtnRed {
      background: #fd4646;
    }

    .actionBtnGreen {
      background: #47cc7c;
    }

    .actionBtnBlue {
      background: #425ba6;
    }
  }

  .buttonBlue {
    padding: 0 40px;
    white-space: nowrap;
    width: 100%;
    padding: 6px 16px;
    background: #425ba6;
    border-radius: 4px;
    border: #425ba6;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
  }

  .buttonGreen {
    white-space: nowrap;
    width: 100%;
    height: 32px;
    background: #47cc7c;
    border-radius: 4px;
    border: #47cc7c;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
  }

  .noBtnGrey {
    color: #fff;
    background-color: #e1e4e8;
    padding: 7px 15px;
    border-radius: 5px;
    font-weight: 600;
  }

  .buttonUploadBlue {
    padding: 0 40px;
    white-space: nowrap;
    padding: 6px 16px;
    border-radius: 4px;
    border: 1px solid #425ba6;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #425ba6;
  }

  .buttonUploadGreen {
    @extend .buttonUploadBlue;
    border-color: #47cc7c;
    color: #47cc7c;
  }

  .buttonUploadRed {
    @extend .buttonUploadBlue;
    border-color: #fd4646;
    color: #fd4646;
  }

  .fileOnServerDiv {
    display: flex;
    justify-content: flex-end;
  }

  .fileOnServer {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #2c282838;
    border: 1px solid #d8d8d826;
    width: 550px;
    padding: 14px;
    margin: 20px;
    border-radius: 10px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .listDocs {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
