@import '../../../../../../Assets/colors.scss';
@import '../../../../../../Assets/mixins.scss';
@import '../../../../../../Assets/modals.scss';

.modal-briefing-review {
  font-family: 'Poppins';
  font-style: normal;
  color: #171B24;

  .container-modal {
    background: #E1E4E8;
  }

  .lineHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    align-content: center;
    padding: 20px;

    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
    }

    .status {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
    }

    .statusApproved, .statusAppByManager {
      color: #4CB05B;
    }

    .statusInRevision {
      color: #E64545;
    }

    .statusPendApp {
      color: #F39332;
    }
  }

  .subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding-left: 20px;
  }

  .main-modal {
    margin: 20px;
    padding: 20px;
    background: #FFF;
    max-height: 400px;
    overflow-y: scroll;
    border-radius: 2px;
  }

  .title-main {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .text-observation {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  .div-observation {
    padding: 15px 0;

    &:not(:last-child) {
      border-bottom: 1px dashed #000000;
    }
  }

  .div-btn-open {
    text-align: end;
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px 20px 0;
  }

  .btn-open {
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    border: none;
    padding: 5px 12px;
    background: #2B408B;
  }
}
