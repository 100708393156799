
.tabs {
  position: relative;
  margin: 16px 30px;
}
a {
  text-decoration:none!important;
}

.notSelect{
  display: flex;
  justify-content:center;
  align-items: center;
  
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.4px;
  padding: 10px 9px;
  
}
.notSelect a{
  color: #fff!important;
}
.notSelect a:hover{
  color: #ffffff !important;
  opacity: 0.6 !important;
}
.select{
  display: flex;
  justify-content:center;
  align-items: center;

  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 6px #00000061;
  border-radius: 7px;
  color: #1A213F!important;

  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.4px;
  padding: 10px 9px;
  
}
.select a{
  color: #1A213F!important;
}
.select a:hover{
  color: #1A213F!important;
}



