.modal-revision-skus {
   font-family: 'Poppins';
   font-style: normal;

   .header-rev-skus {
      padding: 30px 50px 15px 30px;
      display: flex;

      .title-modal {
         font-weight: 600;
         font-size: 16px;
         line-height: 24px;
         color: #E54545;
        }
     
        .subtitle-modal {
         font-weight: 500;
         font-size: 14px;
         line-height: 21px;
         color: #6078BF;
         margin-top: 10px;
         padding-right: 30px;
        }
   }

   .table-modal-details-skus {
    height: 200px;
    overflow-y: scroll;
    padding-left: 15px;

    tr {
      height: 50px;
    }
   }

   .name-list-modal-details-skus,
   .variant-list-modal-details-skus,
   .package-list-modal-details-skus,
   .weight-list-modal-details-skus,
   .ean-list-modal-details-skus {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #303440;
      width: 200px;
      white-space: nowrap;
   }

   .name-list-modal-details-skus {
      font-weight: 500;
      width: 280px;
     }

   .status-list-modal-details-skus {
      .sku-cancelled {
         font-weight: 400;
         font-size: 14px;
         line-height: 21px;
         color: #E64545;
      }
   }

   .div-buttons {
      display: flex;
      justify-content: center;
      padding: 30px 0;

      button {
         height: 32px;
         font-weight: 700;
         font-size: 14px;
         line-height: 21px;
         color: #FFFFFF;
         border-radius: 4px;
      }

      .btn-edit-skus {
         width: 134px;
         background: #F39332;
      }

      .btn-finish-step {
         width: 197px;
         background: #2B408B;
      }
   }
}
