.modalTittle{
  text-align: left;
  font-size: 16px;
  font-family: Ubuntu;
  font-weight: 700;
  letter-spacing: 1.2px;
  color: #F39332;

  text-transform: uppercase;
}


.body {
  min-height: 200px;
  max-height: auto!important;
  overflow: hidden!important; 
  background: #FFFFFF!important;
}

.tableBriefing{
  margin: 0;
}
.tableBriefing th{
  font-size: 14px;
  text-align: center;
  color: #F39332;
  padding: 10px!important;
  vertical-align: middle!important;
  
}

.tableBriefing td{
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  vertical-align: middle!important;
}
.tableBriefing tr {
  background: #FFFFFF!important;
}




.borderLeftRadius {
  border-radius: 9px 0px 0px 0px;
}

.borderRightRadius {
  border-radius: 0px 9px 0px 0px;
}

