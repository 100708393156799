.buttonOutline {
  font-size: 16px;
  font-family: 'Poppins';
  font-weight: 600;
  background-color: transparent;
  border: 1px solid #f39332;
  border-radius: 5px;
  color: #f39332;
  padding: 6px 10px;

  &:disabled {
    color: #999;
    border-color: #999;
  }
}
