.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: transparent linear-gradient(90deg, #FFFFFF 0%, #ABB9F6 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000004F;
  min-height: 150px!important;
  border-radius: 8px;
  margin: 5px;
  padding: 7px 16px!important;
  cursor: pointer;
  border: None;
  transition: border 2s;
}

.card:hover{
  background: transparent linear-gradient(90deg, #FFFFFF 0%, #ABB9F6 50%) 0% 0% no-repeat padding-box;
  border: 1px solid #1A213F;
}


.prospectionName {
  text-align: left;
  color: #1A213F;
  font-weight: 300;
  font-size: 20px;
  text-shadow: 0px 3px 6px #00000029;
}

.prospectionTitle {
  text-align: left;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 18px;
  text-shadow: 0px 3px 6px #00000029;
}

.prospectionDateBegin {
  text-align: left;
  color: #1A213F;
  font-weight: 300;
  font-size: 14px;
  text-shadow: 0px 3px 6px #00000029;
}

