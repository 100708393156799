.wrapper {
  position: relative;
  width: 100%;
  height: 80vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0404042e;
}

.loading {
  display: flex;
  padding-left: 0px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.animeLoading {
  animation: animeLoading 2s linear 0.2s infinite reverse;
}

@keyframes animeLoading {
  from {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
  to {
  }
}
