@import '../../../../../Assets/colors.scss';
@import '../../../../../Assets/mixins.scss';
@import '../../../../../Assets/modals.scss';

.custom-modal {
  .icon-head-steps {
    fill: $darkBlue;

    &.color-red {
      fill: $red;
    }
  }

  .texts-modal {
    color: $darkBlue;
  }

  .date-in {
    border: 3px solid #1f4d74;
    color: #1f4d74;
  }

  .text-steps {
    color: $blue;
    margin: 0;
    position: relative;
    top: 5px;

    font: {
      weight: 600;
      size: 16px;
    }

    @extend .placeholder-fonts;

    &.color-red {
      color: $red;
    }
  }

  .sub-text-steps {
    color: $blue;
    margin: 0;
    position: relative;
    // top: -10px;
    // border-bottom: 7px transparent
    //   linear-gradient(180deg, #78f0a8 0%, #148440 100%) 0% 0% no-repeat
    //   padding-box;
    word-break: break-word;

    font: {
      weight: 500;
      size: 13px;
    }

    @extend .placeholder-fonts;
    // line-height: 15px;

    &:after {
      content: '';
      width: 40px;
      height: 6px;
      position: relative;
      display: block;
      top: 5px;
      @include border-radius(3px);
      background-color: $blue;
    }

    &.bar-red {
      &:after {
        background-color: $red;
      }
    }
  }

  .sub-title-modal-green2 {
    &:before {
      @include green-gradient-2;
    }
  }

  p {
    @extend .placeholder-fonts;

    span {
      font-weight: bold;
    }
  }

  .modal-body {
    padding: 0px 25px;
  }

  .modal-header {
    border: 0;
    display: block;

    svg {
      margin-top: 3px;
    }
  }
}