.text-modal {
  font-weight: 700;
}

.reasonListItens {
  li {
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;

    label {
     display: inline-flex;
     margin-right: 15px;

     .reasonListInput {
       width: 18px;
       height: 18px;
       margin-top: 4px;
       margin-right: 5px;
      }
    }
  }
}

.textLimited {
  font-size: 11px;
  padding-left: 90px;
  color: #ccc;
}

.div-buttons {
  max-width: 100%;
  margin: auto;
  text-align: center;

  button {
    display: initial;
    margin-right: 10px;
    text-align: center !important;
  }

  .button-cancelar {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #2c28281c;
    border: 1px solid #f39332;
    border-radius: 5px;
    padding: 5px;
    width: 120px;
    font-size: 14px;
    color: #f39332;
  }

  .button-confirmar {
    background: #ffffff 0% 0% no-repeat padding-box;
    background: linear-gradient(0deg, rgba(237, 237, 238, 1) 0%, rgba(212, 209, 209, 1) 100%);
    box-shadow: 0px 3px 6px #2c28281c !important;
    border-radius: 5px;
    padding: 5px;
    width: 120px;
    font-size: 14px;
    font-weight: 600;
    color: #202644 !important;
}
}





