@import '../../../../../Assets/colors.scss';
@import '../../../../../Assets/mixins.scss';
@import '../../../../../Assets/modals.scss';

.custom-modal {
  .icon-head-modal-status {
    &.blue {
      fill: $blue;
    }
    &.orange {
      fill: $orange;
    }
    &.red {
      fill: $red;
      background-color: #fff;
    }
    &.black {
      fill: #30343e;
    }
    &.green {
      fill: $green;
    }
  }

  .error-input {
    border: 2px solid rgb(253, 68, 68);
  }

  .text-status-project {
    margin: 0;
    position: relative;
    top: 4px;
    font: {
      weight: 600;
      size: 16px;
    }

    &.blue {
      color: $blue;
    }
    &.orange {
      color: $orange;
    }
    &.red {
      color: $red;
      background-color: #fff;
    }
    &.black {
      color: #30343e;
    }
    &.green {
      color: $green;
    }
    @extend .placeholder-fonts;
  }

  .sub-status-project {
    margin: 0;
    position: relative;
    font: {
      weight: 500;
      size: 15px;
    }
    @extend .placeholder-fonts;

    &.blue {
      color: $blue;

      &:after {
        content: '';
        width: 40px;
        height: 6px;
        position: relative;
        display: block;
        top: 5px;
        @include border-radius(3px);
        background-color: $blue;
      }
    }

    &.orange {
      color: $orange;

      &:after {
        content: '';
        width: 40px;
        height: 6px;
        position: relative;
        display: block;
        top: 5px;
        @include border-radius(3px);
        background-color: $orange;
      }
    }

    &.red {
      color: $red;
      background-color: #fff;

      &:after {
        content: '';
        width: 40px;
        height: 6px;
        position: relative;
        display: block;
        top: 5px;
        @include border-radius(3px);
        background-color: $red;
      }
    }

    &.black {
      color: #30343e;

      &:after {
        content: '';
        width: 40px;
        height: 6px;
        position: relative;
        display: block;
        top: 5px;
        @include border-radius(3px);
        background-color: #30343e;
      }
    }

    &.green {
      color: $green;

      &:after {
        content: '';
        width: 40px;
        height: 6px;
        position: relative;
        display: block;
        top: 5px;
        @include border-radius(3px);
        background-color: $green;
      }
    }
  }

  .sub-title-modal-green3 {
    &:before {
      @include green-gradient-3;
    }
  }

  p {
    @extend .placeholder-fonts;

    span {
      font-weight: bold;
    }
  }

  .modal-body {
    padding: 0px 25px;
  }
  .modal-header {
    border: 0;
    display: block;

    svg {
      margin-top: 3px;
    }

    // #linear-gradient {
    //   &.linear-gradient-blue {
    //     --color-stop: #699bc5;
    //     --color-bot: #354e63;
    //   }
    //   &.linear-gradient-pink {
    //     --color-stop: #f95c87;
    //     --color-bot: #b9254e;
    //   }
    //   &.linear-gradient-orange {
    //     --color-stop: #e3b27e;
    //     --color-bot: #f39332;
    //   }
    //   &.linear-gradient-green {
    //     --color-stop: #78f0a8;
    //     --color-bot: #8dc6a5;
    //   }
    //   &.linear-gradient-black {
    //     --color-stop: #1b213f;
    //     --color-bot: #4e4e4e;
    //   }
    // }
  }
}
