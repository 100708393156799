.uiModuleButton {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  padding: 5px 10px;
  flex-grow: 1;
  max-width: 130px;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out transform .15s ease-in-out;

  &.active {
    background: #E1E4E8;
    border-radius: 5px;
    text-decoration: none;
  }

  &.apply {
    path {
      fill: #4B5FA6;
    }
    color: #4B5FA6;
  }

  &.concluded {
    path {
      fill: #56B464;
    }
    color: #56B464;
  }

  &.not-started {
    path {
      fill: #878B94
      ;
    }
    color: #878B94
    ;
  }

  &:active {
    border-radius: 8px;
    background-color: rgb(251, 251, 251);
    box-shadow: inset -2px -2px 4px rgb(0, 0, 0, 0.2);
  }

  &:disabled {
    opacity: 0.4;
    border: 0!important;

    &:hover {
      border: 0!important;
      color: #d6dae8;
    }
    &:active {
      background-color: unset;
      box-shadow: unset;
    }
  }
}

.uiModuleButton img{
  width: 50px;
  height: 50px;
}

.uiModuleButton .tittle {
  font: normal normal 500 12px/16px Montserrat;
  letter-spacing: 0.34px;
  /* color: #699BC5; */
  padding: 5px;
  min-height: 40px;
  &.dark {
    color: #5B6075;
  }
}

.divIconDes {
  position: relative;
  display: flex;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;

  svg:nth-child(2) {
    position: absolute;
    left: 0;
    top: 0;
  }
}
