.SelectPickerAmicci {
  display: flex;
  flex-direction: column;
  font-family: 'Poppins';

  .spInput {
    display: flex;
    width: 100%;
    font-size: 12px;
    height: 38px;
    border: 1px solid #888;
    border-radius: 8px;
    padding: 0 8px;
    cursor: pointer;
  }

  .spBtnIcon {
    background-color: #fff;
    border: none;
    font-size: 10px;
    align-self: flex-end;
    margin: -26px 4px 0 0;
  }

  .spBtnClear {
    background-color: #fff;
    border: none;
    align-self: flex-end;
    font-size: 12px;
    margin: -19px 18px 0 0;
  }

  .spWindowOptions {
    flex-direction: column;
    position: absolute;
    z-index: 99;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 38px;
    box-shadow: 0 1px 4px 1px #d4d3d3;
  }

  .spInputSearch {
    margin: 10px;
    padding: 4px 8px;
    border: 1px solid #666;
    border-radius: 5px;
    font-size: 14px;
  }

  .spInputSearch:focus {
    outline: none;
  }

  .spIconSearch {
    align-self: flex-end;
    margin: -32px 15px 0 0;
  }

  .spListOptions {
    margin-top: 10px;
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 10px;
  }

  .spOption {
    padding: 3px 10px;
  }

  .spOptionButton {
    background-color: transparent;
    border: none;
    color: #999;
    width: 100%;
    padding: 4px;
    text-align: left;
    white-space: nowrap;
  }

  .spError {
    margin: 14px 0 0 0px;
    font-size: 12px;
    color: #cc2929;
  }

  /** scrollbar **/
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
