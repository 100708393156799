.TitleName {
  color: #F39332;
  font-size: 25px;
  text-align: left;
}

.img {
  margin-right: 15px;
}


.rowTitle {
  margin-bottom: 20px;
}
.colTitle {
  display: flex;
  align-items: center;
}



