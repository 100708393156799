.modal-dialog {
  position: relative;
  &.custom-modal {
    position: relative;

    p {
      font-size: 13.5px;
      color: $primary3;
    }
    .placeholder-fonts {
      margin: 5px 0px;
      letter-spacing: 2px;
      line-height: 25px;

      font: {
        family: 'Montserrat', sans-serif;
        weight: 500;
      }
    }
    .texts-modal {
      padding-left: 40px;

      &.texts-modal-v2 {
        padding-left: 16px;
      }

      &.texts-modal-v3 {
        padding-left: 30px;
      }
    }
    .texts-input {
      font-size: 15px;
      color: $primary3;

      span {
        display: block;
        margin-top: 10px;
      }
    }
    &.modal-dialog {
      padding: 0px;
      margin: auto;
      max-width: 520px;
      position: relative;

      &.modal-dialog-large {
        max-width: 706px;
        margin: auto;
      }
    }
    .date-modal {
      font-size: 14px;
      font-weight: normal;
      color: $purple3;
      text-align: right;
      padding-top: 7px;
    }

    .modal-title {
      svg {
        position: relative;
        top: 8px;
      }
    }
    .modal-footer {
      border: 0 !important;
      display: block !important;
      text-align: center;
      padding: 15px 0px 14px 0px;

      button {
        margin: 0px 10px;
      }
    }
    .modal-content {
      padding: 0px 3px 7px 0px;
      border: 0 !important;
      @extend .placeholder-box-shadow;
    }
    .input-half {
      .inputTextComponent {
        max-width: 115px;
      }
    }
  }
}

.modal {
  .modal-position-right {
    position: absolute !important;
    bottom: 70px;
    right: 50px;
  }

  .modal-position-left {
    position: absolute !important;
    bottom: 70px;
    left: 70px;
  }
}
