.iconTittle {
  display: flex;
  align-items: center;
}


.modalIcon{
  display: flex;
  justify-content:center;
  align-items: center;
  height: 40px;
  width: 40px!important;
  color: #FFFF;
  border-radius: 50%;
  background: transparent linear-gradient(180deg, #F59B40 0%, #BE772F 100%) 0% 0% no-repeat padding-box;
}

.modalTittle{
  text-align: left;
  font-size: 16px;
  font-family: Ubuntu;
  font-weight: 700;
  letter-spacing: 1.2px;
  color: #F39332;

  text-transform: uppercase;
}

.modalDescription {
  text-align: left;
  font-size: 14px;
  font-family: Ubuntu;
  font-weight: 700;
  letter-spacing: 1.2px;
  color: #615b56;
}

.modalDescriptionClient {
  color: #F39332;
}
textarea.bodyCard{
  border-radius: 5px;
  border: 2px solid #FFE7CE;
  resize:none;
  outline:none;
  min-height: 100px;
  overflow:none!important;
  width: 100%;
}
textarea.bodyCard::-webkit-input-placeholder{
  color: #615b56;
}
textarea.bodyCard::-moz-placeholder{
  color: #615b56;
}

textarea.bodyCard:disabled {
  color: #615b56;
}
.body {
  min-height: 200px;
  max-height: 300px!important;
  overflow: hidden!important; 
}

.buttonDetails {
  font-size: 14px;
  font-family: Ubuntu;
  font-weight: 700;
  cursor: pointer;
  color: #615b56;
}

.buttonDetails:hover {
  color:#8e8e93
}

.error{
  display: block;
  color: red;
}
