.mainDiv{
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #2C28281C;
  margin: 0 30px;
  position: relative;
}

.header{
  background-image: linear-gradient(#F29435, #E3B280);
  font-size: 14px;
  text-align: center;
  color: #fff;
  padding: 20px 59px!important;
  vertical-align: middle!important;
  border-radius: 8px 8px 0 0;
  margin: 0!important;
}
