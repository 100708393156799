// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');
@import '../../../../../../Assets/colors.scss';

.stage-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#stages {
  &.disabled-component {
    pointer-events: none !important;
    opacity: 0.6 !important;
  }

  &.table {
    font-family: 'Poppins';
    font-size: calc(0.0065 * 100vw);

    th {
      padding-top: 0;
      font-weight: medium;
      padding: 5px;
      border: 0.3rem solid #fff;
    }

    td {
      vertical-align: middle;
      color: #9a9b9b;
      letter-spacing: 0px;
      padding: 0;
      background-color: #f1f2f4;
      border: 0.3rem solid #fff;

      &.backWhite {
        background-color: #fff;
      }

      &:nth-child(n + 3) {
        white-space: nowrap !important;
      }
      &.active {
        color: #bfc1fb;
        text-decoration: none !important;
      }
    }

    tr {
      text-align: center;
    }
    thead {
      color: #4b427982;
      letter-spacing: 0.2px;
    }

    tbody {
      .stage-title {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 34px;
        min-width: 120px !important;
        padding: 2px;
        background: #f9fcff 0% 0% no-repeat padding-box;
        color: #1f2543;
        font-weight: 600;

        &.color-red {
          background-color: $red;
          color: #ffff;
        }

        &.color-blue {
          background-color: $blue;
          color: #ffff;
        }
        &.color-green {
          background-color: $green;
          color: #ffff;
        }
      }
      i {
        &.fa-2x {
          font-size: 1.5em;
        }
      }
    }
  }

  .innerBtnDaysToFinish {
    width: 100%;
    display: flex;
    flex-direction: column;

    svg {
      font-size: 0.5rem;
      align-self: flex-end;
      margin: -4px -11px 0 0;
    }

    span {
      margin-top: -3px;
    }
  }
}

// @media only screen and (min-device-width: 1365px) and (max-device-width: 1801px) {
//   #stages {
//     &.table {

//       td {
//         &:first-child {
//           width: 30%;
//         }
//         &:last-child {
//           width: 20%;
//         }
//       }
//     }
//   }
// }
