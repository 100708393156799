.content.modalBody {
  position: relative;
  padding: 44px 44px 20px 44px;
}

.btnCloseCustom {
  background: none;
  border: 0;
  padding: 0;
  position: absolute;
  top: 21px;
  right: 21px;
  width: 27px;
  height: 27px;
  color: #000;
}

.btnCloseCustom > svg {
  width: inherit;
  height: inherit;
}

.guideCol {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 18px 0;
}

.guideCol p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #596780;
  margin: 0;
}

.guideCol strong {
  display: inline-block;
  font-size: 22px;
  color: #DF5753;
  font-weight: 600;
  margin: 0 0 8px 0;
}

.orange {
  color: #ec8c32;
}

.highlight {
  font-weight: 500;
  color: #ecb22e;
}

.midHead {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #191D23;
  margin: 16px 0;
}

.midHead {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #191D23;
  margin: 16px 0;
}

.label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #191D23;
}

.subText {
  color: #64748B;
  font-weight: 400;
}

.compGuideSquare {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  margin: 0 20px 0 0;
  border: 1px solid #DF5753;
}

.compGuideSquare svg {
  width: 12px;
  height: 12px;
}

.compGuideSquare svg > path {
  width: 12px;
  fill: #DF5753;
}

.list {
  padding: 0 0 0 60px;
}

textarea.bodyCard{
  border: 1px solid #D0D5DD;
  border-radius: 4px;
  resize:none;
  outline:none;
  min-height: 100px;
  overflow:none!important;
  width: 100%;
  padding: 12px 8px 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #64748B;
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  border-radius: 4px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF;
  border: 0!important;
}

.btn.cancel {
  background: #DF5753;
  margin: 0 8px 0 0;
}

.btn.action {
  background: #425BA6;
  width: 100%;
}

.btn:disabled {
  background: #d9d9d9;
  color: #191D23;
}