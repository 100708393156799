.InputSearchAmicci {
  display: flex;
  flex-direction: column;
  font-family: 'Poppins';

  .isInput {
    display: flex;
    width: 100%;
    font-size: 12px;
    height: 38px;
    border: 1px solid #888;
    border-radius: 8px;
    padding: 0 8px;
  }

  .isBtnSearch {
    background-color: #fff;
    border: none;
    font-size: 14px;
    align-self: flex-end;
    margin: -30px 3px 0 0;
  }

  .isBtnClear {
    background-color: #fff;
    border: none;
    align-self: flex-end;
    font-size: 12px;
    margin: -21px 22px 0 0;
  }
}
