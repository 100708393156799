.donuts-chart {
  display: flex;
  width: 100%;
  overflow: hidden;

  .col-esq {
    padding: 20px;
    margin-top: -15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .legends {
      li {
        display: flex;
        margin: 5px 0;
        span {
          margin-left: 8px;
        }
      }
    }
  }

  .col-dir {
    // margin-left: 120px;
    // margin-top: 100px;
    max-width: 410px;
    max-height: 410px;
  }

  .legendBlue {
    width: 15px;
    height: 15px;
    background: #425ba6;
    border-radius: 5px;
    transform: translateY(3px);
  }

  .legendRed {
    width: 15px;
    height: 15px;
    background: #ff6666;
    border-radius: 5px;
    transform: translateY(3px);
    margin-right: 15px;
  }

  .legendGreen {
    width: 15px;
    height: 15px;
    background: #4baf5a;
    border-radius: 5px;
    transform: translateY(3px);
  }

  .legendGray {
    width: 15px;
    height: 15px;
    background: #98a1b1;
    border-radius: 5px;
    transform: translateY(3px);
  }

  .areaChart {
    width: 100%;
  }

  .chart {
    width: 250px;
    height: 250px;
    margin: 80px;
    display: flex;
    transform: translateY(-45px);

    .area {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      // background: conic-gradient(
      //   #425ba6 50deg,
      //   #98a1b1 50deg 190deg,
      //   #4baf5a 190deg 360deg
      // );
      border-radius: 50%;
      position: relative;

      .dentro {
        width: 150px;
        height: 150px;
        background-color: #ffff;
        border-radius: 50%;
        z-index: 1;
      }

      .valores {
        position: absolute;
        left: 50%;
        bottom: 50%;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        height: 170px;
        width: 40px;
        // background-color: yellow;
        transform-origin: bottom center;
        transform: translateX(-20px);

        .linha {
          display: flex;
          width: 2px;
          height: 140px;
        }
      }
    }
  }
}
