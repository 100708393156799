.body {
  padding: 20px 59px!important;
}

.bodyLoading {
  padding: 0px!important;
}



