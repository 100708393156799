.change-record {
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;

  .header-change-record {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #6078bf;
    padding: 20px 20px 0 20px;
  }

  .content-change-record {
    background: #f1f2f4;
    margin: 0 10px;
    max-height: 370px;
    overflow-y: scroll;

    .no-changes {
      margin: 20px 10px;
    }

    .change-record-main {
      margin: 10px;

      &:not(:last-child) {
        border-bottom: 1px dashed #000000;
      }

      .title-change-record {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }

      .edition {
        color: #4364d9;
      }
      .deletion {
        color: #e54545;
      }

      .div-module-stage {
        margin-top: 15px;
      }

      .div-module-stage p,
      .description-change {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #30343e;
      }

      .description-change {
        font-weight: 500;
        margin-top: 20px;
      }

      .date-change {
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        color: #30343e;
        margin: 20px 0;
      }
    }
  }

  .div-button-change-record {
    text-align: center;
    padding: 10px 0;

    button {
      width: 82px;
      height: 32px;
      border-radius: 4px;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      margin: 0 10px;
      border: none;
      background: #2b408b;
    }
  }
}
