.hold-chart {
  width: 1040px;
  margin: auto;
  position: relative;
  top: -10px;
}

.hold-chart-v2 {
  width: 1020px;
  left: -15px;
  position: relative;
}

canvas {
  width: 100% !important;
}

select {
  border: solid 1px orange;
  border-radius: 5px;
  padding: 8px 40px;
  box-shadow: 2px 2px 6px #00000029;
  text-transform: initial;
}

.showError {
  width: 500px;
  height: 250px;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  margin-top: -125px;
  margin-left: -250px;
}

.showError p {
  font-size: 18px;
  margin: 0px 0px 22px 0px;
}

.container-briefing {
  width: 1040px;
  margin: auto;
}

.hold-select-left {
  width: 18%;
  float: left;
}

.hold-total {
  position: relative;
  width: 20%;
  float: right;  
  font-size: 20px;
  color: #53586e;
  padding-top: 10px;
  text-align: right;  
}

.hold-total span {  
    font-weight: 600; 
    color: #53586e; 
}

.indicators-list {  
  width: 60%;
  float: left;
  padding-left: 40px; 
  position: relative;
  top: -9px; 
}

.indicators-list li {  
    display: inline-flex;
    margin: 8px 10px;  
}

.indicators-list a {  
  background: #FFF;
  padding: 10px 40px;
  border-radius: 6px;
  text-decoration: none;
  border: solid 1px #f39332; 
}

.indicators-list a:hover {  
  background: #f39332;
  color: #FFF;
}

.indicators-active {
  background-color: #f39332 !important;
  color: #FFF;
  padding: 10px 25px;
  text-decoration: none;
}

.indicators-active:hover {
  opacity: 0.8;
}

.indicators-active:focus {
  background-color: #f39332 !important;
  color: #FFF;
  text-decoration: none;
}

.indicators-name {
  color: #f39332;
  clear: both;
  font-size: 16px;
  text-align: right;
  position: relative;
  top: -5px;
}

.indicators-sla {
  color: #f39332;
  clear: both;
  font-size: 16px;
  padding-right: 43px;
  position: relative;
  top: -3px;
  left: -16px;
  margin-bottom: 15px;
}

.indicators-sla small:first-child {
  background-color: #444A62;
  color: #FFF;
  margin-left: 17px;
}

.indicators-sla small:nth-child(1)::before {
  content: '';
}

.indicators-sla small:nth-child(2)::before {
  content: '';
}

.indicators-sla small {
  text-align: left;
  color: #666;
  font-size: 14px;
  background-color: #eee;
  padding: 7px 13px;
}

.indicators-sla small::before {
  content: '|';
  position: relative;
  left: -17px;
  color: #ccc;
}

.indicators-sla small span {
  float: none;
  color: #666;
  padding-left: 5px;
  font-weight: 600;
}

.indicators-sla span {
  float: right;
}

#hold-briefings {
  padding: 30px;
  position: relative;
}

@media screen and (max-width:990px){
  .showError {
    position: relative;
    width: auto;
    height: auto;
    top: auto;
    left: auto;
    margin-top: 50px;
    margin-left: auto;
    padding: 20px;
  }

  .hold-total, .hold-select-left {
    float: none;
    width: 100%;
    text-align: left;
  }

  .indicators-list {  
    width: 100%;
    float: none;
    padding-left: 0px; 
    top: auto; 
    margin-top: 10px; 
  }

  .indicators-list li {  
    width: 100%; 
    margin: 0;
  }

  .indicators-list li a {  
    width: 100%; 
    margin-bottom: 10px;
  }

  .hold-chart, .hold-chart-v2 {
    width: 90%;
  }  

  .container-briefing {
    width: 100%;
  }

  .showError img {
    max-width: 80%;
  }
}
