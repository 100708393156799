@import '../../../../../Assets/colors.scss';
@import '../../../../../Assets/mixins.scss';
@import '../../../../../Assets/modals.scss';

.modal-redirects-in-viability {
  font-family: 'Poppins';
  font-style: normal;

  .header-modal-redirects-in-viability {
    padding: 20px;
    display: flex;

    .text-modal {
      padding-right: 20px;
  
      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #E54545
      }
    
      .subtitle {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #6078BF;
        margin-top: 20px;
      }
    }
  }

  .div-supplier-plattaform {
    display: flex;
    justify-content: center;
    padding: 20px 0;

    button {
      border-radius: 4px;
      height: 32px;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #FFFFFF;
      margin: 0 5px;
      background: #2B408B;
    }

    .btn-redirect-plattaform {
      width: 258px;
    }

    .btn-close-mrv {
      padding: 0 12px;
    }
  }
}
