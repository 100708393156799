.mainDiv{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 5px 15px 15px #0000001A;
  border-radius: 5px;
  position: relative;
}

.notes {
  margin: 30px 10px;
  padding-left: 30px;
}

.notesListCard{
  padding-left: 30px;
}

.loadingNotesCard {
  padding: 0px;
  margin: 0;
}
.titleDate {
  font-size: 21px;
  color: #1A213F;
  letter-spacing: 0px;
  font-weight: 400;
}

.titleDate span{
  color: #718CFF!important;
}

.tableDemands {
  text-align: center;
  padding: 30px;
  vertical-align: middle!important;
  color: #1A213F;
  
  border-bottom: 1px solid #E0E3DA!important;
}

.bold {
  font-weight: bold;
}

.closeButton {
  /* position: relative;
  left: 46%; */
  display: flex;
  justify-content: center;
  align-items: center;
  font: 900 17px Poppins;
  letter-spacing: 1.22px;
  text-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  background: none;
  border: 0;
  cursor: pointer;
  color: #718CFF;
  transition: 0.2s;
  text-decoration: none;
}

.closeButton:hover {
  transform: translateY(-5px);
}

.closeButton .icon {
  display: block;
  margin-left: 5px;
}




