.mainDiv{
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #2C28281C;
  margin: 0 30px;
  position: relative;
}

.tableBriefing{
  margin: 0;
}
.tabs {
  position: relative;
  margin: 16px 30px;
}
/* .tabsLinks {
  display: flex;
  align-items: center;
} */
a {
  text-decoration:none!important;
}

.notSelect{
  display: flex;
  justify-content:center;
  align-items: center;
  
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.4px;
  padding: 10px 9px;
  
}
.notSelect a{
  color: #fff!important;
}
.notSelect a:hover{
  color: #1A213F!important;
}
.select{
  display: flex;
  justify-content:center;
  align-items: center;

  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 6px #00000061;
  border-radius: 7px;
  color: #1A213F!important;

  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.4px;
  padding: 10px 9px;
  
}
.select a{
  color: #1A213F!important;
}
.select a:hover{
  color: #1A213F!important;
}
/* .tabsFilter {
  display: flex;
  align-items: center;
  justify-content:space-between;
} */
.header{
  background-image: linear-gradient(#F29435, #E3B280);
  font-size: 14px;
  text-align: center;
  color: #fff;
  padding: 20px 59px!important;
  vertical-align: middle!important;
  border-radius: 8px 8px 0 0;
  margin: 0!important;
}

.body {
  padding: 20px 59px!important;
}

.btnProject{
  text-align: center;
  margin: auto;
  background: transparent linear-gradient( 90deg, #F39332 0%, #985A1B 100%) 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 5px #00000036;
  border-radius: 7px;
  text-shadow: 0px 3px 6px #00000029;
  color: #fff;
  font-size: 16px;
  padding: 8px 20px;
  display: block;
  width: 250px;
  font-weight: 500;
}

.btnDisabledSend{
  margin: auto;
  text-align: center;
  display: block;
  width: 126px;
  padding: 8px 20px;
  background: transparent linear-gradient(90deg, #E5E5E5 0%, #FAF7FE 100%) 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 5px #00000036;
  border-radius: 7px;
  font-size: 16px;
  color: #A6A6A6;
  font-weight: 500;
}

.borderLeftRadius{
  border-radius: 9px 1px 0px 0px;
}

.borderRightRadius{
  border-radius: 0px 9px 0px 0px;
}

.alignCenter{
  display: flex;
  align-items: center;
}
.bodyLoading {
  padding: 0px!important;
}

.TitleName {
  color: #F39332;
  font-size: 25px;
  text-align: left;
}

.img {
  margin-right: 15px;
}


.rowTitle {
  margin-bottom: 20px;
}
.colTitle {
  display: flex;
  align-items: center;
}


