@import '../../../../../Assets/colors.scss';
@import '../../../../../Assets/mixins.scss';
@import '../../../../../Assets/modals.scss';

.modal-start-ean-dun {
  font-family: 'Poppins';
  font-style: normal;

  .header-modal-ean-dun {
    padding: 20px;
    display: flex;

    .text-modal {
      padding-right: 20px;
  
      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #E54545
      }
    
      .subtitle {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #6078BF;
        margin-top: 20px;
      }
    }
  }

  .div-sku-plattaform {
    display: flex;
    justify-content: center;
    padding: 20px 0;

    button {
      background: #2B408B;
      border-radius: 4px;
      width: 185px;
      height: 32px;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #FFFFFF;
    }
  }
}
