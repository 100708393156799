.SelectPeriodLaunch {
  display: flex;
  flex-direction: column;
  font-family: 'Poppins';

  .splInput {
    display: flex;
    width: 100%;
    font-size: 12px;
    height: 38px;
    border: 1px solid #888;
    border-radius: 8px;
    padding: 0 8px;
  }

  .splBtnIcon {
    background-color: #fff;
    border: none;
    font-size: 10px;
    align-self: flex-end;
    margin: -26px 4px 0 0;
  }

  .splBtnClear {
    background-color: #fff;
    border: none;
    align-self: flex-end;
    font-size: 12px;
    margin: -19px 18px 0 0;
  }

  .splWindowOptions {
    flex-direction: column;
    position: absolute;
    z-index: 99;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 38px;
    box-shadow: 0 1px 4px 1px #d4d3d3;
    width: 330px;
    transform: translateX(-185px);
    padding: 20px;
  }

  .splRadios {
    display: flex;
    justify-content: space-between;

    label {
      display: flex;

      input {
        margin-right: 6px;
        height: 16px;
      }
    }
  }

  .splSelects {
    display: flex;
    flex-direction: column;
    margin: 20px 0 30px 0;

    .splLine {
      display: flex;
      flex-direction: row;

      select {
        border: 1px solid #999;
        background-color: #f9f9f9;
        border-radius: 5px;
        padding: 8px 20px;
        margin: 0 15px 0 0;
        box-shadow: none;
      }
    }
  }

  .splButtons {
    display: flex;
    justify-content: flex-end;

    button {
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      padding: 8px 10px;
      border: none;
      border-radius: 5px;
    }

    .cancel {
      background-color: #e54545;
      margin-right: 15px;
    }

    .save {
      background-color: #2b408b;
    }
  }
}
