@import '../../Assets/colors.scss';

.calendarWrapper {
  position: absolute;
  z-index: 1;

  .rdrMonth {
    padding-bottom: 0;
  }
}

.inputInlineCalendar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: #000;
  background: #fff;
  border-radius: 6px;
  padding: 7px 11px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  height: 38px;
  cursor: pointer;
  text-overflow: ellipsis;
}

.inputInlineCalendarLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 16px 0 0;
}
