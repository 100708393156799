.containerCard{
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.22);
  padding: 20px;
  margin: 10px;
  width: 400px;
  height: 300px;
  transition: all 0.3s ease-out;
  background-color: #f5f5f5
}

.card .cardTitle {
  text-align: center;
  letter-spacing: 1.2px;
  color: #F39332;
  text-transform: uppercase;
  opacity: 1;
  text-shadow: 2px 1px 2px #0000002E;
  font-size: 22px;
}
.card p {
  text-align: center;
  letter-spacing: 1.2px;
  color: #172B4D;
  opacity: 1;
  text-shadow: 2px 1px 2px #0000002E;
  font-size: 20px;
}

.card:hover {
  transform: translateY(-5px);
  cursor: pointer;
}



.logoDigital span{
  letter-spacing: 0px;
  color: #000;
  text-shadow: 2px 1px 2px #0000002E;
  font-size: 27px;
  position: relative;
  top: 6px;
  left: -10px;
}

.bodyCard {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  padding-top: 10px;
}
  