@import '../../../../../Assets/colors.scss';
@import '../../../../../Assets/modals.scss';
@import '../../../../../Assets/mixins.scss';

.custom-modal {
  .icon-head-apply-step {
    fill: $blue;
  }
  .text-apply-step {
    color: $blue;
    margin: 7px 0px 20px 0px;
    font: {
      weight: 500;
      size: 16px;
    }
    @extend .placeholder-fonts;

    &:after {
      content: '';
      width: 40px;
      height: 6px;
      position: relative;
      display: block;
      top: 5px;
      @include border-radius(3px);
      background-color: $blue;
    }
  }

  .sub-title-modal-green3 {
    &:before {
      @include green-gradient-3;
    }
  }

  p {
    @extend .placeholder-fonts;

    span {
      font-weight: bold;
    }
  }

  .modal-body {
    padding: 0px 25px;
  }
  .modal-header {
    border: 0;
    display: block;

    svg {
      margin-top: 3px;
    }
  }
}
