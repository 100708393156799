.NewProject {
  .formProject {
    background-color: #e1e4e8;
    width: 100%;
    height: 100%;
    font-family: 'Poppins';
    font-style: normal;
    color: #000000;
    padding-bottom: 50px;
  }

  .rowProject,
  .rowForm {
    padding: 0 60px 0 60px;
  }

  .titleProject {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    padding: 30px 60px;
  }

  .subtitleProject {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .divOperation,
  .divTypeProject {
    border: 1px solid #f5f5f5;
    display: flex;
    justify-content: space-between;
    padding: 12px;
    margin-top: 10px;
  }

  .divOperation {
    max-width: 80%;
    justify-content: space-around;
  }

  .nameOpType {
    text-align: center;
  }

  .divTypeProject {
    max-width: 90%;
  }

  .boxRetail,
  .boxComex {
    background: #f39332;
    border-radius: 5px;
    width: 84px;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;

    path {
      fill: #303440;
    }

    &.selected {
      background-color: #e57f17;

      path {
        fill: #ffffff;
      }
    }
  }

  .divOp {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 84px;
    input {
      display: none;
    }
  }

  .divTypeProject {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 12px;
  }

  .boxProject {
    background: #ffad0d;
    border-radius: 4px;
    padding: 8px 16px;
    font-weight: 600;

    svg {
      margin-right: 8px;
    }

    &.selected {
      background-color: #f39332; /* #E59902 */
      color: #ffffff;
      path {
        fill: #ffffff !important;
      }
    }

    &:disabled {
      background-color: #e59902;
      color: #ccc;
      border: 0 !important;
    }

    img {
      margin-right: 5px;
    }
  }

  .rowForm {
    margin-top: 90px;
  }

  .divForm {
    margin-top: 30px;
    max-width: 80%;
  }

  .inputNameProj {
    height: 38px;
    border: none;
    border-radius: 8px;
    padding: 0 8px;
    min-width: 100%;
    max-width: 100%;
  }

  .btnInputCategory {
    margin: 0;
    height: 38px;
    width: 100%;
    border: none;
    border-radius: 8px;
    background-color: #fff;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .divDerivProj {
    margin-top: 50px;
  }

  /*   .lightbluePicker {
    z-index: 100000 !important;
    background-color: red !important;
  } */

  .div-buttons {
    display: flex;
    justify-content: flex-end;
    margin: 50px 100px 0 0;
  }

  .btn-danger,
  .btn-success {
    padding: 8px 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    border-radius: 4px;
  }

  .formError {
    margin: 5px 0 0 5px;
    font-size: 12px;
    color: #cc2929;
  }
}

.rs-picker-menu {
  z-index: 1100 !important;
}
