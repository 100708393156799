.scetionBtnBack {
  margin-top: 10px;
}

.btnBack {
  color: #fff;
  font-size: 18px;
}

.btnBack:hover {
  color: #fff;
}

.btnBack svg {
  margin-left: 4px;
} 