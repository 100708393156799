@import '../../Assets/colors.scss';

.fakeStatusButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 34px;
  box-shadow: 1px 1px 3px #00000030;
  border-radius: 5px;
  padding: 5px 10px;
  font-family: 'Montserrat', 'Ubuntu';
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out transform 0.15s ease-in-out;

  &.no-border-radius {
    border-radius: 0px !important;
  }

  &.btn-xsmall {
    min-width: 65px;
    font-size: 11px;
  }

  &.btn-small {
    min-height: 17px;
    min-width: 86px;
    font-size: 13px;
  }
  &.btn-default {
    min-width: 100px;
    font-size: 14px;
  }

  &.btn-medium {
    min-width: 138px;
    font-size: 16px;
  }

  &.btn-large {
    min-width: 148px;
    font-size: 18px;
  }

  &.btn-blue {
    background-color: $blue;

    &:hover,
    &.btn-active {
      filter: brightness(110%) saturate(110%) !important;
    }
  }

  &.btn-blue2 {
    background-color: $blue6;

    &:hover,
    &.btn-active {
      filter: brightness(110%) saturate(110%) !important;
    }
  }

  &.btn-pink {
    background-color: $red5;

    &:hover,
    &.btn-active {
      filter: brightness(110%) saturate(110%) !important;
    }
  }

  &.btn-orange {
    background-color: $orange;

    &:hover,
    &.btn-active {
      filter: brightness(110%) saturate(110%) !important;
    }
  }

  &.btn-green {
    background-color: $green;

    &:hover,
    &.btn-active {
      filter: brightness(110%) saturate(110%) !important;
    }
  }

  &.btn-black {
    //background: transparent linear-gradient(180deg, #1b213f 0%, #4e4e4e 100%) 0%
    //   0% no-repeat padding-box;

    background-color: #30343e;

    &:hover,
    &.btn-active {
      filter: brightness(110%) saturate(110%) !important;
    }
  }

  &.btn-grey {
    // background: transparent linear-gradient(180deg, #d9d7d7 0%, #757474 100%) 0%
    //0% no-repeat padding-box;
    background-color: #98a1b1;

    &:hover,
    &.btn-active {
      filter: brightness(110%) saturate(110%) !important;
    }
  }

  &.btn-red, &.btn-green, &.btn-yellow, &.btn-statusdefault {
    background-color: transparent;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    border-radius: 4px;
  }

  &.btn-red {
    color: #E64545;
    border: 1px solid #E64545;
  }

  &.btn-green, &.btn-statusdefault {
    color: #FFFFFF;
    background: #4CB05B;
  }

  &.btn-yellow {
    color: #F39332;
    border: 1px solid #F39332;
  }
}
