.modalCategory {
  display: flex;
  max-width: 700px !important;

  .selectCategoryEsq {
    width: 60%;
    padding: 0 20px 0 5px;

    .titleCategory {
      font-size: 14px;
      font-weight: 600;
    }

    .subtitleCategoryEsq {
      font-size: 12px;
      font-weight: 400;
    }

    .categoryColumn {
      display: flex;
      flex-direction: column;
      padding-top: 10px;
  
      .categorySelectGroup {
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
      }
  
      .legendField {
        font-size: 10px;
        color: #070707;
        font-weight: bold;
        margin: 0;
      }
    }
  
    .categoryButtons {
      max-width: 100%;
      margin: 50px 0 0 0;
      display: flex;
      gap: 20px;
      justify-content: center;
    }
  }

  .selectCategoryDir {
    width: 40%;
    padding-left: 20px;
    background: #F5F5F5;
    
    .subtitleCategoryDir {
      font-weight: 500;
      font-size: 14px;
      margin-top: 8px;
    }

    .listProducts {
      margin-top: 20px;
      overflow-y: scroll;
      height: 280px;

      li {
        font-weight: 400;
        font-size: 14px;
        font-family: 'Poppins';
        margin-top: 5px;
        line-height: 24px;
      }
    }
  }
}
