.modal-cd-destiny {
  min-width: 664px;
  height: 434px;
  //background: url("/static/media/bg-cd-states.8c43212c.png") no-repeat !important;
  background-size: cover !important;
  margin: auto;
  margin-top: 220px;
  margin-right: 120px;
  border: 0;

  .specialPaddingModal {
    padding: 20px 80px 30px 80px;

    .notesProviders {
      margin: 20px 0;
    }

    .listaCds {
      display: flex;
      flex-wrap: wrap;
      padding: 65px 0px 0px 16px;
      width: 90%;
      margin: auto;
      position: relative;

      li {
       margin: 4px 9px;
       border: 2px solid #f5af69;
       border-radius: 5px;
       padding-top: 1px;
       padding-bottom: 1px;
       font-weight: bold;
       cursor: pointer;
       width: 35px;
       text-align: center;
      }

     .listaCdAtivo {
       background-color: #313752;
       color: #ffffff;
       border: solid 2px #313752 !important;
      }
    }
  }

  .buttonModalCdDestiny {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #2c28281c;
    border: 1px solid #f39332;
    border-radius: 5px;
    padding: 5px;
    width: 120px;
    font-size: 14px;
    color: #f39332;
  }

  .buttonWhite {
    background: #ffffff 0% 0% no-repeat padding-box;
    background: linear-gradient(0deg, rgba(237, 237, 238, 1) 0%, rgba(212, 209, 209, 1) 100%);
    box-shadow: 0px 3px 6px #2c28281c !important;
    border-radius: 5px;
    padding: 5px;
    width: 120px;
    font-size: 14px;
    font-weight: 600;
    color: #202644 !important;
  }
}