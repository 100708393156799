.content.modalBody {
  position: relative;
  padding: 44px 44px 20px 44px;
}

.btnCloseCustom {
  background: none;
  border: 0;
  padding: 0;
  position: absolute;
  top: 21px;
  right: 21px;
  width: 27px;
  height: 27px;
  color: #000;
}

.btnCloseCustom > svg {
  width: inherit;
  height: inherit;
}

.guideCol {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 0 18px 0;
}

.guideCol p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #596780;
  margin: 0;
}

.guideCol strong {
  display: inline-block;
  font-size: 22px;
  color: #1d4ed8;
  font-weight: 600;
  margin: 0 0 8px 0;
}

.orange {
  color: #ec8c32;
}

.highlight {
  font-weight: 500;
  color: #ecb22e;
}

.midHead {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.03em;
  color: #425BA6;
  margin: 16px 0;
}

.compGuideSquare {
  width: 50px;
  height: 50px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  margin: 0 20px 0 0;
  background: #CCD9FF;
}

.compGuideSquare svg {
  width: 30px;
  height: 30px;
}

.compGuideSquare svg > path {
  width: 30px;
  fill: #fff;
}

.list {
  padding: 0 0 0 85px;
}

.item {
  margin: 12px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.item p {
  margin: 0 0 0 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #425ba6;
}

.icoBox {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  border-radius: 50%;
}

.icon {
  width: 22px !important;
  height: 22px !important;
  color: #666;
}

.green {
  color: #47CC7C !important;
}