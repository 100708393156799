.content.modalBody {
  position: relative;
  padding: 28px 32px;
}

.head {
  border-bottom: 1px solid #F0F0F0;
}

.btnCloseCustom {
  background: none;
  border: 0;
  padding: 0;
  position: absolute;
  top: 21px;
  right: 21px;
  width: 27px;
  height: 27px;
  color: #000;
}

.btnCloseCustom > svg {
  width: inherit;
  height: inherit;
}

.guideCol {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 0 18px 0;
}

.text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #494E59;
  margin: 0 0 18px 0;
}

.title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #6078BF;
  margin: 0 0 9px 0;
}

.warning {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #fff7d9;
  border-radius: 4px;
  padding: 10px 12px;
  width: 100%;
  margin: 15px 0;
}

.warningText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #957400;
  margin: 0;
}

.warningIcon {
  flex: 0 0 auto;
  color: #957400;
  height: 18px;
  width: auto;
  margin-right: 10px;
}

.label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #191D23;
  margin: 9px 0;
}

.bodyCard{
  border: 1px solid #D0D5DD;
  border-radius: 4px;
  resize:none;
  outline:none;
  min-height: 100px;
  overflow:none!important;
  width: 100%;
  padding: 12px 8px 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #64748B;
}

.calendarBox {
  position: relative;
  width: 160px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: -15px;
}

.borderCalendar {
  border: 1px solid #EC8C32 !important;
  border-radius: 4px !important;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  color: #494E59 !important;
}

.textBtn {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  border-radius: 4px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF;
  border: 0!important;
}

.btn.cancel {
  background: #DF5753;
  margin: 0 8px 0 0;
}

.btn.action {
  background: #425BA6;
}

.btn:disabled {
  background: #d9d9d9;
  color: #FFFFFF;
}

.orange {
  color: #ec8c32;
}

.blue {
  color: #6078BF;
}