.modal-cd-destiny-sku {
  min-width: 530px;
  height: 380px;
  background-size: cover !important;
   
  .specialPaddingModal {
    padding: 20px 80px 30px 80px;
     
    .paragrafo-modal-body {
     font-family: "Poppins";
     font-style: normal;
     font-weight: 400;
     font-size: 16px;
     line-height: 24px;
    }

    .hold-all-sku {
    margin: 20px 0px;
    max-width: 100%;
    text-align: center;

     button {
      display: initial;
      margin-right: 10px;
      text-align: center !important;
     }

     .button-cancelar {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #2c28281c;
      border: 1px solid #f39332;
      border-radius: 5px;
      padding: 5px;
      width: 120px;
      font-size: 14px;
      color: #f39332;
     }

     .button-confirmar {
      background: #ffffff 0% 0% no-repeat padding-box;
      background: linear-gradient(0deg, rgba(237, 237, 238, 1) 0%, rgba(212, 209, 209, 1) 100%);
      box-shadow: 0px 3px 6px #2c28281c !important;
      border-radius: 5px;
      padding: 5px;
      width: 120px;
      font-size: 14px;
      font-weight: 600;
      color: #202644 !important;
     }
    }
  }

  .buttonModalCopyCdDestiny {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #2c28281c;
    border: 1px solid #f39332;
    border-radius: 5px;
    padding: 5px;
    width: 120px;
    font-size: 14px;
    color: #f39332;
  }

  .buttonWhite {
    background: #ffffff 0% 0% no-repeat padding-box;
    background: linear-gradient(0deg, rgba(237, 237, 238, 1) 0%, rgba(212, 209, 209, 1) 100%);
    box-shadow: 0px 3px 6px #2c28281c !important;
    border-radius: 5px;
    padding: 5px;
    width: 120px;
    font-size: 14px;
    font-weight: 600;
    color: #202644 !important;
  }
}