@import '../../../../../Assets/colors.scss';
@import '../../../../../Assets/mixins.scss';
@import '../../../../../Assets/modals.scss';

.modal-edit-briefing {
  font-family: 'Poppins';
  font-style: normal;

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  .divForm {
    margin-bottom: 30px;
  }

  .inputNamePr {
    display: flex;
    width: 100%;
    font-size: 12px;
    height: 38px;
    border: 1px solid #888;
    border-radius: 8px;
    padding: 0 8px;
  }

  .divButtons {
    display: flex;
    gap: 10px;

    .btnSave, .btnCancel {
      border-radius: 4px;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #FFFFFF;
      padding: 8px 14px;
    }

    .btnCancel {
      background: #E64545;
    }

    .btnSave {
      background: #4CB05B;
    }
  }
}
