@import '../../Assets/colors.scss';

.hold-textarea {
  input,
  select,
  textarea {
    padding: 12px 12px 200px 12px !important;
  }
}

.inputTextComponent {
  margin: 18px 20px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input,
  select,
  textarea {
    border-width: 2px;
    border-color: $primary3;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  select {
    // background: url('../../assets/images/i-arrow-select.svg');
    background-repeat: no-repeat;
    background-position: right;
  }

  input[type='file'] {
    width: 100%;
    padding-left: 25px;
  }

  &.with-label {
    label {
      // position: absolute;
      transform: translate(5px, 12px);
      background-color: white;
      font-size: 14px;
      padding: 0 5px 5px 5px;

      &.primary1 {
        color: $primary1;
      }
      &.primary2 {
        color: $primary2;
      }
    }
  }

  &.no-margin {
    margin: 0 !important;
  }

  &.no-border {
    input,
    select,
    textarea {
      border-top-width: 0;
      border-left-width: 0;
      border-right-width: 0;
      border-radius: 0;
      border-color: $gray4;
      background-color: $gray6;
    }
    label {
      padding-bottom: 0;
    }
  }

  &.with-icon {
    input,
    select,
    textarea {
      padding-left: 32px;
    }
    .input-icon {
      display: block;
      position: absolute;
      margin: 10px 0 0 10px;
      color: $gray3;
    }
  }

  &.invalid {
    input,
    select,
    textarea {
      border-color: $red3;
      padding-right: 32px;
      background: $red2;
    }

    span {
      display: block;
      font-size: 14px;
      color: $red;
      transform: translateX(5px);
      margin-bottom: -21px;
    }
  }
}
