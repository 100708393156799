.menuImg {
  position: absolute;
  left: 50px;
  top: 35px;
  z-index: 3;

  &.fixedIcon {
    position: fixed;
  }

  .svgMenu {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  svg {
    cursor: pointer;
  }
}

.sidebar {
  background-color: #ffffff;
  width: 100%;
  /* height: 90vh; */
  position: relative;

  .closeMenu {
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 17px;
    cursor: pointer;
  }

  .logoDiv {
    a:hover {
      text-decoration: none;
    }
  }

  .logo {
    text-align: center;
    padding: 30px 0 0 0;
  }

  .logoSpan {
    color: #000000;
    font-size: 25px;
    font-weight: bold;
    margin-left: 5px;
    text-shadow: 0px 1px 2px #00000042;
    position: relative;
    top: 5px;
    left: -20px;
  }

  .titleMenu {
    a {
      color: #1a213f;
      font-size: 17px;
      font-weight: 500;
      margin-top: 9px;
      text-align: center;
      text-shadow: 1px 1px 4px #00000029;
      letter-spacing: 0px;
      display: block;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
  }

  .titleMenu {
    a:hover {
      color: #1a213f;
    }
  }

  .btnSidebarPopover {
    background-color: transparent;
    font-size: 14px;
    color: #f39332;
    text-align: start;
    padding: 0 0 0 5px;

    img {
      margin-right: 10px;
    }
  }

  .iconLinkSmall {
    display: inline-block;
  }
} /* fim sidebar */

.textNewProduct {
  color: #707070;
  font-size: 18px;
}

.textNewProduct {
  span {
    font-weight: 700;
  }
}

a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.menuHome svg {
  margin-right: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.menuHome svg:hover {
  color: #e2e5e5 !important;
}

.logoBold {
  color: #f39332;
  font-size: 25px;
  font-weight: bold;
  text-shadow: 0px 1px 2px #00000042;
}

.ulMenuSidebar {
  margin: 40px 0;
}

.ulMenuSidebar li {
  // display: block;
  // padding: 15px 30px;
  // -webkit-transition: all 0.3s ease-in-out;
  // transition: all 0.3s ease-in-out;
}

.ulMenuSidebar li:hover {
  // background-color: whitesmoke;
}

.ulMenuSidebar li .linkMenu {
  color: #338ce1;
  font-size: 14px;
  display: block;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.linkAccordion {
  padding: 0px 0px 0px 30px;
  font-size: 13px;
  font-weight: 500;
  color: #338ce1;
  top: 10px;
  position: relative;
}

.linkInsideMenu {
  display: block;
  font-size: 13px;
  color: #2a73ba;
  margin: 7px 0;
  font-weight: 500;
}

.btnInsideMenu {
  // composes: linkInsideMenu;
  @extend .linkInsideMenu;
  background-color: transparent;
  padding: 0;
}

.ulMenuSidebar li .linkMenuDisabled {
  color: #338ce1;
  font-size: 13px;
  display: block;
  margin: 30px 0 0 0;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.linkMenuSuport {
  color: #f39332;
  font-size: 13px;
  display: block;
  cursor: pointer;
  padding: 0 30px;
}

.linkMenuSuport:hover {
  color: #f39332;
}

.linkMenuSuport img {
  position: relative;
  margin-right: 10px;
  min-width: 20px;
  top: -2px;
}

.linkMenu {
  color: #338ce1;
  font-size: 13px;
  display: block;
}

.linkMenu:hover {
  color: #338ce1;
}

.linkMenu img {
  position: relative;
  margin-right: 10px;
  min-width: 20px;
}

.linkMenuActive {
  background-image: linear-gradient(#1a213f, #666a7e);
  padding: 20px 34px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 5px;
  display: block;
  box-shadow: 2px 2px 6px #0000004f;
}

.linkMenuActive span {
  position: relative;

  left: 8px;
}

.linkMenu span {
  position: relative;

  left: 4px;
}

.linkMenuActive:hover {
  color: #fff;
  text-decoration: none;
}

.linkMenuActive:focus {
  color: #fff;
  text-decoration: none;
}

.supportLink {
  bottom: 10px;
}

.textSupport {
  font-size: 17px;
  font-weight: 300;
  line-height: 30px;
  padding: 0px;
}

.orange {
  color: #f39332;
}

.fieldsetModal {
  margin: 20px 50px 25px 0;
}

.lineInputs {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
}

.lightblueInput,
.lightblueInput:focus,
.lightblueInput:active {
  background-color: #f2f4fe !important;
  border: none;
  box-shadow: 0px 0px 5px 1px #d3d3d3 !important;
  margin-right: 10px;
}

.lightbluePicker {
  border: none !important;
  box-shadow: 0px 0px 5px 1px #d3d3d3 !important;
}

.lightbluePicker > a {
  background-color: #f2f4fe !important;
}

.footerModal {
  justify-content: center !important;
}

.btnModal {
  padding: 8px 15px;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 1px #d3d3d3;
}

.btnRed {
  @extend .btnModal;
  color: #fff;
  background-color: #ff6363;
}

.btnGreen {
  @extend .btnModal;
  color: #fff;
  background-color: #31f078;
}

.btnGreen:disabled,
.btnGreen:disabled:hover {
  cursor: default;
  border: 0;
  color: #fff;
  background-color: #31f078;
  opacity: 0.4;
}

.btnGray {
  @extend .btnModal;
  margin: 10px auto;
  width: 190px;
}

.areaBtnsGray {
  display: flex;
  flex-direction: column;
}

.sectionButtonsMenu {
  display: flex;
  flex-direction: column;
  padding: 15px 30px;

  .buttonCloseDiv {
    padding: 30px 0;

    .buttonCloseText {
      display: flex;
      align-items: center;

      button {
        background: transparent;

        img {
          margin-right: 5px;
        }
      }
    }
  }
}