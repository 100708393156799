.mainDiv2 {
  margin: 0 30px !important;
  padding: 10px 0 !important;
  position: relative !important;
  margin-top: -12px !important;
}

.mainDiv1 {
  margin: 0 30px !important;
  margin-bottom: 100px;
}

/* .mainDiv3 {
  margin: 0 30px !important;
} */

.mainDiv3 {
  /* margin: 0 30px !important; */
  margin: 0 0px !important;
  padding: 0;
  width: 100%;
}

/* .title {
  font-size: 20px;
} */

.client {
  font-size: 15px;
  font-weight: bold;
  position: relative;
  line-height: 20px;
  color: #fff;
}

.clientName {
  font-size: 15px;
  font-weight: 300;
  margin-left: 2px;
}

.project {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  line-height: 20px;
  color: #fff;
}

.projectName {
  font-size: 16px;
  font-weight: 300;
  margin-left: 2px;
}

/* .footerFilterComercial {
  margin: 30px;
} */

.footerFilterComercial {
  margin: 0 0px;
  background: #fef4ea;
  width: 100%;
  position: absolute;
  right: 0;
  left: 0;
}

.mainFooterFilterComercial {
  margin: 25px 100px;
  min-height: 100px;
}

.footerFilterComercial h4 {
  font-size: 20px;
  color: #1a213f;
  margin-bottom: 10px;
}

.footerFilterComercial p {
  font-size: 15px;
}

.footerFilterComercial li {
  display: inline-flex;
}

.footerFilterComercial span {
  background-color: orange;
  color: #fff;
  padding: 10px 15px;
  margin: 3px;
  margin-right: 10px;
}
