@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');
@import 'charts-custom.css';
@import 'custom-components.css';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Ubuntu', 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
}

h1,
h2,
h3,
h4,
p {
  margin: 0px;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

img {
  display: inline-block;
  max-width: 100%;
}

button,
input {
  display: block;
  font-size: 1rem;
  font-family: var(--type-first);
  color: #333;
}

a {
  text-decoration: none;
  color: #333;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.clear {
  clear: both;
}

.active {
  text-decoration: underline;
  font-weight: 700;
}

.iconMenu {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.rotate180 {
  transform: rotateX(180deg);
  -webkit-transform: rotateX(180deg);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

main {}

.textBackTitle {
  position: relative;
  text-align: center;
  display: block;
  top: 20px;
  font-size: 200px;
  color: #fff;
  opacity: 0.4;
  letter-spacing: 40px;
  text-align: center;
}

.specialField {
  position: relative;
  margin-top: -20px !important;
}

.specialFieldthree {
  position: relative;
  margin-top: -35px !important;
}

.pdd-special {
  padding: 50px;
}

.pd-20 {
  padding: 20px;
}

.pd-left-20 {
  padding-left: 20px;
}

/* Spaces */

.m-t-1 {
  margin-top: 10px;
}

.m-t-2 {
  margin-top: 20px;
}

.m-t-3 {
  margin-top: 30px;
}

.m-t-4 {
  margin-top: 40px;
}

.m-t-7 {
  margin-top: 70px;
}

.m-r-15 {
  margin-right: 15px;
}

.p-t-3 {
  padding-top: 30px;
}

.p-t-7 {
  padding-top: 70px;
}

.p-b-7 {
  padding-bottom: 70px;
}

.red {
  background-color: red;
}

/* positions */

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.container-pdm {
  max-width: 1280px;
  margin: auto;
  padding: 0 30px;
  width: 100%;
}

/* COLORS */

.orange {
  color: #f59332 !important;
}

.gray {
  color: #c3c3c3 !important;
}

/* FONTS */

.font12 {}

.font14 {}

.font16 {}

.font18 {
  font-size: 18px;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.line-regular {
  line-height: 26px;
}

/* FORMS */

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ccc;
  outline: 0;
  box-shadow: 0 0 black;
}

/* Modal */

.modal-header .close span {
  background: red;
  color: #fff;
  border-radius: 100%;
  width: 25px;
  height: 26px;
  display: block;
  font-weight: 300;
}

.autoHeight {
  min-height: 400px;
}

.getSuppliers {
  max-width: 45%;
}

/* DropDown */

.dropdown-item {
  font-size: 14px;
  font-weight: 700;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: transparent;
}

/* BUTTONS */

button:focus {
  outline: none !important;
}

button:hover {
  opacity: 0.9;
}

.btn-delete {
  background: #f97575 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2c28281c;
  border: 1px solid #f97575;
  border-radius: 5px;
  color: #fff;
  padding: 5px;
  width: 120px;
  font-size: 14px;
}

.btn-one {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2c28281c;
  border: 1px solid #f39332;
  border-radius: 5px;
  padding: 5px;
  width: 120px;
  font-size: 14px;
  color: #f39332;
}

.btn-one-width {
  width: 170px;
}

.btn-one:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #f39332;
}

.btn-one-active {
  background: #ffffff 0% 0% no-repeat padding-box;
  background: linear-gradient(0deg,
      rgba(237, 237, 238, 1) 0%,
      rgba(212, 209, 209, 1) 100%);
  box-shadow: 0px 3px 6px #2c28281c !important;
  border-radius: 5px;
  padding: 5px;
  width: 120px;
  font-size: 14px;
  font-weight: 600;
  color: #202644 !important;
}

.btn-one-active:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #f39332;
}

.btn-sourcing {
  width: 300px;
}

.btn-upload {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2c28281c;
  border: 1px solid #6ca0f9;
  border-radius: 5px;
  padding: 5px;
  width: 120px;
  font-size: 14px;
  color: #6ca0f9;
}

.btn-submit {
  background: #f39332;
  display: block;
  width: 60px;
  color: #fff;
  height: 40px;
  border-radius: 5px;
  box-shadow: 2px 2px 6px #00000029;
}

:disabled {
  border: 1px solid #d6dae8;
  color: #d6dae8;
  background: transparent;
}

:disabled:hover {
  border: 1px solid #d6dae8;
  color: #d6dae8;
  background: transparent;
}

/* Borders */

.border-light {
  border: 0.5px solid #8898aa;
}

/* PopOvers */

.popover {
  border: none;
  font-family: 'Ubuntu';
  /* min-width: 330px; */
}

.popover .arrow {
  display: none;
}

.rs-alert-item-content {
  max-width: 900px;
}

/* Flex */

.flex-gap {
  display: flex;
  gap: 20px;
  align-items: center;
}

.flex-center-gap {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.flex-space {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}

.one-three {
  display: flex;
}

/* INPUTS */

.inputText {
  background-color: #ffffff;
  box-shadow: 2px 2px 6px #00000029;
  border: 1px solid #f39332;
  border-radius: 6px;
  font-size: 14px;
  color: #000000;
  padding: 5px 10px;
  display: block;
  font-weight: 500;
  height: 40px;
}

.inputText.input12 {
  font-size: 12px;
}

.inputGetSuppliers {
  background: #2b408b;
  padding: 10px;
  margin: 20px;
  color: #fff;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
  max-width: 242px;
}

.inputGetSuppliers:disabled {
  background: lightgray;
  color: gray;
  pointer-events: none;
}

/* .inputObs span {
  padding: 15px;
  font-size: 12px;
  position: relative;
  top: 6px;
  font-weight: 700;
} */

/* .inputObs span:hover {
  color: #fff;
} */

.status-span {
  margin-right: 5px;
}

.inputObs svg {
  margin-left: 5px;
}

.inputGetSuppliers span {
  margin-right: 10px;
}

.TagsSuppliers {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
}

.TagsSuppliers div {
  background: #f3a75b;
  padding: 10px;
  border-radius: 7px;
  font-weight: 700;
  color: #fff;
  font-size: 13px;
  margin: 5px 10px 0px 0px;
}

.inputText:focus {
  box-shadow: 2px 2px 6px #00000029;
  outline: none !important;
  border-color: #f39332;
}

.textArea {
  height: 150px !important;
  resize: none;
}

.orangeSelect {
  background-color: #ffffff;
  box-shadow: 2px 2px 6px #00000029;
  border: 1px solid #f39332;
  border-radius: 6px;
  font-size: 14px;
  color: #000000;
  padding: 8px 20px 8px 8px;
  display: block;
  font-weight: 500;
  width: 100%;
  position: relative;
  margin: auto;
  height: 40px;
  min-width: 200px;
  max-width: 320px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../src/Assets/caret.png);
  background-position: 96% center;
  background-repeat: no-repeat;
}

.orangeSelect:disabled {
  border: 1px solid #d6dae8;
  color: #d6dae8;
  pointer-events: none;
}

.orangeSelect.input12 {
  font-size: 12px;
}

.orangeSelect.mw150 {
  min-width: 150px;
}

.orangeSelect:focus {
  outline: none;
}

.orangeSelect span::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.5em solid;
  border-right: 0.5em solid transparent;
  border-bottom: 0;
  border-left: 0.5em solid transparent;
  right: 10px;
  position: absolute;
  bottom: 14px;
  color: #f39332 !important;
}

.input12>.rs-btn>.rs-picker-toggle-placeholder,
.input12>.rs-btn>.rs-picker-toggle-value {
  font-size: 12px;
}

.inputMagic {
  background-color: #a1c4ff;
  color: #fff;
}

.inputMagic svg {
  color: #fff;
}

.noShadow {
  box-shadow: none;
}

.rs-auto-complete input {
  background-color: #ffffff;
  box-shadow: 2px 2px 6px #00000029;
  border: 1px solid #f39332;
  border-radius: 6px;
  font-size: 14px;
  color: #000000;
  padding: 5px 10px;
  display: block;
  font-weight: 500;
  height: 40px;
}

.rs-input-group {
  /* border: 1px solid #f39332; */
  box-shadow: 2px 2px 6px #00000029;
  padding: 2px 4px;
}

.input-group-append {
  margin-left: -1px;
  border: 1px solid #f39332;
  border-left: none;
  border-radius: 5px;
}

.rs-picker-daterange {
  box-shadow: 2px 2px 6px #00000029;
  border: 1px solid #f39332;
  border-radius: 5px;
  color: #f39332;
  display: block;
}

.rs-picker-toggle-wrapper {
  box-shadow: 2px 2px 6px #00000029;
  border: 1px solid #f39332;
  border-radius: 5px;
  color: #f39332;
  display: block;
}

.rs-picker-menu.rs-picker-select-menu,
.rs-picker-menu.rs-picker-check-menu {
  padding-top: 6px;
  position: absolute;
  z-index: 99999;
}

.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg~.rs-picker-tag-wrapper .rs-tag {
  margin-top: 7px;

  font-size: 13px;
}

.tagPicker {
  margin-top: 10px;
  min-height: 43px;
}

.tagPicker:hover {
  border-color: #f39638 !important;
  cursor: pointer;
}

.tagPicker .rs-picker-toggle {
  cursor: pointer;
  font-size: 14px;
  color: #8e8e93;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
  border-color: transparent;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #313131;
}

.rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn,
.rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn {
  padding-right: 50px;
}

.rs-picker-toolbar-ranges {
  display: none;
}

.rs-picker-disabled {
  opacity: 1;
}

.rs-picker-default .rs-picker-toggle {
  border: none;
  height: 38px;
  font-weight: 500;
}

.blueSearch {
  display: flex;
  flex-direction: row;
}

.blueBtnPickerSearch {
  color: #fff;
  background: linear-gradient(to bottom, #253fae, #1c2858);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
}

.bluePickerSearch.rs-picker-toggle-wrapper {
  border: none;
}

.bluePickerSearch.rs-picker-toggle-wrapper>.rs-picker-toggle.rs-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bluePickerSearch>.rs-picker-toggle {
  background: linear-gradient(to bottom, #253fae, #1c2858);
}

.bluePickerSearch>.rs-picker-toggle>.rs-picker-toggle-placeholder,
.bluePickerSearch>.rs-picker-toggle>.rs-picker-toggle-value,
.bluePickerSearch>.rs-picker-toggle>.rs-picker-toggle-caret {
  color: #fff;
}

.selectPicker .rs-picker-toggle-caret {
  color: #f5a34f;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAYAAADA+m62AAAABHNCSVQICAgIfAhkiAAAAL5JREFUGFd9jzEKwlAQRGcS0SYRj2Ahit238xZ6BPuQQuzFGwQM6T2CHsNGfqniIcQEVDR/5UeUBMRtlpl5DLtMl4M1yBH+jciG10i1Hy73BBogq7gIQN5qT9Mvkmw5mBlgQdIrkyKSOeTcC3fRtyKN1YGCjtBxLEwxRoiTH+peoT8NWaKUybklUH97cqeLoRdoXQGtSGOVQDgpOMrKD3XwKapcL5FqpS6ONvRzdDnV55+gNS+xGtvdDPW6/NgL3MI+RRyq6oYAAAAASUVORK5CYII=);
  background-position: 96% center;
  background-repeat: no-repeat;
  top: 9px !important;
}

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus,
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover {
  border-color: #f39332;
  outline: 0;
}

.rs-picker-toggle-clean {
  display: none !important;
  display: inline-block;
  position: absolute;
  top: 9px !important;
  right: 30px;
  font-weight: normal;
  background: inherit;
  color: #a6a6a6;
  transition: 0.2s color linear;
  cursor: pointer;
}

.btn-clean {
  font-size: 13px;
  background: transparent;
  font-weight: 500;
  color: #f39332;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #000000;
  position: relative;
  top: 2px;
  font-weight: 500;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 8px;
  padding-bottom: 8px;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #000000;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
  opacity: 1;
  cursor: not-allowed;
}

.selectPicker {
  min-width: 200px;
}

.holdSelectDashHome .selectPicker {
  min-width: 140px;
}

.selectPickerCustom {
  float: left;
  min-width: 300px;
  margin-right: 10px;
}

.selectPickerBlue {
  border-color: #c4d1ff;
}

.orangeSelectCustom {
  min-width: 50px !important;
  max-width: 163px;
}

.inputBlueItem {
  box-shadow: 1px 1px 3px #00000030;
  border-radius: 5px;
  background-color: #c4d1ff !important;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #080808;
  font-weight: 700;
  height: 40px;
  text-align: center;
}

.inputBlueItem:focus {
  box-shadow: 1px 1px 3px #00000030;
  border-radius: 5px;
  background-color: #c4d1ff;
  border: none;
}

.inputGrayItem {
  box-shadow: 1px 1px 3px #00000030;
  border-radius: 5px;
  background-color: #d1d1d1;
  border: none;
  font-size: 14px;
  color: #080808;
  font-weight: 700;
  height: 40px;
  text-align: center;
}

.inputGrayItem:focus {
  box-shadow: 1px 1px 3px #00000030;
  border-radius: 5px;
  background-color: #d1d1d1;
  border: none;
}

.inputYellowItem {
  box-shadow: 1px 1px 3px #00000030;
  border-radius: 5px;
  background-color: #ffe7a1;
  border: none;
  font-size: 14px;
  color: #080808;
  font-weight: 700;
  height: 40px;
  text-align: center;
}

.inputYellowItem:focus {
  box-shadow: 1px 1px 3px #00000030;
  border-radius: 5px;
  background-color: #ffe7a1;
  border: none;
}

.inputWhiteItem {
  box-shadow: 1px 1px 3px #00000030;
  border-radius: 5px;
  background-color: #f5f5f5;
  border: none;
  width: 100%;
  height: 40px;
  cursor: pointer;
}

.trashGray {
  cursor: not-allowed;
  filter: grayscale(1);
}

.pointer {
  cursor: pointer;
}

.errorBlur {
  border: 1px solid #f5000069;
  background: #fd939369 !important;
}

/* table */

.table-responsive {
  min-height: 480px;
}

.rs-table-cell-header .rs-table-cell-content {
  padding: 10px 10px;
  font-size: 12px;
  line-height: 1.66666667;
  background: #d5e5ff;
  color: #000;
  font-weight: bold;
}

/* Menu */

.rs-drawer-body {
  margin: 0 !important;
}

.rs-drawer-left.rs-drawer-xs,
.rs-drawer-right.rs-drawer-xs {
  width: 330px;
}

/* Flex Divs */

.one-third {
  display: flex;
  gap: 20px;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 20px;
}

.mainDiv {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #2c28281c;
  margin: 0 30px;
  position: relative;
  padding: 10px;
}

.flex-buttons {
  display: flex;
  margin: 20px 50px 30px 50px;
  gap: 20px;
  width: 430px;
  justify-content: space-around;
}

.relative-top {
  margin-top: -100px;
  margin-bottom: 50px;
}

.flex-status {
  display: flex;
  gap: 20px;
  width: 250px;
  justify-content: space-around;
  border-top-right-radius: 5px;
  padding: 20px;
  font-size: 17px;
  color: #fff;
  font-weight: 300;
}

.space-file {
  display: flex;
  justify-content: space-between;
  width: 500px;
}

.typeFiles {
  text-align: right;
  padding: 0 0px 20px 10px;
  font-size: 13px;
}

.blue-status {
  background: #5c5cff;
}

.green-status {
  background: #2ecc71;
}

.red-status {
  background: #e74c3c;
}

.orange-status {
  background: #e67e22;
}

/* Header */

.headerModule {
  margin: auto;
  text-align: center;
  position: relative;
  height: 25px;
}

.headerModule-subtext {
  color: #fff;
  opacity: 0.6;
  font-size: 14px;
  font-weight: 400;
  margin-top: -90px;
}

.headerModule-before {
  display: block;
  background: linear-gradient(180deg, #1b213f 10%, #65697d 100%);
  width: 100%;
  height: 190px;

  margin-bottom: -190px;
  border-bottom: none;
}

.headerModule-after {
  content: '';
  position: absolute;
  width: 100%;
  font: normal normal bold 221px/20px Ubuntu;
  letter-spacing: -11.93px;
  color: #ffffff;
  opacity: 0.06;
  text-align: center;
  top: 132px;
}

.headerModule h1 {
  font-size: 28px;
  font-weight: normal;
  position: relative;
  top: -70px;
  display: inline;
}

.headerModule h5 {
  position: relative;
  top: -15px;
  font-size: 14px;
}

.header- {
  background: #293d7c;
  display: none;
}

.header-home {
  background: #b9c5fc;
  display: none;
}

.header-indicadores {
  background-color: #1a213f;
}

.header-indicadores p {
  color: #fff;
  text-shadow: none;
}

.header-gestao-comercial {
  background-color: #1b213f;
}

.header-gestao-comercial p {
  color: #1a213f;
  text-shadow: none;
}

.header-pdm,
.header-inov,
.header-view-tasks {
  background-color: #1a213f;
}

.indicatorsHeaderModule {
  background-color: #1b213f;
}

.indicatorsHeaderModule h1 {
  letter-spacing: 2.8px;
  color: #d3d3d3;
}

.commercialHeaderModule {
  background-color: #1b213f;
}

.commercialHeaderModule h1 {
  letter-spacing: 2.8px;
  color: #d3d3d3;
}

.pdmHeaderModule,
.InovHeaderModule {
  background-color: #1b213f;
  height: 10px;
}

.pdmHeaderModule.managementHeader {
  background-color: #f5f5f5;
}

.pdmNewCardHeaderModule {
  background-color: #348ce0;
  height: 10px;
}

.pdmReformHeaderModule {
  background-color: #29418c;
  height: 10px;
}

.pdmExtLineHeaderModule {
  background-color: #636a7e;
  height: 10px;
}

.pdmHeaderModule h1,
.pdmNewCardHeaderModule h1,
.InovHeaderModule h1 {
  letter-spacing: 2.8px;
  color: #ffffff;
  opacity: 0.6;
  text-transform: uppercase;
}

.pdmHeaderModule.managementHeader h1 {
  color: #425ba6;
  opacity: 1;
}

.versionGit {
  color: #fff;
  font-size: 7px;
  text-align: center;
  margin-top: 5px;
}

/* HOME */

.textHome h2 {
  text-align: left;
  color: #1a213f;
  font-weight: 500;
  font-size: 30px;
  line-height: 46px;
  padding-left: 100px;
  opacity: 0.4;
  position: absolute;
  bottom: 50px;
  left: 200px;
}

.homeImg {
  padding: 50px;
  width: 50%;
  margin: auto;
}

.homeImgPlm {
  padding: 50px;
  width: 60%;
  margin: auto;
}

.textSoon {
  display: none;
  position: absolute;
  right: -375px;
  top: 10px;
  background: transparent linear-gradient(180deg, #ffffff 0%, #cae4fe 100%) 0% 0% no-repeat padding-box;
  box-shadow: 2px 5px 20px #00000061;
  border-radius: 12px;
  z-index: -1;
  padding: 15px 15px 30px 15px;
  width: 400px;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
}

.soon {
  cursor: not-allowed;
}

.soon img {
  content: url('./Assets/lockImg.png');
  filter: grayscale(0);
}

.soon+.textSoon {
  display: block;
}

/* LOGIN */

.buttonLogin {
  background-color: #f3a75b;
  width: 140px;
  font-size: 20px;
  color: #ffffff;
  border-radius: 5px;
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  padding: 6px;
  cursor: pointer;
}

.buttonLogin:hover {
  opacity: 0.8;
}

/* Indicators */

.totalBriefings {
  text-align: right;
}

.totalBriefings p {
  padding: 60px 0 10px 0;
  font-size: 27px;
}

.totalBriefings p span {
  background: #6395f9;
  padding: 10px;
  border-radius: 50%;
  font-size: 23px;
  margin-left: 5px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  display: inline-block;
  width: 53px;
}

.nameIndicators {
  text-align: center;
  font-size: 30px;
  font-weight: 300;
  margin: 0px 0 40px 0;
}

.flex-indicators {
  display: flex;
  grid-gap: 20px;
  grid-gap: 20px;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  min-height: 600px;
  flex-direction: row;
}

.clear {
  clear: both;
}

/* CD destinos */
.modal-cd-destiny {
  min-width: 664px;
  height: 434px;
  background: url('../src/Assets/bg-cd-states.png') no-repeat !important;
  background-size: cover !important;
  margin: auto;
  margin-top: 220px;
  margin-right: 120px;
  border: 0;
}

.modal-cd-destiny-sku {
  min-width: 530px;
  height: 380px;
  background-size: cover !important;
}

.modal-cd-destiny-sku p {
  margin: auto !important;
  text-align: center;
  margin-bottom: 20px;
  display: block;
}

.modal-cd-destiny-sku .hold-all-sku {
  margin: 20px 0px;
}

.modal-cd-destiny p {
  color: #1a213f;
  font-size: 20px;
  font-weight: 600;
  padding-top: 20px;
  text-align: center;
}

.modal-cd-destiny .modal-content {
  border: 0;
  min-height: 320px;
  border-radius: 14px;
  background: transparent;
}

.modal-cd-destiny .modal-footer {
  padding-top: 40px;
  border: 0;
}

.hold-all-sku {
  max-width: 100%;
  margin: auto;
  text-align: center;
}

.hold-all-sku button {
  display: initial;
  margin-right: 10px;
  text-align: center !important;
}

.NewBriefing_yellowMediumInput,
.NewBriefing_legendField {
  margin-left: 11px;
}

.btn-opening-calls {
  position: relative;
  color: #338ce1;
  font-weight: 600;
  display: block;
  text-align: center;
  padding: 15px 0px;
  top: 25px;
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
}

.btn-opening-calls:hover {
  background-color: #eee;
}

/* Custom Components */

.box-modal-blue {
  margin-right: 10px;
  padding: 11px;
  color: #699bc5;
  font-size: 13px;
  font-weight: normal;
  border: dashed 2px #707070;
  border-radius: 7px;
  background: #f1f9ff;
}

.box-modal-red {
  margin-right: 10px;
  padding: 11px;
  color: #1b213f;
  font-size: 13px;
  font-weight: normal;
  border: dashed 2px #f95c87;
  border-radius: 7px;
  background: #feeef2;
}

.i-attention {
  position: relative;
  top: -3px !important;
  margin-right: 10px;
}

.box-modal-green {
  margin-right: 10px;
  padding: 11px;
  color: #1f4d74;
  font-size: 13px;
  font-weight: normal;
  border: dashed 2px #78f0a8;
  border-radius: 7px;
  background: #78f0a852;
}

.text-observation {
  color: #1f4d74;
  font-weight: 600;
  font-size: 14px;
}

.text-observation span {
  font-weight: normal;
}

.text-limit {
  font-size: 12px;
  padding-top: 5px;
  font-weight: normal;
  color: #666;
}

.custom-textarea {
  min-height: 136px;
}

.custom-textarea::placeholder {
  color: #1f25437d;
}

.hold-radio-button {
  color: #1f4d74;
  font-weight: 600;
}

.hold-radio-button .inputTextComponent {
  margin: 0;
}

.hold-radio-button label {
  color: #1f4d74;
  font-weight: 600;
  margin-bottom: 16px;
}

.hold-radio-button input[type='radio'],
input[type='checkbox'] {
  display: inline-block;
  margin-bottom: 15px;
  position: relative;
  top: 2px;
  margin-right: 10px;
}

/* Responsive */

@media (max-width: 1490px) {
  .one-third {
    width: 90%;
    flex-direction: row;
    margin: 20px auto;
    justify-content: space-between;
  }

  .one-three {
    width: 90%;
    flex-direction: row;
    margin: 20px auto;
    justify-content: space-between;
  }

  .one-three div {
    flex-grow: 1;
    width: 33%;
  }

  .one-third div {
    flex-grow: 1;
    width: 33%;
  }

  .orangeSelect {
    max-width: 100%;
  }

  .flex-start {
    display: block;
  }

  .pd-left-20 {
    padding: 0;
  }

  .rs-picker-toggle-wrapper {
    width: 100% !important;
    text-align: center;
  }

  /* .maxWidthBriefing {
    overflow-x: scroll;
    padding: 10px;
    width: 100%;
  } */
}

.hold-menu-indicators {
  float: left;
  width: 27px;
  min-height: 40px;
  position: relative;
  top: -1px;
}

.hold-menu-indicators img {
  max-height: 20px;
}

@media (max-width: 1366px) {
  .homeImg {
    width: 60%;
    text-align: center;
    margin: auto;
  }
}

@media (max-width: 1023px) {
  .flex-space {
    flex-direction: column;
    text-align: center;
  }

  .flex-center-gap {
    flex-direction: column;
    text-align: center;
    padding: 0;
  }

  .text-center-mobile {
    text-align: center !important;
  }

  .with-row {
    flex-direction: row !important;
  }

  .with-flex {
    display: flex !important;
  }
}

@media (max-width: 880px) {
  .flex-gap {
    flex-direction: column;
  }

  .btn-submit {
    width: 100%;
  }

  .toCenter {
    justify-content: center !important;
  }
}

.header-V01 {
  background-color: #1a213f;
  background-image: linear-gradient(#1a213f, #666a7e);
}

.modal-content1 {
  position: unset !important;
  display: block !important;
  border-radius: 0.3rem !important;
}

.modal-content1>input[type='checkbox'] {
  margin: 3px 3px 3px 4px !important;
}

.modal-content1>input {
  display: unset !important;
}

.hideAdiBtn {
  display: none !important;
}

.total-count {
  width: unset !important;
}

.btn-select {
  margin: unset !important;
}

.SourcingVision_mainDiv__1Sbne {
  background: unset !important;
}

.error {
  color: rgb(253, 68, 68) !important;
  font-size: 12px;
  font-weight: bold;
  font-family: 'Montserrat';
}

.error-input {
  border: 2px solid #fd4444;
}

.custom-numeric-input {
  padding: 15px 0px;
}

.custom-numeric-input input {
  width: 100px;
  padding: 10px;
  text-align: center;
}

.custom-numeric-input span {
  color: #738cf8;
  margin-right: 12px;
}

.rowHeaderListTable {
  margin: 0 30px;
  padding: 10px 0;
  position: relative;
}

.infoRowHeaderListTable {
  color: #fff;
  font-weight: 600;
  margin-top: 8px;
}

.inputRowHeaderListTable {
  color: #000 !important;
  padding: 8px 11px !important;
  opacity: 1;
}