// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');
@import '../../../../../../Assets/colors.scss';

// .stage-item {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }

$btn-font-size: calc(0.008 * 100vw);

#activity-record {
  max-height: 530px !important;

  .header {
    background-color: $blue;
    min-height: 60px;
    // padding: 0 18px;

    // font-size: 13px;
    font-size: calc(0.0065 * 100vw);
    font-weight: normal;
    font-family: 'Poppins';
    // color: #ffffff;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .column {
      width: 29%;
    }

    .btnComment {
      display: flex;
      flex-direction: row;
      align-items: center;
      // font-size: 14px;
      font-size: $btn-font-size;
      width: 100%;
      height: 30px;
      border-radius: 6px;
      justify-content: space-between;
      padding: 0 8px;

      .imgBalloon {
        display: flex;
        width: 14px;
        height: 14px;
        background: url('../../../../../../Assets/balloon.jpg') center center;
        background-size: cover;
      }
    }

    .rs-picker-select {
      height: 30px;

      .rs-btn {
        height: 30px;
        padding-top: 7px;
        padding-bottom: 6px;
        font-size: $btn-font-size;
      }
    }

    .inputInlineDateRange {
      height: 30px;
      font-size: $btn-font-size;
    }

    .textCol {
      @media (min-width: 1200px) and (max-width: 1919px) {
        width: 25%;
      }
    }
    .filterCol {
      @media (min-width: 1200px) and (max-width: 1919px) {
        width: 75%;
      }
    }
  }

  .body {
    height: 400px;
    background: #f9fafc 0% 0% no-repeat padding-box;
    box-shadow: 8px 8px 50px #00000021;
    overflow-y: auto;
    padding: 15px 0;
    .noResults {
      background: url('../../../../../../Assets/no_regs_vis.svg') no-repeat
        no-repeat left center;
      height: 100%;
      font-size: 16px;
      color: $primary3;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      p {
        margin-left: 30px;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
    padding-bottom: 10px !important;
    p {
      // margin-top: 10px;
      // text-align: right;
      color: $blue;
      height: 39px;
      text-align: end;
    }
    .reopenmodulebtn {
      background: transparent linear-gradient(180deg, #e3b27e 0%, #f39332 100%)
        0% 0% no-repeat padding-box;
      max-width: 50px;
    }
    .closemodulebtn {
      background: linear-gradient(
        180deg,
        rgba(108, 187, 254, 1) 0%,
        rgba(0, 101, 186, 1) 100%
      );
    }
  }

  .rs-picker-toggle-wrapper {
    border: 0;
  }

  .rs-picker-toggle-clean {
    display: inline-block !important;
  }

  .rs-picker-toggle .rs-picker-toggle-placeholder {
    top: 0;
  }

  .regActivityWeekdayHeader {
    text-align: right;
    margin: 0px 15px 10px 0;
    text-transform: capitalize;
    font-weight: bold;
    // font-size: 12px;
    color: $primary4;
  }

  .regActivityRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    padding: 0 1rem 0 1rem;
    // font-size: 13px;
    color: $gray2;
    strong {
      color: $primary4;
    }
    .icoCol {
      // width: auto;
      flex-shrink: 0;
      min-width: 40px;
      // margin: 2px 0px 0 15px;
    }
    .textCol {
      // flex: 9;
    }
  }

  .obsModalBtn {
    padding: 0 0 0 2px;
    background: none;
  }

  .hover-wrapper {
    position: relative;
    .in-tooltip {
      position: absolute;
      top: -24px;
      padding: 1px 4px;
      border-radius: 4px;
      background: #f1f1f1;
      letter-spacing: 0.36px;
      color: #1a213f;
      font-weight: 300;
      left: 50%;
      transform: translate(-50%, 0);
      // font-size: 12px;
      display: none;
    }
    &:hover {
      .in-tooltip {
        display: block;
      }
    }
  }
}
