.upload-modal {
  font-family: Poppins;
  font-style: normal;

  .div-header {
    display: flex;
    justify-content: space-evenly;

    .div-icon-upload {
      width: 50px;
      height: 50px;
      background-color: #6ca0f9;
      border-radius: 12px;
      margin-top: 20px;
      margin-left: 180px;
    }

    .icon-upload {
      width: 22px;
      height: 22px;
      color: #ffffff;
      margin: 15px;
    }

    .title-header {
      margin-left: 12px;
      margin-right: 300px;
      font-weight: 600;
      font-size: 22px;
      line-height: 150%;
      letter-spacing: -0.03em;
      color: #425ba6;
      white-space: nowrap;
      margin-top: 20px;
    }

    .subtitle-header {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.03em;
      color: #596780;
      font-weight: 400;
      font-size: 14px;
    }

    .icon-close {
      color: #353535;
      width: 20px;
      height: 20px;
      margin-top: 10px;
      margin-left: -200px;
    }
  }

  .div-body {
    margin-left: 80px;

    .title-body {
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: -0.03em;
      color: #425ba6;
    }

    .body-modal {
      font-family: Poppins;
      font-style: normal;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.03em;
      color: #596780;
      margin-left: 26px;

      .star-icon {
        color: #c8d18c;
        width: 20px;
        height: 19px;
        margin-right: 10px;
      }

      .warning-icon {
        color: #f29232;
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }

      .body-message {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #425ba6;
      }
    }

    .div-icon-doc {
      display: flex;
      flex-direction: column;

      .icon-doc {
        color: #425ba6;
        width: 16px;
        height: 20px;
        //  margin-left: 26px;
        margin-right: 10px;
      }

      .link-doc {
        display: flex;
        margin-left: 10px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #425ba6;
        white-space: nowrap;
        cursor: pointer;
      }

      .name-doc {
        font-family: 'Poppins';
        font-style: italic;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #3c3c3b;
        margin: 0 0 15px 35px;

        li {
          display: flex;

          button {
            background-color: transparent;
            margin-left: 6px;
          }
        }
      }

      .close-x {
        color: #000000;
        width: 18px;
        height: 18px;
        font-weight: 200;
      }

      .upload-icon {
        width: 20px;
        height: 19px;
        color: #425ba6;
        margin-left: 5px;
      }
    }
  }

  .footer-modal {
    margin-left: 350px;

    .button-footer {
      padding: 0 40px;
      white-space: nowrap;
      padding: 6px 16px;
      background: #425ba6;
      border-radius: 4px;
      border: #425ba6;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
      margin-bottom: 10px;
    }
  }
}
