.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: transparent linear-gradient(180deg, #FBFBFB 0%, #E3E3E3 100%) 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 20px #2C28281C;
  min-height: 180px!important;
  border-radius: 8px;
  margin: 5px;
  padding: 7px 0 7px 16px!important;
  border: None;
}

.iconTittle {
  display: flex;
  align-items: center;
}


.cardIcon{
  display: flex;
  justify-content:center;
  align-items: center;
  height: 30px;
  width: 30px!important;
  color: #FFFF;
  border-radius: 50%;
  background: transparent linear-gradient(180deg, #F59B40 0%, #BE772F 100%) 0% 0% no-repeat padding-box;
}

.cardTittle{
  text-align: left;
  font-size: 11px!important;
  font-family: Ubuntu;
  font-weight: 700;
  letter-spacing: 1.2px;
  color: #F39332;

  text-transform: uppercase;
}

.cardDescription {
  text-align: left;
  font-size: 11px;
  font-family: Ubuntu;
  font-weight: 700;
  letter-spacing: 1.2px;
  color: #615b56;
}

.cardDescriptionClient {
  color: #F39332;
  font-size: 11px;
}

.noteDescription {
  color: #172B4D;
  font-size: 13px;
  
}

.noteDescription p {
  word-wrap: break-word;
}
.noteDescription span {
  cursor: pointer;
  color: #615b56;
}

.noteDescription span:hover {
  color: #F39332;
}

.noteDate {
 color: #A6A6A6; 
 font-size: 11px;
}

.noteCancelled {
  font-size: 13px;
  color: #F45A6A;
}


.rowProject {
  display: flex;
  justify-content: flex-start;
}