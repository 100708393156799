@import '../../../../../Assets/colors.scss';
@import '../../../../../Assets/mixins.scss';
@import '../../../../../Assets/modals.scss';

.modal-observation {
  .lineHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    align-content: center;
    padding: 20px;

    span {
      font-weight: normal;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #6078BF;
    }  

    .buttonBriefing {
      display: flex;
      white-space: nowrap;
      background-color: #425BA6;
      border-radius: 4px;
      color: #FFFFFF;
      font-weight: 700;
    }
  }

  .buttonFooter {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 16px;
      width: 82px;
      height: 32px;
      left: 642px;
      top: 534px;
      background: #3E569C;
      border-radius: 4px;
      color: #FFFFFF;
      font-weight: 700;
    }

    .observationProject{
      color: #1B213F;
      font-size: 12px;
      overflow-y: scroll;
      height: 200px;
    }
}

.modal-dialog {
  &.modal-select-supplier {
    .modal-header {
      padding: 0px 20px;
      border: 0;
      margin-top: 20px;

      p {
        font-weight: normal;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
      }

      button {
        margin-left: 390px;
        white-space: nowrap;
        background-color: #425BA6;
        color: #FFFFFF;
        margin-top: -20px;
        margin-left: -20px;
      }
    }

    .modal-footer {
      button {
        width: auto;
        display: inline-flex;
        margin: 0 10px;
      }

      .divButtonFooter {
        max-width: 500px;
        margin: auto;
      }
    }

    .modal-body {
      p,
      li {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400px;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .close {
      display: none;
    }
  }
}
