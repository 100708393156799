.TableList {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #2c28281c;
  margin: 0 30px;
  position: relative;

  .table {
    margin: 0;
    width: 100%;
    border: none;

    th {
      background-color: #f08723;
      font-size: 11px;
      text-align: center;
      color: #fff;
      padding: 20px 5px !important;
      vertical-align: middle !important;
    }

    td {
      font-size: 11px;
      text-align: center;
      font-weight: bold;
      padding: 30px 5px;
      vertical-align: middle !important;
      border: none;
    }

    tbody {
      tr:nth-of-type(odd) {
        background-color: #fcefe2 !important;
      }
      tr:hover {
        background-color: #fcefe2 !important;
      }
    }
  }

  .borderLeftRadius {
    border-radius: 9px 0 0 0;
  }

  .borderRightRadius {
    border-radius: 0 9px 0 0;
  }

  .groupButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 6px;
  }

  &.newTable {
    margin: 0;

    .borderLeftRadius {
      border-radius: 0;
    }

    .borderRightRadius {
      border-radius: 0;
    }

    .expansiveHead {
      display: flex;
      flex-direction: column;

      button {
        background-color: transparent;
        color: #fff;
        font-size: 11px;

        span {
          color: #6383f2;
        }
      }
    }

    th {
      background-color: #494e59;
      font-size: 11px;
    }

    tbody {
      tr:nth-of-type(odd) {
        background-color: #fff !important;
      }
      tr:nth-of-type(even) {
        background-color: #eaecf4 !important;
      }
      tr:hover {
        background-color: #eaecf4 !important;
      }
    }

    td {
      font-size: 11px;
      vertical-align: top !important;
      white-space: nowrap;
    }

    .expansiveItem {
      display: flex;
      flex-direction: column;
      justify-content: center;

      button {
        background-color: transparent;
        font-size: 11px;

        span {
          margin-left: 4px;
          transform: translateY(-1px);
        }
      }

      .expansiveIcon {
        color: #6383f2;
        font-size: 16px;
      }
    }

    .closedLabel {
      height: 28px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .expansiveArea {
      display: flex;
      flex-direction: column;
      // padding-top: 15px;
      // height: auto;
      overflow: hidden;
      transition: all 0.3s ease-in-out;

      // &.closed {
      //   padding-top: 0;
      //   // height: 0;
      // }

      p {
        height: 18px;
        margin: 0;
      }
    }
  }
}
