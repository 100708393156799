/***************** Structure *************/
#section {
  padding: 5px 12px;
  background: #F7F8F9;
  border-radius: 4px;
}

.hold-filters {
  padding: 0;
}

.table {
  margin-bottom: unset !important;
  color: unset !important;
}

.sourcingList {
  width: 100%;
  font-size: 12px !important;
  text-align: center;
  min-height: 35px;
}

.sourcingList td {
  padding: 2px 4px;
}

.marginNegative {
  margin-top: -15px !important;
}

.listBox {
  width: 361px;
  height: 189px;
  background: transparent linear-gradient(180deg, #ffffff 0%, #e5e5e5 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2c282838;
  border: 1px solid #d8d8d826;
  opacity: 1;
  overflow-wrap: break-word;
  overflow-y: scroll;
  padding: 15px;
}

.listBox a {
  color: #496bfb !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
  line-height: 25px;
}

.listBox button {
  color: #496bfb !important;
  background-color: transparent;
  transition: 0.5s;
}

.listBox button:hover {
  color: #18234d !important;
}

.one-third {
  display: flex;
  gap: 20px;
  float: left;
  margin-bottom: 20px;
}

.mainDiv {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #2c28281c;
  margin: 0 30px;
  position: relative;
  padding: 10px;
}

.borderLeftRadius {
  border-radius: 9px 1px 0px 0px;
}

.borderRightRadius {
  border-radius: 0px 9px 0px 0px;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.borderTop td {
  border-top: none !important;
  padding: 2px !important;
}

.calIcon {
  width: 21px;
  height: 20px;
}

.CDIcon {
  width: 20px;
  height: 20px;
}

.ObsIcon {
  width: 17px;
  height: 20px;
  position: relative;
  left: 7px;
  top: -3px;
}

/***************** Tables ********************/

/* 1 */

.tableSourcing th {
  background-image: linear-gradient(#f29435, #e3b280);
  font-size: 12px;
  text-align: center;
  color: #fff;
  height: 65px;
}

.tableSourcing td {
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  padding: 30px;
  vertical-align: middle !important;
}

.tableSourcing tbody tr:nth-of-type(odd) {
  background-color: #fcefe2 !important;
}

.tableSourcing tbody tr:hover {
  background-color: #fcefe2 !important;
}

.tableSourcing img {
  display: inline-block;
}

.tableSourcing p {
  font-size: 10px;
  color: #6ca0f9;
  margin-top: 5px;
}

/** 2 **/

.tableList th {
  font-size: 12px;
  text-align: center;
  color: #fff;
}

.tableString {
  background: transparent linear-gradient(90deg, #e5e5e5 0%, #faf7fe 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px 0px 0px 5px;
  opacity: 1;
  font-size: 12px;
}

.no-pad {
  padding: 0 !important;
  display: flow-root;
  margin-bottom: 8px;
  border: none !important;
}

/***************** Button Variations *************/

/* .btn {
  text-align: center !important;
  display: block !important;
  font-size: 12px !important;
  font-weight: bold;
  font-family: "Ubuntu", sans-serif;
  letter-spacing: 0px;
  border: none !important;
} */

.btnBlue {
  background: #3D435C !important;
  color: #fff;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  font: 400 0.8rem 'Poppins';
  padding: 10px 18px;
  box-shadow: none;
}

.btn.btnBlue:disabled {
  background-color: lightgray;
}

.btnGray {
  background: #F0F0F0 !important;
  color: #64748B !important;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  font: 400 0.8rem 'Poppins';
  padding: 10px 18px;
  box-shadow: none;
}

.btnDarkGray {
  background: transparent linear-gradient(180deg, #3c3c3b 0%, #7c7c7c 100%) 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 15px #00000038;
  border-radius: 5px !important;
  opacity: 0.99;
  color: #fff !important;
  min-height: 35px;
  border: none !important;
  font-size: 12px;
}

.btnGreen {
  background: transparent linear-gradient(90deg, #32f37a 0%, #02993a 100%) 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 15px #00000038;
  border-radius: 5px;
  opacity: 1;
  color: #1a213f;
  border: none !important;
}

.btnOrange {
  background: transparent linear-gradient(180deg, #d18538 0%, #f29232 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  box-shadow: 4px 4px 5px #00000036 !important;
  opacity: 1;
  color: #faf7fe !important;
  border: none !important;
  line-height: unset !important;
}

.btnBrown {
  background: transparent linear-gradient(90deg, #f39332 0%, #985a1b 100%) 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 5px #00000036;
  border-radius: 7px;
  opacity: 1;
  color: #fff;
  font-weight: 500;
  border: none !important;
  width: 168px;
  height: 38px;
}

.btnYellow {
  background: transparent linear-gradient(180deg, #efc342 0%, #3c3c3b 100%) 0% 0% no-repeat padding-box;
  border-radius: 7px !important;
  opacity: 0.99;
  color: #fff !important;
  border: none !important;
  min-height: 35px;
  font-size: 12px;
}

.btnAdd {
  margin: auto;
}

.btnResult {
  background: transparent linear-gradient(90deg, #f3f3f3 0%, #e2e2e2 100%) 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 3px #00000029;
  border-radius: 5px;
  opacity: 1;
  margin-right: 2px;
}

.btnLarge {
  width: 228px;
  height: 44px;
}

.btnDisabledSend {
  margin: auto;
  text-align: center;
  display: block;
  width: 126px;
  height: 38px;
  background: transparent linear-gradient(90deg, #e5e5e5 0%, #faf7fe 100%) 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 5px #00000036;
  border-radius: 7px;
  color: #a6a6a6;
  font-weight: 500;
  border: none;
  cursor: default !important;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 4px 4px 5px #00000036 !important;
}

.btnEnabledSend {
  margin: auto;
  text-align: center;
  display: block;
  width: 126px;
  height: 38px;
  background: transparent linear-gradient(90deg, #32f37a 0%, #02993a 100%) 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 5px #00000036;
  border-radius: 7px;
  color: #fff;
  font-weight: 500;
  border: none;
}

.mainDiv .closeButton {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #425BA6;
  opacity: 1;
  text-decoration: none;
}

.mainDiv .closeButton:hover {
  color: #f39332;
  text-decoration: none;
}

.backIcon {
  width: 36px;
  height: 37px;
}

/***************** Checkbox *************/

.checkboxList {
  padding-top: 8px;
}

.supplier_checklist label>input[type='checkbox'] {
  display: none;
}

.supplier_checklist label>input[type='checkbox']+*::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 27px;
  height: 25px;
  border-radius: 10%;
  border-style: solid;
  border-width: 2px;
  border-color: #1a213f;
  margin: 10px;
}

.supplier_checklist label>input[type='checkbox']:checked+*::before {
  content: '✓';
  color: white;
  text-align: center;
  font-size: 18px;
  height: 26px;
  background: #253fb0;
  border: solid 1px #253fb0 !important;
}

.supplier_checklist label>input[type='checkbox']:checked+* {
  color: #3751d2;
}

/***************** Search Button *************/

/* @import url(https://fonts.googleapis.com/css?family=Open+Sans); */

.search {
  width: 100%;
  position: relative;
  display: flex;
}

.searchTerm {
  width: 300px;
  padding: 10px;
  height: 39px;
  outline: none;
  float: left;
  background: #F0F0F0;
  box-shadow: none;
  border-radius: 5px 0px 0px 5px;
  opacity: 1;
  border: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #686464;
}

button.searchButton {
  width: 40px;
  height: 39px;
  border: none;
  background: #F0F0F0;
  box-shadow: none;
  border-radius: 0px 5px 5px 0px;
  opacity: 1;
  display: flex;
  align-items: center;
  color: #686464;
  cursor: pointer;
  font-size: 20px;
}

button.searchButtonLeft {
  width: 40px;
  height: 39px;
  border: none;
  background: #F0F0F0;
  box-shadow: none;
  border-radius: 5px 0px 0px 5px;
  opacity: 1;
  text-align: center;
  color: #686464;
  cursor: pointer;
  font-size: 20px;
}

.no-rad {
  border-radius: 0 !important;
}

.fa-search {
  margin-left: 5px;
}

.prov-cnpj-dropdown {
  width: 234px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #494E59;
}

.prov-cnpj-dropdown .rs-dropdown-toggle {
  width: 100%;
  text-align: left;
}

.prov-cnpj-dropdown .matriz {
  color: #657AA9;
}

#filter-modal .box-labeled {
  display: flex;
  margin: 9px 0;
}

#filter-modal .box-labeled .b-label {
  padding: 12px 22px 12px 12px;
  width: 100%;
  height: 43px;
  background: #F7F8F9;
  border-radius: 4px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #30343E;
}

#filter-modal .box-labeled label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

#filter-modal .rs-picker-toggle-wrapper {
  border: 0;
  margin: 9px 0;
  box-shadow: none;
}

#filter-modal .rs-btn {
  width: 100%;
  background: #F7F8F9;
  border: 0;
  border-radius: 5px;
  padding: 6px 12px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #30343E;
}

#filter-modal .rs-picker-toggle .rs-picker-toggle-placeholder {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #30343E;
}

#filter-modal input {
  width: 100%;
  background: #F7F8F9;
  border: 0;
  border-radius: 5px;
  padding: 6px 12px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #30343E;
}

#filter-modal .form-check-input {
  width: 20px !important;
  height: 20px !important;
  appearance: none;
  border-radius: 0 !important;
  border: 1px solid #000 !important;
  position: relative;
  margin: 0;
  padding: 0 !important;
}

#filter-modal .form-check-input:checked {
  border-color: #F39433 !important;
  background-color: #F39433 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
}

#filter-modal .close {
  opacity: 1;
  display: block;
  padding: 8px 16px;
}

#filter-modal .close svg {
  height: 18px;
  width: auto;
}

.btn.btn-multi-action {
  color: #fff;
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.btn-multi-action.text {
  color: #30343E;
}

.btn-multi-action.blue {
  background: #425BA6;
}

.btn-multi-action.orange {
  background: #EC8C32;
}

.btn-multi-action.red {
  background: #F47780;
}

.btn-multi-action:disabled {
  background: #D7D7D7
}

/***************** Modal *********************/

.modal-sourcing .modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal-sourcing .modal-content {
  background-color: #fefefe;
  margin: 116px 30px;
  border: 1px solid #888;
  width: 96% !important;
  /* min-height: 396px; */
  height: auto;
  box-shadow: 4px 4px 20px #0000001c;
  border-radius: 5px 0px 0px 5px;
  border: none !important;
  padding-left: 35px;
}

#bgFornecedor span {
  color: #212529 !important;
}

#bgProduto span {
  padding: 10px;
}

#bgProduto .btnBlue {
  box-shadow: none !important;
}

.modal-sourcing .modal-close {
  float: right;
  top: 0;
  right: 0;
}

#myModalObserve .modal-close {
  float: right;
  top: 0px;
  right: 0;
}

.modal-sourcing .modal-close :hover,
.modal-sourcing .modal-close :focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

#myModalObserve .modal-content {
  border: 1px solid #888;
  width: 500px !important;
  height: 250px;
  box-shadow: 4px 4px 20px #0000001c;
  border-radius: 5px 0px 0px 5px;
  float: right;
  padding-right: 35px;
}

#myModalObserva .modal-content {
  border: 1px solid #888;
  width: 500px !important;
  height: 190px;
  box-shadow: 4px 4px 20px #0000001c;
  border-radius: 5px 0px 0px 5px;
  float: right;
  padding-right: 35px;
  overflow: hidden;
}

#myModalObserva .modal-obs-list-itens {
  overflow: auto;
}

.cd_list {
  border: 2px solid #f5af69;
  border-radius: 5px;
  padding-top: 1px;
  width: 35px;
  text-align: center;
  margin: 4px;
  font-weight: 700;
  cursor: default !important;
  float: left;
}

.derived-floating-badge {
  position: relative;
  background: rgb(247, 247, 247) none repeat scroll 0% 0%;
  display: inline-block;
  padding: 12px 26px;
  left: 30px;
  top: 22px;
  z-index: 1;
  font: normal normal 300 15px/20px Ubuntu;
  letter-spacing: 0px;
  color: rgb(100, 104, 124);
  border-radius: 10px;
}

.derived-badge {
  background: rgb(247, 247, 247) none repeat scroll 0% 0%;
  display: inline-block;
  padding: 10px 26px;
  font: normal normal 300 15px/20px Ubuntu;
  letter-spacing: 0px;
  color: rgb(100, 104, 124);
  border-radius: 10px;
}

#myModalDestino,
#myModalObserve,
#myModalObserva {
  z-index: 2;
}

#myModalDestino .modal-content {
  padding-top: 30px;
  border: 1px solid #888;
  width: 321px !important;
  height: 270px;
  box-shadow: 4px 4px 20px #0000001c;
  border-radius: 5px 0px 0px 5px;
  float: right;
  background: url(Assets/bg_cd_states.png) no-repeat !important;
  overflow: hidden;
  overflow: auto;
}

#myModalDestino .modal-close {
  position: absolute;
  right: 0px;
  background-color: orange !important;
  color: #fff !important;
  text-align: center;
  padding: 5px 10px;
}

#myModalDestino h5 {
  text-align: center;
  font-size: 12px !important;
  color: #1a213f;
}

.cd_product {
  font-style: 12px !important;
  font-weight: bold;
  margin-bottom: 4px;
}

#myModalObserva .modal-close {
  position: absolute;
  right: 0px;
  background-color: orange !important;
  color: #fff !important;
  text-align: center;
  padding: 20px;
}

#myModalObserve .modal-close {
  position: absolute;
  right: 0px;
  background-color: orange !important;
  color: #fff !important;
  text-align: center;
  padding: 5px 10px;
}

#myModalObserve h5 {
  font-size: 13px;
  padding-top: 15px;
}

#myModalObserva h5 {
  font-size: 12px;
}

.orange_board {
  border: 1px solid orange;
  padding: 20px;
  border-radius: 3px;
  overflow: auto;
  line-height: 25px;
  margin-bottom: 14px;
}

#bgFornecedor {
  background-image: url('../Sourcing/Assets/bgFornecedor.png');
  background-repeat: no-repeat;
  background-position: right;
}

#bgProduto {
  background-image: url('../Sourcing/Assets/bgProduto.png');
  background-repeat: no-repeat;
  background-position: center;
}

.t1 {
  width: 245px;
  border: 1px solid #2b408b;
  border-bottom: 0px;
}

.t2 {
  width: 120px;
  border: 1px solid #3c3c3b;
  border-bottom: 0px;
}

.t3 {
  width: 540px;
  border: 1px solid #efc342;
  border-bottom: 0px;
}

.mt-15 {
  margin: -35px auto 0 !important;
}

.m-center {
  margin: 0 auto;
}

.m-top {
  margin: 0 3px 3px 0;
}

.m-left {
  margin-left: 10px;
}

.pro-none {
  display: none;
}

.margin20 {
  margin-bottom: 20px;
}

.margin5 {
  margin-bottom: 5px;
}

.pad-left {
  padding-left: 20px;
}

.pad-top {
  padding-top: 5px;
}

.font-list {
  font-weight: bold;
}

.font td {
  font-size: 12px !important;
  font-family: 'Ubuntu', sans-serif;
}

.v-align-b {
  vertical-align: bottom;
}

.cursor-dis {
  cursor: default !important;
  pointer-events: none;
  position: relative;
  z-index: 1;
}

.pad-10 {
  padding: 10px;
}

.pagination {
  margin-top: 20px;
}

.active>a,
.active>button {
  background: #dd8a38 !important;
  color: white !important;
  text-decoration: none !important;
}

.clearSearchButton {
  display: block;
  position: absolute;
  margin: 6px 0 0 410px;
}

.content-form {
  display: flex;
  justify-content: space-between;
}

.list-suppliers {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;
  width: calc(100% - 385px);
  height: 189px;
  overflow: auto;
}

.btn-supplier {
  display: flex;
  width: 200px;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.btn-supplier>span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 156px;
}

.btn-supplier>button {
  background-color: transparent;
  color: white;
}

.popover-inner {
  box-shadow: 1px 1px 8px 1px gray;
}

.popover-footer {
  display: flex;
  justify-content: center;
}

.button-files {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-btn button {
  width: 86% !important;
  font-size: 16px;
  border-radius: 6px;
}

.division-blue {
  padding: 10px 20px;
  background: rgb(113, 140, 255);
  background: linear-gradient(180deg, #283b80 0%, #19244e 100%);
  color: #fff;
  position: relative;
  top: -18px;
  border-radius: 6px;
}

.division-grey {
  padding: 10px 37px;
  background: rgb(113, 140, 255);
  background: linear-gradient(180deg, #424141 0%, #747474 100%);
  color: #fff;
  position: relative;
  top: -18px;
  border-radius: 6px;
}

.division-yellow {
  padding: 10px 37px;
  background: rgb(113, 140, 255);
  background: linear-gradient(180deg, #947928 0%, #cda738 100%);
  color: #fff;
  position: relative;
  top: -18px;
  border-radius: 6px;
}

.pt-6 {
  padding-top: 6px;
}

.hold-empty-cd-destinos {
  max-width: 90%;
  padding-top: 20px;
}

.hold-empty-cd-destinos p {
  margin: 10px 0px;
}

.provider-search-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #FFF2E4 !important;
  box-shadow: 8px 6px 14px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  margin: 0 0 21px 0;
  padding: 7px 7px 7px 0;
}

.provider-search-item .data-col {
  display: flex;
  align-self: stretch;
  flex: 1;
  justify-content: flex-start;
  position: relative;
  margin: 0 0 0 12px;
  padding: 0 0 0 24px;
}

.data-col p {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  color: #878787;
}

.data-col span {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  color: #1D201F;
}

.data-col .highlight {
  color: #FFB66D;
}

.data-col.decorated::after {
  position: absolute;
  width: 4px;
  height: 100%;
  left: 8px;
  background: #FFE4C9;
  border-radius: 3px;
  content: ' ';
}

.icons-provider {
  align-self: flex-start;
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  margin: 0 0 0 12px;
  width: 104px;
}

.icons-provider svg {
  width: auto;
  height: 26px;
}

.icons-provider svg:first-child {
  margin-right: 0;
}

.icons-legend {
  text-align: right;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #1D201F;
  margin: 12px 0 12px 0;
}

.icons-legend span {
  display: inline-block;
  margin: 0 12px 0 4px;
}

.provider-check {
  width: 20px !important;
  height: 20px !important;
  appearance: none;
  border-radius: 0 !important;
  border: 1px solid #878787 !important;
  position: relative;
  margin: 0;
  padding: 0 !important;
  flex: 0 0 auto;
}

.provider-check:checked {
  border-color: #F39433 !important;
  background-color: #F39433 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
}

.details-button {
  background: #3D435C;
  color: #fff;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  font: 400 0.8rem 'Poppins';
  padding: 10px 18px;
}

.details-button:hover {
  background: #181b29;
}