
.btnDefault{
  background: transparent linear-gradient(180deg, #0232FF 0%, #6E8AFF 100%) 0% 0% no-repeat padding-box;
  color: white;
  
}
.btnPrimary{
  background: transparent linear-gradient(180deg, #0232FF 0%, #6E8AFF 100%) 0% 0% no-repeat padding-box!important;
  color: white!important;
}

.btnSecondary{
  background: transparent linear-gradient(180deg, #FD4545 0%, #FE8989 100%) 0% 0% no-repeat padding-box;
  color: white;
}

.btnWhite {
  background: transparent linear-gradient(180deg, #F0F0F0 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  color: #E6AE74!important;
}

.btnWhite:hover {
  color: #E6AE74!important;
}