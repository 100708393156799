.specialPaddingModal {
  padding: 20px 80px 30px 80px;

  .notesProviders {
    margin: 20px 0;

    .attentionMessage {
      color: #fd4646;
      margin-bottom: 5px;
      font-weight: 500;
    }
  }

  .button-confirmar {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #2c28281c;
    border: 1px solid #f39332;
    border-radius: 5px;
    padding: 5px;
    width: 120px;
    font-size: 14px;
    color: #f39332;
  }
}