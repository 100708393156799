@import '../../../../../Assets/colors.scss';
@import '../../../../../Assets/mixins.scss';
@import '../../../../../Assets/modals.scss';

.custom-input-component {
  padding-top: 8px;

  .inputTextComponent {
    margin: 0;
  }
}

.custom-modal {
  .text-reopening {
    color: $primary3;
    margin: 0;
    position: relative;
    top: 4px;
    font: {
      weight: 600;
      size: 16px;
    }
    @extend .placeholder-fonts;
  }

  .sub-reopening {
    color: $primary3;
    margin: 0;
    position: relative;
    // top: -12px;
    font: {
      weight: 500;
      size: 13px;
    }
    @extend .placeholder-fonts;

    // &:before {
    //   content: '';
    //   width: 60px;
    //   height: 7px;
    //   position: relative;
    //   display: block;
    //   top: 40px;
    //   @include border-radius(5px);
    //   @include blue-grey-gradient;
    // }
  }

  .sub-title-modal-green2 {
    &:before {
      @include green-gradient-2;
    }
  }

  .sub-title-modal-blue {
    &:before {
      @include blue-gradient;
    }
  }

  .sub-title-modal-blue-grey {
    &:before {
      @include blue-grey-gradient;
    }
  }

  p {
    @extend .placeholder-fonts;

    span {
      font-weight: bold;
    }
  }

  .modal-body {
    padding: 0px 25px;
  }
  .modal-header {
    border: 0;
    display: block;

    svg {
      margin-top: 3px;
    }
  }
}
